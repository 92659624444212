import {
    setCart,
    setCartQuantity,
    setCartReady,
    setCartSubTaxTotal,
    setCartSubTotal,
    setCartTotal,
    setLoyaltyDiscount,
} from "../state"
import { createCart, getCartForUpdateCart, updateCart } from "./cartServices"
import { reValidatePromocode } from "./promocodeUtils"

function addToCart(params: any) {
    try {
        const {
            currentCart,
            cartItem,
            specialRequest,
            isEdit,
            editedItemIndex,
            conceptVat,
            conceptServiceCharge,
            addDeliveryToVat,
            orderType,
        } = params
        let newOrderdItems = [...currentCart.orderedItems]
        if (isEdit) {
            const sameMenuItem = currentCart.orderedItems.filter((item: any) => item.menuItemID === cartItem.menuItemID)
            if (sameMenuItem.length > 1) {
                const existingItemIndex = currentCart.orderedItems.findIndex(
                    (item: any) =>
                        item.menuItemID + item.price + item.priceName + item.choiceIDs.sort() + item.notes ===
                        cartItem.menuItemID +
                            cartItem.price +
                            cartItem.priceName +
                            cartItem.choiceIDs.sort() +
                            cartItem.notes
                )
                if (existingItemIndex !== -1) {
                    // Update existing item with new values
                    newOrderdItems[existingItemIndex].quantity += cartItem.quantity
                    newOrderdItems = newOrderdItems.filter((item, index) => index !== editedItemIndex)
                } else {
                    // replace the last item with the new item
                    newOrderdItems[editedItemIndex] = cartItem
                }
            } else {
                newOrderdItems[editedItemIndex] = cartItem
            }
        } else {
            const existingItemIndex = currentCart.orderedItems.findIndex(
                (item: any) =>
                    item.menuItemID + item.price + item.priceName + item.choiceIDs.sort() + item.notes ===
                    cartItem.menuItemID +
                        cartItem.price +
                        cartItem.priceName +
                        cartItem.choiceIDs.sort() +
                        cartItem.notes
            )

            if (existingItemIndex !== -1) {
                // Update existing item with new values
                cartItem.quantity += newOrderdItems[existingItemIndex].quantity
                newOrderdItems[existingItemIndex] = cartItem
            } else {
                // Add new item to array
                newOrderdItems.push(cartItem)
            }
        }
        for (let i = 0; i < newOrderdItems.length; i++) {
            delete newOrderdItems[i].__typename
        }

        const updateInput: any = {
            id: currentCart.id,
            orderedItems: newOrderdItems,
            _version: currentCart._version,
        }

        const subTaxTotal = getCartSubTaxTotal(updateInput.orderedItems)
        const subTotal = getCartSubTotal(subTaxTotal, conceptServiceCharge, orderType)
        //calculate loyaltyDiscount
        //le.log({ fromAddToCart: updateInput.orderedItems })
        const loyaltyDiscount = getCartLoyaltyDiscount(updateInput.orderedItems)
        const totalPrice = getCartTotalPrice(
            subTotal,
            conceptVat,
            currentCart.deliveryFee,
            addDeliveryToVat,
            loyaltyDiscount
        )
        updateInput.subTaxTotal = subTaxTotal
        updateInput.subTotal = subTotal
        updateInput.totalPrice = totalPrice
        if (specialRequest) {
            updateInput.specialRequest = specialRequest
        }
        return updateInput
    } catch (err) {
        //le.log(err)
        throw err
    }
}

function mergeLocalAndOnlineCart(
    localCart: any,
    onlineCart: any,
    conceptVat: number,
    conceptServiceCharge: number,
    addDeliveryToVat: any,
    orderType: string
) {
    try {
        const mergedCart = { ...onlineCart }
        if (mergedCart.orderedItems === undefined || Object.keys(mergedCart).length === 0) {
            return localCart
        }

        if (!localCart || !localCart.orderedItems || localCart.orderedItems.length < 1) {
            return mergedCart
        }

        for (const localCartItem of localCart.orderedItems) {
            const existingItemIndex = mergedCart.orderedItems.findIndex(
                (onlineCartItem: any) =>
                    onlineCartItem.menuItemID +
                        onlineCartItem.price +
                        onlineCartItem.priceName +
                        onlineCartItem.choiceIDs.sort() +
                        onlineCartItem.notes ===
                    localCartItem.menuItemID +
                        localCartItem.price +
                        localCartItem.priceName +
                        localCartItem.choiceIDs.sort() +
                        localCartItem.notes
            )

            if (existingItemIndex !== -1) {
                const onlineCartItem = mergedCart.orderedItems[existingItemIndex]
                // Update quantity if the item is already in onlineCart
                onlineCartItem.quantity += localCartItem.quantity
            } else {
                // Add new item to onlineCart if not found
                mergedCart.orderedItems.push(localCartItem)
            }
        }

        // Recalculate prices
        const newSubTaxTotal = getCartSubTaxTotal(mergedCart.orderedItems)
        const newSubTotal = getCartSubTotal(newSubTaxTotal, conceptServiceCharge, orderType)
        //le.log({ fromMege: mergedCart.orderedItems })
        //calculate loyaltyDiscount
        const loyaltyDiscount = getCartLoyaltyDiscount(mergedCart.orderedItems)
        const newTotalPrice = getCartTotalPrice(
            newSubTotal,
            conceptVat,
            mergedCart.deliveryFee,
            addDeliveryToVat,
            loyaltyDiscount
        )

        mergedCart.totalPrice = newTotalPrice
        mergedCart.subTaxTotal = newSubTaxTotal
        mergedCart.subTotal = newSubTotal

        return mergedCart
    } catch (err) {
        //le.log(err)
        throw err
    }
}

function getCartItemsQuantity(cart: any) {
    const cartItems = cart?.orderedItems || []
    if (cartItems.length === 0) return 0
    let quantity = 0
    for (const cartItem of cart.orderedItems) {
        quantity += cartItem.quantity
    }
    return quantity
}

function getCartSubTaxTotal(orderedItems: any[]): any {
    if (orderedItems.length === 0) return 0
    let totalPrice = 0
    for (const item of orderedItems) {
        totalPrice += item.price * item.quantity
    }
    return parseFloat(totalPrice.toFixed(2))
}

const getCartSubTotal = (subTaxTotal: number, conceptServiceCharge: number, orderType: string) => {
    let value: any = 0
    if (orderType === "delivery") {
        value = parseFloat((subTaxTotal || 0).toFixed(2))
    } else {
        value = parseFloat((subTaxTotal * (conceptServiceCharge || 1)).toFixed(2))
    }
    return value
}

// Function to get the loyalty discount
const getCartLoyaltyDiscount = (cartItems: any) => {
    let value: any = 0
    for (const cartItem of cartItems) {
        if (cartItem.isLoyalty) {
            value += cartItem.price
        }
    }
    return value
}

const getCartTotalPrice = (
    subTotal: number,
    conceptVat: number,
    deliveryFee: number,
    addDeliveryToVat: Boolean,
    loyaltyDiscount: number
) => {
    let value: any = 0
    if (addDeliveryToVat) {
        value = parseFloat(((subTotal + deliveryFee) * (conceptVat || 1)).toFixed(2))
    } else {
        value = parseFloat((subTotal * (conceptVat || 1)).toFixed(2)) + deliveryFee
    }
    if (loyaltyDiscount > 0) value -= loyaltyDiscount
    return value
}

// function getCartSubTotal(subTaxTotal: number, vat: number) {
//   const value: any = parseFloat((subTaxTotal * (vat || TAX)).toFixed(2));
//   return value;
// }

// function getCartTotalPrice(subTotal: number, deliveryFee: number) {
//   const value: any = parseFloat((subTotal + deliveryFee).toFixed(2));
//   return value;
// }

async function updateOnlineCart(
    newOrderedItems: any,
    newDeliveryFee: number,
    userID: string,
    conceptID: string,
    conceptVat: number,
    conceptServiceCharge: number,
    addDeliveryToVat: any,
    orderType: string
) {
    try {
        const newSubTaxTotal = getCartSubTaxTotal(newOrderedItems)
        const newSubTotal = getCartSubTotal(newSubTaxTotal, conceptServiceCharge, orderType)
        // get loyalty discount
        const loyaltyDiscount = getCartLoyaltyDiscount(newOrderedItems)
        const newTotalPrice = getCartTotalPrice(
            newSubTotal,
            conceptVat,
            newDeliveryFee,
            addDeliveryToVat,
            loyaltyDiscount
        )
        //check existing cart
        const response = await getCartForUpdateCart(userID, conceptID)
        const existingCart = response[0]
        //create new cart if not exist
        if (!existingCart) {
            const createdCart = await createCart(
                conceptID,
                userID,
                removeTypeNameFromItems(newOrderedItems),
                newDeliveryFee,
                newSubTotal,
                newSubTaxTotal,
                newTotalPrice
            )
            return createdCart
        }
        const updatedOrderedItems = removeTypeNameFromItems(newOrderedItems)
        const updatedCart = await updateCart(
            existingCart.id,
            conceptID,
            existingCart._version,
            updatedOrderedItems,
            newTotalPrice,
            userID,
            newDeliveryFee,
            newSubTotal,
            newSubTaxTotal
        )
        return updatedCart
    } catch (err) {
        console.log(`error updating online cart: ${err}`)
    }
}

// async function updateOnlineCart(
//   newOrderedItems: any,
//   newDeliveryFee: number,
//   userID: string,
//   conceptID: string
// ) {
//   try {
//     const newSubTaxTotal = getCartSubTaxTotal(newOrderedItems);
//     const newSubTotal = getCartSubTotal(newSubTaxTotal);
//     const newTotalPrice = getCartTotalPrice(newSubTotal, newDeliveryFee);
//     //check existing cart
//     const response = await getCart(userID, conceptID);
//     const existingCart = response[0];
//     //create new cart if not exist
//     if (!existingCart) {
//       console.log("creating new cart");

//       const createdCart = await createCart(
//         conceptID,
//         userID,
//         removeTypeNameFromItems(newOrderedItems),
//         newDeliveryFee,
//         newSubTotal,
//         newSubTaxTotal,
//         newTotalPrice
//       );
//       return createdCart;
//     }
//     existingCart.totalPrice = newTotalPrice;
//     existingCart.subTaxTotal = newSubTaxTotal;
//     existingCart.subTotal = newSubTotal;
//     existingCart.deliveryFee = newDeliveryFee;
//     existingCart.orderedItems = removeTypeNameFromItems(newOrderedItems);
//     const updatedCart = await updateCart(
//       existingCart.id,
//       existingCart.conceptID,
//       existingCart._version,
//       existingCart.orderedItems,
//       existingCart.totalPrice,
//       userID,
//       existingCart.deliveryFee,
//       existingCart.subTotal,
//       existingCart.subTaxTotal
//     );
//     return updatedCart;
//   } catch (err) {
//     console.log(`error updating online cart: ${err}`);
//   }
// }

function removeTypeNameFromItems(existingItems: any) {
    return existingItems.map((cartItem: any) => {
        return {
            menuItemID: cartItem.menuItemID,
            symphonyID: cartItem.symphonyID,
            preparationAreaID: cartItem.preparationAreaID,
            name: cartItem.name,
            quantity: cartItem.quantity,
            choiceIDs: cartItem.choiceIDs,
            choiceNames: cartItem.choiceNames,
            choiceSymphonyID: cartItem.choiceSymphonyID,
            extraChoiceIDs: cartItem.extraChoiceIDs,
            extraChoiceSymphonyID: cartItem.extraChoiceSymphonyID,
            price: cartItem.price,
            priceName: cartItem.priceName,
            priceMultiLanguages: cartItem.priceMultiLanguages,
            notes: cartItem.notes,
            image: cartItem.image,
            multiLanguages: cartItem.multiLanguages,
            comboItemID: cartItem.comboItemID,
            isLoyalty: cartItem.isLoyalty || false,
        }
    })
}

function decrementOrRemoveItem(cartItems: any, updatedItem: any, index: number) {
    if (updatedItem.quantity > 0) return cartItems
    cartItems.splice(index, 1)
    return cartItems
}

function countItemVersions(itemID: string, items: any) {
    let counter = 0
    for (let i = 0; i < items.length; i++) {
        if (items[i].menuItemID === itemID) counter++
    }
    return counter
}

function getLocalCart(conceptID: string): any | null {
    const cartJson = localStorage.getItem(`userCart-${conceptID}`)
    return cartJson ? JSON.parse(cartJson) : null
}

function setLocalCart(cart: any, conceptID: string): void {
    localStorage.setItem(`userCart-${conceptID}`, JSON.stringify(cart))
}

function updateOfflineCart(
    newOrderedItems: any,
    deliveryFee: number,
    conceptID: string,
    conceptVat: number,
    conceptServiceCharge: number,
    addDeliveryToVat: any,
    orderType: string
) {
    const localCart = getLocalCart(conceptID)
    if (!localCart) {
        return
    }
    const subTaxTotal = getCartSubTaxTotal(newOrderedItems)
    const subTotal = getCartSubTotal(subTaxTotal, conceptServiceCharge, orderType)
    //  get Loyalty Discount
    const loyaltyDiscount = getCartLoyaltyDiscount(newOrderedItems)
    const totalPrice = getCartTotalPrice(subTotal, conceptVat, deliveryFee, addDeliveryToVat, loyaltyDiscount)
    localCart.orderedItems = newOrderedItems
    localCart.subTaxTotal = subTaxTotal
    localCart.subTotal = subTotal
    localCart.totalPrice = totalPrice
    localCart.discount = loyaltyDiscount
    setLocalCart(localCart, conceptID)
}

const updateCartState = (
    updatedCartItems: any,
    newQuantity: number,
    newSubTaxTotal: any,
    deliveryFee: number,
    dispatch: any,
    conceptVat: number,
    conceptServiceCharge: number,
    addDeliveryToVat: any,
    orderType: string,
    promocode?: any,
    ready?: any
) => {
    const newItemsSubTaxTotal = getCartSubTaxTotal(updatedCartItems)
    const newSubTotal = getCartSubTotal(newItemsSubTaxTotal, conceptServiceCharge, orderType)
    //  get Loyalty Discount
    const loyaltyDiscount = getCartLoyaltyDiscount(updatedCartItems)
    const newTotalPrice = getCartTotalPrice(newSubTotal, conceptVat, deliveryFee, addDeliveryToVat, loyaltyDiscount)
    reValidatePromocode(deliveryFee, newSubTotal, newQuantity, promocode, dispatch)
    dispatch(setCart([...updatedCartItems]))
    dispatch(setCartQuantity(newQuantity))
    dispatch(setCartSubTaxTotal(parseFloat(newItemsSubTaxTotal.toFixed(2))))
    dispatch(setCartSubTotal(newSubTotal))
    dispatch(setCartTotal(newTotalPrice))
    dispatch(setLoyaltyDiscount(loyaltyDiscount))
    if (ready) {
        dispatch(setCartReady(true))
    }
}

export {
    addToCart,
    countItemVersions,
    decrementOrRemoveItem,
    getCartItemsQuantity,
    getCartSubTaxTotal,
    getCartSubTotal,
    getCartTotalPrice,
    getLocalCart,
    getCartLoyaltyDiscount,
    mergeLocalAndOnlineCart,
    removeTypeNameFromItems,
    setLocalCart,
    updateCartState,
    updateOfflineCart,
    updateOnlineCart,
}
