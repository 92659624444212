import { EndPoints, LambdaFunctions } from "../constants/Enums"
import invokeLambda from "./invokeLambda"

async function getCart(userID: string, conceptID: string) {
    try {
        const operationId = 2

        const requestBody = {
            userID,
            conceptID,
            operationId,
        }
        return await invokeLambda(LambdaFunctions.callCenterOperations, requestBody)
    } catch (error) {
        console.error("Error getting Cart :", error)
        // throw error;
    }
}

async function getCartForUpdateCart(userID: string, conceptID: string) {
    try {
        const operationId = 37
        const requestBody = {
            userID,
            conceptID,
            operationId,
        }
        return await invokeLambda(LambdaFunctions.callCenterOperations, requestBody)
    } catch (error) {
        console.error("Error getting Cart :", error)
        // throw error;
    }
}

async function createCart(
    conceptID: string,
    userID: string,
    orderedItems: any,
    deliveryFee: number,
    subTotal: number,
    subTaxTotal: number,
    totalPrice: number
) {
    try {
        const operationId = 12

        const discount = calculateDiscount(orderedItems)
        totalPrice = totalPrice - discount

        console.log({
            conceptID,
            orderedItems,
            totalPrice,
            userID,
            deliveryFee,
            subTotal,
            subTaxTotal,
            discount,
        })
        const requestBody = {
            operationId,
            conceptID,
            userID,
            orderedItems,
            deliveryFee,
            subTotal,
            subTaxTotal,
            totalPrice,
            discount,
        }
        return await invokeLambda(LambdaFunctions.callCenterOperations, requestBody)
    } catch (error) {
        console.error("Error creating Cart :", error)
        throw error
    }
}

async function updateCart(
    cartID: string,
    conceptID: string,
    version: number,
    newOrderedItems: any[],
    totalPrice: any,
    userID: string,
    deliveryFee: number,
    subTotal: number,
    subTaxTotal: number,
) {
    try {
        const operationId = 5

        console.log({ newOrderedItems })

        const discount = calculateDiscount(newOrderedItems)
        totalPrice = totalPrice - discount

        console.log({
            cartID,
            conceptID,
            version,
            newOrderedItems,
            totalPrice,
            userID,
            deliveryFee,
            subTotal,
            subTaxTotal,
            discount,
        })

        const requestBody = {
            operationId,
            conceptID,
            cartID,
            version,
            userID,
            newOrderedItems,
            deliveryFee,
            subTotal,
            subTaxTotal,
            totalPrice,
            discount,
        }
        return invokeLambda(LambdaFunctions.callCenterOperations, requestBody)
    } catch (error) {
        console.error("Error updating Cart :", error)
        throw error
    }
}

function calculateDiscount(cardItems: any[]) {
    let discount = 0
    cardItems.forEach((cardItem: any) => {
        if (cardItem?.isLoyalty) {
            discount += cardItem?.price
        }
    })
    return discount
}

export { getCart, createCart, updateCart, getCartForUpdateCart }
