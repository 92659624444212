import { Grid, Typography, Button, Box } from "@mui/material"
import { useState, useEffect } from "react"
import Redeem from "./Redeem"
import Navbar from "../Nav/Navbar"
import RewardOtp from "./RewardOtp"
import Earn from "./Earn"
import { capitalization, numberTranslation } from "../../constants/helperFunction"
import { useTranslation } from "react-i18next"
import Transactions from "./Transactions"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import { getLoyaltyItems, getUserLoyaltyTransactions } from "../../services/loyaltyServices"
import { setLogin, setRewardItems, setTransactions } from "../../state"
import getUser from "../../services/getUser"
import { useNavigate } from "react-router-dom"
// import RewardCard from "./RewardCard";
import Redeemed from "./Redeemed"
import { generateClient } from "aws-amplify/api"
import { onUpdateOTP } from "../../graphql/subscriptions"
import RewardCard from "./RewadCard_2"

interface RewardItemType {
    id: string
    name: string
    description: string
    points: number
    redemptionPerUser: number
    multiLanguages: any
    image: string
}

interface TransactionItem {
    id: string
    name: string
    points: number
    createdAt: string
    channel: string
    type: string
}

const emptyTransactions = []

const API = generateClient()

function Rewards() {
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const themeMode = useSelector((state: any) => state.mode)
    const user = useSelector((state: any) => state.user)
    const transactions = useSelector((state: any) => state.transactions)

    const [showRedeem, setShowRedeem] = useState(false)
    const [showEarn, setShowEarn] = useState(false)
    const [showRewardCard, setShowRewardCard] = useState(false)
    const [showRedeemed, setShowRedeemed] = useState(false)
    const [selectedItem, setSelectedItem] = useState<RewardItemType>({
        id: "",
        name: "",
        description: "",
        points: 0,
        redemptionPerUser: 0,
        multiLanguages: "",
        image: "./assets/image1.png",
    })
    const [showOtp, setShowOtp] = useState(false)
    const [otp, setOtp] = useState<string>("")

    const handleRedeemClick = () => {
        setShowRedeem(true)
    }
    const closeRedeem = () => {
        setShowRedeem(false)
    }
    const handleEarnClick = () => {
        setShowEarn(true)
    }
    const closeEarn = () => {
        setShowEarn(false)
    }
    const closeAll = () => {
        setShowRedeem(false)
        setShowEarn(false)
        setShowOtp(false)
        setShowRewardCard(false)
    }
    const handleShowRewardCard = (item: RewardItemType) => {
        setSelectedItem(item)
        setShowRedeem(false)
        setShowRewardCard(true)
    }
    const closeRewardCard = () => {
        setShowRewardCard(false)
        setShowRedeem(true)
    }
    const handleShowOtp = () => {
        setShowRewardCard(false)
        setShowOtp(true)
    }
    const closeOtp = () => {
        setShowOtp(false)
        setShowRewardCard(true)
    }
    const handleSetOtp = (otp: string) => {
        setOtp(otp)
    }
    const closeRedeemed = () => {
        setShowRedeemed(false)
        navigate("/")
    }

    const fetchRewardItems = async () => {
        const rewardItemsArr = await getLoyaltyItems()
        dispatch(setRewardItems(rewardItemsArr ? rewardItemsArr : []))
    }

    const fetchLoyaltyTransactions = async () => {
        if (!user) return
        const fetchedTransactions = await getUserLoyaltyTransactions(user.id, 50, 1)
        dispatch(setTransactions(fetchedTransactions))
    }

    const fetchUser = async () => {
        if (!user) {
            return navigate("/")
        }
        try {
            const fetchedUser = await getUser(user.id)
            dispatch(setLogin({ user: fetchedUser }))
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchUser()
        fetchRewardItems()
        fetchLoyaltyTransactions()
    }, [])

    useEffect(() => {
        const subscription = API.graphql({ query: onUpdateOTP })
        if ("subscribe" in subscription) {
            const otpSubscription = subscription.subscribe({
                next: async (event: any) => {
                    const updatedOTP = event.data.onUpdateOTP
                    if (updatedOTP.deleted !== "1" || updatedOTP.userID !== user.id || !updatedOTP.redeemed) return
                    setShowRedeemed(true)
                },
                error: (error: any) => {
                    console.error("Subscription error:", error)
                },
            })
            return () => {
                if ("unsubscribe" in otpSubscription) {
                    otpSubscription.unsubscribe()
                }
            }
        }
    }, [showRedeemed])

    return (
        <Grid>
            <Grid sx={{ padding: "1rem", marginTop: "2rem" }}>
                <Grid item xs={12} sx={{ direction: i18n.language === "Arabic" ? "rtl" : "ltr" }}>
                    <Link to="/">
                        <Button
                            sx={{
                                backgroundColor: "rgba(169, 169, 169, 0.55)",
                                color: "rgba(64, 64, 64, 0.7)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                minWidth: " 2rem",
                                height: "2rem",
                                borderRadius: "0.5rem",
                                marginBottom: "0.5rem",
                                "&:hover": {
                                    backgroundColor: "#33bde8",
                                },
                            }}
                        >
                            <ArrowBackIosNewIcon
                                sx={{
                                    color: "#33BDE8",
                                    fontSize: "1rem",
                                    transform: i18n.language === "Arabic" ? "rotate(180deg)" : "none",
                                }}
                            />
                        </Button>
                    </Link>
                </Grid>

                <Grid>
                    <Typography sx={{ fontSize: "18px", fontWeight: 600, color: "#9B9B9B" }}>
                        {t("welcome to")}{" "}
                    </Typography>
                    <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>{t("pableAndAbdoRewards")} </Typography>
                </Grid>
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        padding: "1rem",
                        backgroundColor: themeMode === "dark" ? "#393744" : "#D3D3D3",
                        marginY: "1rem",
                    }}
                >
                    <Grid item sm={8}>
                        <Typography
                            sx={{
                                fontSize: "22",
                                fontWeight: "500",
                            }}
                        >
                            {t("availablePoints")}
                        </Typography>
                        <Typography
                            fontSize={35}
                            sx={{
                                fontWeight: "700",
                            }}
                        >
                            {`${numberTranslation(user?.points ? user.points : 0, i18n.language)} ${t("points")}`}
                        </Typography>
                    </Grid>
                    <Grid item sm={3}>
                        <img src="./assets/reward.png" style={{ minWidth: "50px", height: "50px" }} alt="" />
                    </Grid>
                    <Grid item xs={10} sx={{ marginBottom: "1rem" }}>
                        <Typography fontSize={15}>{t("rewardsBody")}</Typography>
                    </Grid>

                    <Grid container>
                        <Grid item sm={6} xs={6} sx={{ display: "flex", justifyContent: "center" }}>
                            <Button
                                sx={{
                                    width: "100%",
                                    background: "#F07EAA",
                                    paddingX: "1rem",
                                    paddinY: "0.5rem",
                                    mx: "4px",
                                    "&:hover": {
                                        boxShadow: "0px 0px 5px 2px rgb(240,126,165,0.7)",
                                        background: "#33BDE8",
                                    },
                                }}
                                onClick={handleEarnClick}
                            >
                                {t("how to earn")}
                            </Button>
                        </Grid>
                        <Grid item sm={6} xs={6} sx={{ display: "flex", justifyContent: "center" }}>
                            <Button
                                sx={{
                                    width: "100%",
                                    background: "#33BDE8",
                                    paddingX: "1rem",
                                    paddinY: "0.5rem",
                                    mx: "4px",
                                    "&:hover": {
                                        boxShadow: "0px 0px 5px 2px rgb(240,126,165,0.7)",
                                        background: "#33BDE8",
                                    },
                                }}
                                onClick={handleRedeemClick}
                            >
                                {t("redeem")}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container justifyContent="center" mb={7}>
                    {transactions?.length > 0 ? (
                        <Transactions />
                    ) : (
                        <>
                            <Grid item sm={12} xs={12} display="flex" justifyContent="center" sx={{ marginY: "3rem" }}>
                                <img src="./assets/reward.png" alt="" />
                            </Grid>
                            <Typography>{t("You have no transactions yet")}</Typography>
                        </>
                    )}
                </Grid>

                <Grid container>
                    {showRedeem && (
                        <div className="overlay">
                            <Redeem onClose={closeRedeem} onRewardItemClick={handleShowRewardCard} />
                        </div>
                    )}
                    {showEarn && (
                        <div className="overlay">
                            <Earn onRedeem={handleRedeemClick} onClose={closeEarn} />
                        </div>
                    )}
                    {showRewardCard && (
                        <div className="overlay">
                            {/* <RewardCard id={selectedItem.id} name={selectedItem.name} description={selectedItem.description} points={selectedItem.points} redemptionPerUser={selectedItem.redemptionPerUser} multiLanguages={selectedItem.multiLanguages} image={selectedItem.image} onClose={closeRewardCard} onShowOtp={handleShowOtp} onCloseOtp={closeOtp} handleSetOtp={handleSetOtp}/> */}
                            <RewardCard
                                id={selectedItem.id}
                                loyaltyItem={selectedItem}
                                name={selectedItem.name}
                                description={selectedItem.description}
                                points={selectedItem.points}
                                redemptionPerUser={selectedItem.redemptionPerUser}
                                multiLanguages={selectedItem.multiLanguages}
                                image={selectedItem.image}
                                onClose={closeRewardCard}
                                onShowOtp={handleShowOtp}
                                onCloseOtp={closeOtp}
                                handleSetOtp={handleSetOtp}
                            />
                        </div>
                    )}
                    {showOtp && (
                        <div className="overlay">
                            <RewardOtp otp={otp} onClose={closeOtp} />
                        </div>
                    )}
                    {showRedeemed && (
                        <div className="overlay">
                            <Redeemed onClose={closeRedeemed} />
                        </div>
                    )}
                </Grid>
            </Grid>
            {!showRedeem && !showEarn && !showOtp && !showRewardCard && !showRedeemed && <Navbar />}
        </Grid>
    )
}

export default Rewards
