import { useSelector } from "react-redux"
import { IRewardItem } from "../../constants/interfaces"
import "./RedeemableItemsList.css" // Import styles

const RedeemableItemsList = ({ addToCart, hasLoyalty }) => {
    const rewardItems = useSelector((state: any) => state.rewardItems)
    const discountedValue = useSelector((state: any) => state.discountedValue)
    const user = useSelector((state: any) => state.user)

    return (
        <div className="redeemable-items">
            <p className="redeem-points">
                🎁 You have <strong>{user?.points || 0}</strong> Redeemable points.
            </p>

            <div className="reward-list-container">
                {rewardItems && rewardItems?.length > 0 ? (
                    <div className="reward-list">
                        {rewardItems.map((item: IRewardItem) => (
                            <div className="reward-card" key={item.name}>
                                <div className="reward-info">
                                    <h4>{item.name && item.name[0].toUpperCase() + item.name?.slice(1)}</h4>
                                    <p className="reward-points">{item.points} pts</p>
                                </div>
                                <button
                                    className="redeem-btn"
                                    onClick={() => addToCart(item)}
                                    disabled={user?.points < item.points || hasLoyalty || discountedValue > 0}
                                >
                                    {user?.points >= item.points
                                        ? hasLoyalty
                                            ? "Redeemed"
                                            : "Redeem"
                                        : "Not Enough Points"}
                                </button>
                            </div>
                        ))}
                    </div>
                ) : (
                    <h4>Coming soooooooooooooooooon!</h4>
                )}
            </div>
        </div>
    )
}

export default RedeemableItemsList
