import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import { Box, Button, Stack } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { numberTranslation } from "../../constants/helperFunction"
import {
    countItemVersions,
    decrementOrRemoveItem,
    updateCartState,
    updateOfflineCart,
    updateOnlineCart,
} from "../../services/cartUtils"
import { setCartReady, setSelectedItem } from "../../state/index"
interface CounterComponentProps {
    itemID: string
    categoryID: string
    item: any
    setMessage: (str: any) => void
    openSnack: () => void
}
const CounterComponent = ({ itemID, categoryID, item, setMessage, openSnack }: CounterComponentProps) => {
    const [value, setValue] = useState<number>(0)
    const [disableBtns, setDisableBtns] = useState<boolean>(false)
    const theme = useTheme()
    const { t, i18n } = useTranslation()
    const items: any[] = useSelector((state: any) => state.cart.orderedItems || [])
    const totalQuantity = useSelector((state: any) => state.cart.quantity || 0)
    const subTaxTotal = useSelector((state: any) => state.cart.subTaxTotal || 0)
    const deliveryFee = useSelector((state: any) => state.cart.deliveryFee || 0)
    const promocode = useSelector((state: any) => state.promocode)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector((state: any) => state.user)
    const selectedConcept = useSelector((state: any) => state.selectedConcept)
    const [debouncedValue, setDebouncedValue] = useState(0)
    const [initialValue, setInitialValue] = useState(0)
    const handleIncrement = async () => {
        dispatch(setCartReady(false))
        if (!selectedConcept) return
        setDisableBtns(true)
        const index = items.findIndex((item) => item.menuItemID === itemID)
        if (index < 0) {
            // item is new so redirect the user to the item page
            dispatch(setSelectedItem(item))
            navigate(`/menu/${categoryID}/${itemID}`)
        } else {
            // item already exists in the cart
            const itemVersions = countItemVersions(itemID, items)
            if (itemVersions > 1) {
                // if itemVersions > 1 redirect the user to the itemPage to choose which choices and price he wants to add
                dispatch(setSelectedItem(item))
                navigate(`/menu/${categoryID}/${itemID}`)
            } else {
                // else there is one cartItem with this itemID then inc it
                const updatedItems = [...items]
                const updatedItem = {
                    ...updatedItems[index],
                    quantity: value,
                }
                updatedItems[index] = updatedItem
                const newQuantity = totalQuantity + value - initialValue
                const newSubTaxTotal = subTaxTotal + updatedItem.price * (value - initialValue)
                if (user) {
                    await updateOnlineCart(
                        updatedItems,
                        deliveryFee,
                        user.id,
                        selectedConcept.id,
                        selectedConcept.vatPercentage,
                        selectedConcept.serviceChargePercentage,
                        selectedConcept.addDeliveryToVat,
                        "delivery" // not used
                    )
                } else {
                    updateOfflineCart(
                        updatedItems,
                        deliveryFee,
                        selectedConcept.id,
                        selectedConcept.vatPercentage,
                        selectedConcept.serviceChargePercentage,
                        selectedConcept.addDeliveryToVat,
                        "delivery" // not used
                    )
                }
                updateCartState(
                    updatedItems,
                    newQuantity,
                    newSubTaxTotal,
                    deliveryFee,
                    dispatch,
                    selectedConcept.vatPercentage,
                    selectedConcept.serviceChargePercentage,
                    selectedConcept.addDeliveryToVat,
                    "delivery", // not used
                    promocode
                )
            }
        }
        setInitialValue(value)
        setDisableBtns(false)
        dispatch(setCartReady(true))
    }

    const handleDecrement = async () => {
        dispatch(setCartReady(false))
        if (!selectedConcept) return
        setDisableBtns(true)

        const index = items.findIndex((item) => item.menuItemID === itemID)
        if (index < 0) {
            setDisableBtns(false)
            return
        } else {
            const itemVersions = countItemVersions(itemID, items)
            if (itemVersions > 1) {
                navigate("/cart")
            } else {
                const updatedItems = [...items]
                const updatedItem = {
                    ...updatedItems[index],
                    quantity: value,
                }
                updatedItems[index] = updatedItem
                const finalUpdatedItems = decrementOrRemoveItem(updatedItems, updatedItem, index)
                const newQuantity = totalQuantity - (initialValue - value)
                const newSubTaxTotal = subTaxTotal - updatedItem.price * (initialValue - value)
                if (user) {
                    await updateOnlineCart(
                        updatedItems,
                        deliveryFee,
                        user.id,
                        selectedConcept.id,
                        selectedConcept.vatPercentage,
                        selectedConcept.serviceChargePercentage,
                        selectedConcept.addDeliveryToVat,
                        "delivery" // not used
                    )
                } else {
                    updateOfflineCart(
                        updatedItems,
                        deliveryFee,
                        selectedConcept.id,
                        selectedConcept.vatPercentage,
                        selectedConcept.serviceChargePercentage,
                        selectedConcept.addDeliveryToVat,
                        "delivery" // not used
                    )
                }
                updateCartState(
                    finalUpdatedItems,
                    newQuantity,
                    newSubTaxTotal,
                    deliveryFee,
                    dispatch,
                    selectedConcept.vatPercentage,
                    selectedConcept.serviceChargePercentage,
                    selectedConcept.addDeliveryToVat,
                    "delivery", // not used
                    promocode
                )
                setInitialValue(value)
            }
        }
        dispatch(setCartReady(true))
        setDisableBtns(false)
    }
    const handleIncrementDebounced = () => {
        if (item.outOfStock || (item.hasLimitedQuantity && item.quantity <= debouncedValue)) {
            setMessage("Item is out of stock.")
            openSnack()
            return
        }
        setDebouncedValue((prev) => prev + 1)
    }

    const handleDecrementDebounced = () => {
        setDebouncedValue((prev) => (prev > 0 ? prev - 1 : 0))
    }
    useEffect(() => {
        // put each item quantity as its value
        const index = items.findIndex((item) => item.menuItemID === itemID)
        if (index < 0) return
        const versions = countItemVersions(itemID, items)
        const item = items[index]
        if (versions > 1) return
        setDebouncedValue(item.quantity)
        setInitialValue(item.quantity)
    }, [])

    useEffect(() => {
        // changeUpdating(true);

        const timer = setTimeout(() => {
            setValue(debouncedValue)
            //   changeUpdating(false);
        }, 700)

        return () => clearTimeout(timer)

        // eslint-disable-next-line
    }, [debouncedValue])
    useEffect(() => {
        const handleUpdateValue = async () => {
            if (!selectedConcept) return
            if (value === initialValue) {
                return
            } else if (value > initialValue) {
                handleIncrement()
            } else {
                handleDecrement()
            }
        }

        if (debouncedValue !== 0 || initialValue !== 0) handleUpdateValue()
    }, [value])
    if (debouncedValue === 0) {
        return <div></div>
    }

    return (
        <Stack direction="row" gap={1}>
            <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{
                    height: "19px",
                    width: "19px",
                    border: "1px solid ",
                    borderRadius: "3px",
                    borderColor: theme.palette.info.main,
                }}
            >
                <Button onClick={handleDecrementDebounced} disabled={disableBtns}>
                    <RemoveIcon
                        sx={{
                            color: theme.palette.info.main,
                            height: "15px",
                            width: "15px",
                        }}
                    />
                </Button>
            </Box>
            <Box
                sx={{
                    color: theme.palette.primary.main,
                    fontSize: "15px",
                    fontWeight: "500px",
                }}
            >
                {numberTranslation(debouncedValue, i18n.language)}
                {/* {value} */}
            </Box>
            <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{
                    height: "19px",
                    width: "19px",
                    borderRadius: "3px",
                    backgroundColor: theme.palette.info.light,
                }}
            >
                <Button onClick={handleIncrementDebounced} disabled={disableBtns}>
                    <AddIcon sx={{ height: "15px", width: "15px" }} />
                </Button>
            </Box>
        </Stack>
    )
}

export default CounterComponent
