import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

const PATH_STORAGE_KEY = "lastVisitedPath";

const InitialRedirect = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [hasRedirected, setHasRedirected] = useState(false);

  const categories =
    useSelector((state: any) => state.categoriesState.listing) || [];

  useEffect(() => {
    const savedPath = localStorage.getItem(PATH_STORAGE_KEY);

    if (
      !hasRedirected &&
      savedPath &&
      categories.length > 0 &&
      !savedPath?.includes("menu") &&
      savedPath !== location.pathname &&
      location.pathname === "/"
    ) {
      navigate(savedPath);
      setHasRedirected(true);
    }
    if (savedPath?.includes("menu")) {
      navigate("/");
    }
  }, [categories, location.pathname]);

  return null;
};

export default InitialRedirect;
