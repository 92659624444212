import { LambdaFunctions } from "../constants/Enums";
import invokeLambda from "./invokeLambda";

const ZONES_CACHE_KEY = "zonesCache";
const CACHE_TTL_MS = 24 * 60 * 60 * 1000;

async function listZones() {
  try {
    const cached = localStorage.getItem(ZONES_CACHE_KEY);

    if (cached) {
      const { data, timestamp } = JSON.parse(cached);

      const isFresh = Date.now() - timestamp < CACHE_TTL_MS;

      if (isFresh) {
        console.log(" Returning cached zones");
        return data;
      } else {
        console.log(" Cache expired. Fetching fresh zones...");
      }
    }

    const operationId = 22;
    const requestBody = { operationId };

    const freshZones = await invokeLambda(
      LambdaFunctions.callCenterOperations,
      requestBody
    );

    const cachePayload = {
      data: freshZones,
      timestamp: Date.now(),
    };
    localStorage.setItem(ZONES_CACHE_KEY, JSON.stringify(cachePayload));

    return freshZones;
  } catch (error) {
    console.error(" Error listing zones:", error);
    throw error;
  }
}

export default listZones;
