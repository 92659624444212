import { Add, Remove } from "@mui/icons-material"
import CloseIcon from "@mui/icons-material/Close"
import EditNoteIcon from "@mui/icons-material/EditNote"
import { LoadingButton } from "@mui/lab"
import {
    Alert,
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    Snackbar,
    TextField,
    Typography,
    useTheme,
} from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { ChoiceGroups } from "../../API"
import { capitalization, numberTranslation } from "../../constants/helperFunction"
import { CartItem, Choice, ChoiceGroupRules } from "../../models"
import * as cartServices from "../../services/cartServices"
import * as cartUtils from "../../services/cartUtils"
import { fetchData } from "../../services/categoryPageUtils"
import { setSelectedItem } from "../../state"

//mytext theme.palette.primary.dark
interface Price {
    id: string
    name: string
    multiLanguages: string
    price: number
    symphonyID: string
}
interface ChoiceGroupItem {
    choiceGroupID: string
    choiceGroupName: string
    choiceGroupMultiLanguages: string
    choices: ChoiceItem[]
}
interface ChoiceItem {
    id: string
    symphonyID: string
    name: string
    price: number
    multiLanguages: string
}

function MenuItems() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const theme = useTheme()
    const { t, i18n } = useTranslation()

    // const user = useSelector((state: any) => state.user || {});
    const selectedItem = useSelector((state: any) => state.selectedItem || null)
    const selectedConcept = useSelector((state: any) => state.selectedConcept)
    const categories = useSelector((state: any) => state.categoriesState) || {}
    const user = useSelector((state: any) => state.user)
    const items: any[] = useSelector((state: any) => state.cart.orderedItems || [])
    const promocode = useSelector((state: any) => state.promocode)
    const promocodeName = useSelector((state: any) => state.promocodeName)
    const channel = useSelector((state: any) => state.channel)

    const { state } = useLocation()
    const { itemId, categoryId } = useParams()

    const EditedcartItem: CartItem | undefined = state?.cartItem
    let { editedQuantity, editedSelectedSize, editedSubTaxTotal, selectedChoices, editedNotes, editedItemIndex } =
        prepareEditedItem(EditedcartItem)
    const [subTaxTotal, setSubTaxTotal] = useState<number>(editedSubTaxTotal || selectedItem?.prices[0]?.price)
    const [choicesPrice, setChoicesPrice] = useState(0)
    const [quantity, setQuantity] = useState(editedQuantity || 1)
    const [addToCartDisabled, setAddToCartDisabled] = useState<boolean>(false)
    const [validationWarning, setValidationWarning] = useState<boolean>(false)
    const [outOfStockWarning, setOutOfStockWarning] = useState<boolean>(false)
    const [selectedSize, setSelectedSize] = useState<any | null>({
        name: editedSelectedSize?.name || selectedItem?.prices[0]?.name || null,
        multiLanguages: editedSelectedSize?.multiLanguages || selectedItem?.prices[0]?.multiLanguages || null,
        priceId: editedSelectedSize?.id || selectedItem?.prices[0]?.id || null,
        price: editedSelectedSize?.price || selectedItem?.prices[0]?.price || null,
        symphonyID: editedSelectedSize?.symphonyID || selectedItem?.prices[0]?.symphonyID || null,
    })
    const [checkedItems, setCheckedItems] = useState<Record<string, number>>({})
    const [choiceGroupsMax, setChoiceGroupsMax] = useState<Record<string, number>>({})
    const [notes, setNotes] = useState(editedNotes || "")
    const [loading, setLoading] = useState(false)

    const deliveryFee = 0

    function prepareEditedItem(EditedcartItem: CartItem | undefined) {
        let editedQuantity = EditedcartItem?.quantity
        let editedSelectedSize = selectedItem?.prices.find((price: any) => price.name === EditedcartItem?.priceName)
        let editedSubTaxTotal = EditedcartItem ? EditedcartItem.price * EditedcartItem.quantity : null
        let editedNotes = EditedcartItem?.notes

        let ItemChoices: Choice[] = selectedItem?.choiceGroups
            .map((choiceGroup: ChoiceGroups) => choiceGroup.choiceItems.items?.map((choice: any) => choice))
            .flat()

        // let selectedChoices = ItemChoices?.filter(
        //   (choice) => EditedcartItem?.choiceIDs?.includes(choice.id)
        // );

        let selectedChoices: Choice[] = []

        for (const choiceID of EditedcartItem?.choiceIDs ?? []) {
            const selectedChoice = ItemChoices.find((choice) => choice.id === choiceID)
            if (selectedChoice) {
                selectedChoices.push(selectedChoice)
            }
        }

        let editedItemIndex = items.findIndex((item: any) => {
            const editedItem: any = EditedcartItem ?? {} // If EditedcartItem is null or undefined, use an empty object

            // Extract properties from the edited item with nullish coalescing operator (??)
            const { menuItemID, price, priceName, choiceIDs, notes } = editedItem

            // Ensure choiceIDs is sorted before comparison
            const sortedChoiceIDs = choiceIDs ? [...choiceIDs].sort() : null

            // Concatenate properties into strings for comparison, ensuring nullish values are treated as empty strings
            const itemProperties = `${item.menuItemID}${item.price}${item.priceName}${
                item.choiceIDs ? [...item.choiceIDs].sort().join("") : ""
            }${item.notes}`
            const editedItemProperties = `${menuItemID}${price}${priceName}${
                sortedChoiceIDs ? sortedChoiceIDs.join("") : ""
            }${notes}`

            // Return true if the properties match, otherwise false
            return itemProperties === editedItemProperties
        })
        let checked: Record<string, boolean> = {}
        return {
            editedQuantity,
            editedSelectedSize,
            editedSubTaxTotal,
            editedNotes,
            selectedChoices,
            editedItemIndex,
        }

        return {
            editedQuantity,
            editedSelectedSize,
            editedSubTaxTotal,
            editedNotes,
            selectedChoices,
            editedItemIndex,
        }
    }

    const handleCheckboxChange = (choiceId: string, choicePrice: number, choiceGroupID: string, action: string) => {
        setCheckedItems((checkedItems) => {
            const newCheckedItems = { ...checkedItems }
            if (action === "add") {
                // Add
                if (newCheckedItems[choiceId]) {
                    newCheckedItems[choiceId] += 1
                } else {
                    newCheckedItems[choiceId] = 1
                }
                setChoicesPrice((choicesPrice) => {
                    setSubTaxTotal(() =>
                        parseFloat(((choicesPrice + choicePrice + selectedSize.price) * quantity).toFixed(2))
                    )
                    return choicesPrice + choicePrice
                })

                setChoiceGroupsMax((choiceGroupsMax) => {
                    let newChoiceGroupsMax = { ...choiceGroupsMax }
                    newChoiceGroupsMax[choiceGroupID] = newChoiceGroupsMax[choiceGroupID] - 1
                    return newChoiceGroupsMax
                })
            } else {
                // Remove
                setChoicesPrice((choicesPrice) => {
                    setSubTaxTotal(() =>
                        parseFloat(((choicesPrice - choicePrice + selectedSize.price) * quantity).toFixed(2))
                    )
                    return choicesPrice - choicePrice
                })
                setChoiceGroupsMax((choiceGroupsMax) => {
                    let newChoiceGroupsMax = { ...choiceGroupsMax }
                    newChoiceGroupsMax[choiceGroupID] = newChoiceGroupsMax[choiceGroupID] + 1
                    return newChoiceGroupsMax
                })

                newCheckedItems[choiceId] -= 1
            }
            return newCheckedItems
        })
    }
    const handleIncrement = () => {
        if (selectedItem.hasLimitedQuantity && selectedItem.quantity <= quantity) {
            setOutOfStockWarning(true)
            return
        }
        setQuantity((prevQuantity) => prevQuantity + 1)
        setSubTaxTotal(parseFloat(((choicesPrice + selectedSize.price) * (quantity + 1)).toFixed(2)))
    }

    const handleDecrement = () => {
        if (quantity === 1) return
        setQuantity((prevQuantity) => {
            if (prevQuantity > 0) {
                setSubTaxTotal(parseFloat(((choicesPrice + selectedSize.price) * (quantity - 1)).toFixed(2)))
                return prevQuantity - 1
            }
            return prevQuantity
        })
    }

    const countChoices = (selectedChoices: any[], availableChoices: any[]): number => {
        if (!selectedChoices.length) return 0

        const availableChoiceIds = new Set(availableChoices.map((choice) => choice.id))
        return selectedChoices.reduce(
            (count, selectedChoiceID) => (availableChoiceIds.has(selectedChoiceID) ? count + 1 : count),
            0
        )
    }

    // const countChoices = (
    //   selectedChoices: any,
    //   availableChoices: any
    // ): number => {
    //   if (selectedChoices.length < 1) return 0;
    //   let counter = 0;
    //   for (const availableChoice of availableChoices) {
    //     const idx = selectedChoices.findIndex(
    //       (choiceID: any) => choiceID === availableChoice.id
    //     );
    //     if (idx >= 0) counter++;
    //   }
    //   return counter;
    // };

    // const isSelectionValidated = (choiceIDs: any, choiceGroups: any): boolean => {
    //   for (const choiceGroup of choiceGroups) {
    //     if (choiceGroup.required) {
    //       const { maxNumberOfChoices, minNumberOfChoices } = choiceGroup;
    //       const choicesCount = countChoices(
    //         choiceIDs,
    //         choiceGroup.choiceItems.items
    //       );
    //       if (
    //         choicesCount < minNumberOfChoices ||
    //         choicesCount > maxNumberOfChoices
    //       ) {
    //         setValidationWarning(true);
    //         setAddToCartDisabled(false);
    //         return false;
    //       }
    //     }
    //   }
    //   return true;
    // };

    const isSelectionValidated = (choiceIDs: any, choiceGroups: any): boolean => {
        for (const choiceGroup of choiceGroups) {
            const { maxNumberOfChoices, minNumberOfChoices } = choiceGroup

            const minChoicesNumber =
                selectedItem?.choiceGroupsRules?.find((cg: ChoiceGroupRules) => cg?.id === choiceGroup.id)
                    ?.minNumberOfChoices ?? minNumberOfChoices

            const maxChoicesNumber =
                selectedItem?.choiceGroupsRules?.find((cg: ChoiceGroupRules) => cg?.id === choiceGroup.id)
                    ?.maxNumberOfChoices ?? maxNumberOfChoices

            const choicesCount = countChoices(choiceIDs, choiceGroup.choiceItems.items)
            if (choicesCount < minChoicesNumber || choicesCount > maxChoicesNumber) {
                setValidationWarning(true)
                setAddToCartDisabled(false)
                return false
            }
        }
        return true
    }

    const isSelectionValidatedForDisabled = (checkedItems: any, choiceGroups: any, choiceGroupId: string): boolean => {
        const choiceIDs = []
        Object.keys(checkedItems).forEach((checkedItem) => {
            const count = checkedItems[checkedItem]
            for (let i = 0; i < count; i++) {
                choiceIDs.push(checkedItem)
            }
        })

        const choiceGroup = choiceGroups.find((group) => group.id === choiceGroupId)

        if (!choiceGroup) {
            // If the choice group is not found, return true (or handle the error as needed)
            return true
        }

        const { maxNumberOfChoices, minNumberOfChoices } = choiceGroup

        const maxChoicesNumber =
            selectedItem?.choiceGroupsRules?.find((cg: ChoiceGroupRules) => cg?.id === choiceGroup.id)
                ?.maxNumberOfChoices ?? maxNumberOfChoices

        const choicesCount = countChoices(choiceIDs, choiceGroup.choiceItems.items)
        if (choicesCount >= maxChoicesNumber) {
            return false
        }

        return true
    }

    const handleCartUpdateOffline = (conceptID: any, cartItem: any, subTaxTotal: number) => {
        const existingCart = cartUtils.getLocalCart(conceptID)
        let subTotal = cartUtils.getCartSubTotal(subTaxTotal, selectedConcept.serviceChargePercentage, channel)
        let loyaltyDiscount =  cartUtils.getCartLoyaltyDiscount(existingCart?.orderedItems || [])
        let totalPrice = cartUtils.getCartTotalPrice(
            subTotal,
            selectedConcept.vatPercentage,
            deliveryFee,
            selectedConcept.addDeliveryToVat,
            loyaltyDiscount??0
        )
        if (!existingCart) {
            const newCart = {
                conceptID,
                orderedItems: [cartItem],
                subTaxTotal,
                subTotal,
                deliveryFee,
                totalPrice,
            }
            const newQuantity = cartUtils.getCartItemsQuantity(newCart)
            cartUtils.setLocalCart(newCart, conceptID)
            cartUtils.updateCartState(
                newCart.orderedItems,
                newQuantity,
                newCart.subTaxTotal,
                newCart.deliveryFee,
                dispatch,
                selectedConcept.vatPercentage,
                selectedConcept.serviceChargePercentage,
                selectedConcept.addDeliveryToVat,
                channel,
                promocode
            )
        } else {
            // User already has a cart, so update it by adding the new cart item
            let updatedCart
            if (EditedcartItem) {
                // handle edit item case
                const params = {
                    currentCart: existingCart,
                    cartItem,
                    isEdit: true,
                    editedItemIndex,
                    conceptVat: selectedConcept.vatPercentage,
                    conceptServiceCharge: selectedConcept.serviceChargePercentage,
                    addDeliveryToVat: selectedConcept.addDeliveryToVat,
                    orderType: channel,
                }
                updatedCart = cartUtils.addToCart(params)
            } else {
                const params = { currentCart: existingCart, cartItem, editedItemIndex }
                updatedCart = cartUtils.addToCart(params)
            }
            const newQuantity = cartUtils.getCartItemsQuantity(updatedCart)
            cartUtils.setLocalCart(updatedCart, conceptID)
            cartUtils.updateCartState(
                updatedCart.orderedItems,
                newQuantity,
                updatedCart.subTaxTotal,
                updatedCart.deliveryFee,
                dispatch,
                selectedConcept.vatPercentage,
                selectedConcept.serviceChargePercentage,
                selectedConcept.addDeliveryToVat,
                channel,
                promocode
            )
        }
    }

    const handleCartUpdateOnline = async (conceptID: any, userID: string, cartItem: any, subTaxTotal: number) => {
        const existingCart = await cartServices.getCart(userID, conceptID)
        let subTotal = cartUtils.getCartSubTotal(subTaxTotal, selectedConcept.serviceChargePercentage, channel)
                let loyaltyDiscount =  cartUtils.getCartLoyaltyDiscount(existingCart?.orderedItems || [])

        let totalPrice = cartUtils.getCartTotalPrice(
            subTotal,
            selectedConcept.vatPercentage,
            deliveryFee,
            selectedConcept.addDeliveryToVat,
            loyaltyDiscount??0
        )

        if (existingCart?.length === 0) {
            // user doesn't has a cart so create a new cart
            const orderedItems = [cartItem]
            const newCart = await cartServices.createCart(
                conceptID,
                userID,
                orderedItems,
                deliveryFee,
                subTotal,
                subTaxTotal,
                totalPrice
            )
            const newQuantity = cartUtils.getCartItemsQuantity(newCart)
            cartUtils.updateCartState(
                newCart.orderedItems,
                newQuantity,
                newCart.subTaxTotal,
                newCart.deliveryFee,
                dispatch,
                selectedConcept.vatPercentage,
                selectedConcept.serviceChargePercentage,
                selectedConcept.addDeliveryToVat,
                channel,
                promocode
            )
        } else {
            // user already has a cart so update it by adding the new cart item
            const params = {
                currentCart: existingCart[0],
                cartItem,
                isEdit: !!EditedcartItem,
                editedItemIndex,
                conceptVat: selectedConcept.vatPercentage,
                conceptServiceCharge: selectedConcept.serviceChargePercentage,
                addDeliveryToVat: selectedConcept.addDeliveryToVat,
                orderType: channel,
            }
            const updatedCartObj = cartUtils.addToCart(params)
            subTaxTotal = cartUtils.getCartSubTaxTotal(updatedCartObj.orderedItems)
            subTotal = cartUtils.getCartSubTotal(subTaxTotal, selectedConcept.serviceChargePercentage, channel)
                    let loyaltyDiscount =  cartUtils.getCartLoyaltyDiscount(existingCart?.orderedItems || [])

            totalPrice = cartUtils.getCartTotalPrice(
                subTotal,
                selectedConcept.vatPercentage,
                deliveryFee,
                selectedConcept.addDeliveryToVat,
                loyaltyDiscount??0
            )
            const updatedCart = await cartServices.updateCart(
                updatedCartObj.id,
                conceptID,
                updatedCartObj._version,
                updatedCartObj.orderedItems,
                updatedCartObj.totalPrice,
                userID,
                updatedCartObj.deliveryFee,
                updatedCartObj.subTotal,
                updatedCartObj.subTaxTotal
            )
            const newQuantity = cartUtils.getCartItemsQuantity(updatedCart)
            cartUtils.updateCartState(
                updatedCart.orderedItems,
                newQuantity,
                updatedCart.subTaxTotal,
                updatedCart.deliveryFee,
                dispatch,
                selectedConcept.vatPercentage,
                selectedConcept.serviceChargePercentage,
                selectedConcept.addDeliveryToVat,
                channel,
                promocode
            )
        }
    }

    const handleAddToCart = async () => {
        try {
            setAddToCartDisabled(true)

            const conceptID = selectedItem.conceptID
            const choiceIDs: any = []
            const choiceNames: string[] = []
            const choiceSymphonyID: any = []
            const extraChoiceIDs: any = []
            const extraChoiceSymphonyID: any = []
            let preparationAreaID = ""

            if (quantity < 1) return

            for (const choiceGroup of selectedItem.choiceGroups) {
                const choiceGroupItem: ChoiceGroupItem = {
                    choiceGroupID: choiceGroup.id,
                    choiceGroupName: choiceGroup.name,
                    choiceGroupMultiLanguages: choiceGroup.multiLanguages,
                    choices: [] as any[],
                }
                choiceGroup.choiceItems?.items.forEach((choice: any) => {
                    // if (checkedItems[choice.id]) {
                    //   choiceIDs.push(choice.id);
                    //   choiceGroupItem.choices.push({
                    //     id: choice.id,
                    //     symphonyID: choice.symphonyID,
                    //     name: choice.name,
                    //     price: choice.price,
                    //     multiLanguages: choice.multiLanguages,
                    //   });
                    //   choiceSymphonyID.push(choice.symphonyID);
                    // }
                    Object.keys(checkedItems).forEach((choiceID) => {
                        const count = checkedItems[choiceID]

                        if (count > 0 && choiceID === choice.id) {
                            for (let i = 0; i < count; i++) {
                                choiceIDs.push(choice.id)
                                choiceGroupItem.choices.push({
                                    id: choice.id,
                                    symphonyID: choice.symphonyID,
                                    name: choice.name,
                                    price: choice.price,
                                    multiLanguages: choice.multiLanguages,
                                })
                                choiceSymphonyID.push(choice.symphonyID)
                            }
                        }
                    })
                })
                if (choiceGroupItem.choices.length > 0) {
                    choiceNames.push(JSON.stringify(choiceGroupItem))
                }
            }

            // for (const choiceGroup of selectedItem.choiceGroups) {
            //   choiceGroup.choiceItems?.items.forEach((choice: any) => {
            //     if (checkedItems[choice.id]) {
            //       choiceIDs.push(choice.id);
            //       choiceNames.push(choiceGroup.name==="remove"?(`remove ${choice.name}`):choice.name);
            //       choiceSymphonyID.push(choice.symphonyID);
            //     }
            //   });
            // }

            if (!isSelectionValidated(choiceIDs, selectedItem.choiceGroups)) {
                setValidationWarning(true)
                return
            }

            for (const category of categories.listing) {
                if (category.id === selectedItem.categoryID) {
                    preparationAreaID = category.preparationAreaID
                }
            }
            const cartItem = {
                menuItemID: selectedItem.id,
                symphonyID: selectedSize.symphonyID,
                preparationAreaID,
                name: selectedItem.name,
                quantity,
                choiceIDs,
                multiLanguages: selectedItem.multiLanguages,
                choiceNames,
                choiceSymphonyID,
                extraChoiceIDs,
                extraChoiceSymphonyID,
                price: subTaxTotal / quantity,
                priceName: selectedItem.prices.length > 1 ? selectedSize.name : null,
                priceID: selectedSize.priceId ?? "",
                priceMultiLanguages: selectedItem.prices.length > 1 ? selectedSize.multiLanguages : null,
                notes: notes,
                image: selectedItem.image,
                comboItemID: selectedItem.comboItemID,
            }

            // IF USER IS LOGGED IN WORK WITH THE ONLINE DB
            if (user) {
                await handleCartUpdateOnline(conceptID, user.id, cartItem, subTaxTotal)
                // IF USER IS LOGGED OUT WORK WITH LOCALSTORAGE
            } else {
                handleCartUpdateOffline(conceptID, cartItem, subTaxTotal)
            }
            navigate("/")
        } catch (error) {
            console.error("MenuItems.tsx", " :: prepareEditedItem() :: Error Adding Item To Cart❌ : ", error)
        }
    }

    const handleValidationWarningClose = () => {
        setValidationWarning(false)
        setAddToCartDisabled(false)
    }
    const handleOutOfStockWarningClose = () => {
        setOutOfStockWarning(false)
    }

    const handleSizeSelection = (
        size: string,
        price: number,
        priceId: string,
        multiLanguages: string,
        symphonyID: string
    ) => {
        if (selectedSize.name !== size) {
            setSelectedSize({
                name: size,
                priceId,
                price,
                multiLanguages,
                symphonyID,
            })
            setSubTaxTotal(parseFloat(((choicesPrice + price) * quantity).toFixed(2)))
        }
    }

    const handleNotesChange = (event: any) => {
        setNotes(event.target.value)
    }

    //   const getRemovedChoices = (choiceGroups: any[]) => {
    //     const removedChoices: any[] = [];
    //     for (const choiceGroup of choiceGroups) {
    //       if (choiceGroup.name !== 'remove') continue;
    //       choiceGroup.choices.forEach((choice: any) => {
    //         if (checkedItems[choice.id]) {
    //           removedChoices.push(choice.name);
    //         }
    //       });
    //     }
    //     return removedChoices;
    //   }

    //   const isChoiceRemoved = (choiceName: string, removedChoices: any[]) => {
    //     let isRemoved = false;
    //     for (const removedChoiceName of removedChoices) {
    //         const regex = new RegExp(`\\b${removedChoiceName.toLowerCase()}\\b`, 'i');
    //         if (regex.test(choiceName.toLowerCase())) {
    //             isRemoved = true;
    //             break;
    //         }
    //     }
    //     return isRemoved;
    // };

    //   const unCheckRemovedChoices = (choiceID: string) => {
    //     if (!checkedItems[choiceID]) return;
    //     const updatedCheckedItems = { ...checkedItems }
    //     delete updatedCheckedItems[choiceID];
    //     setCheckedItems(updatedCheckedItems);
    //   }

    //   const filterRemovedChoices = (choiceGroups: any[]) => {
    //     if (choiceGroups.length < 1) return [];
    //     const removedChoices: any[] = getRemovedChoices(choiceGroups);
    //     const filteredChoiceGroups: any[] = [];
    //     for (const choiceGroup of choiceGroups) {
    //       if (choiceGroup.choices.length < 1 || choiceGroup.name === 'remove') {
    //         filteredChoiceGroups.push(choiceGroup);
    //         continue;
    //       };
    //       const updatedChoiceGroupChoices: any[] = [];
    //       choiceGroup.choices.forEach((choice: any) => {
    //         if (!isChoiceRemoved(choice.name, removedChoices)) {
    //           updatedChoiceGroupChoices.push(choice);
    //         } else {
    //           unCheckRemovedChoices(choice.id);
    //         }
    //       });
    //       const choiceGroupCopy = { ...choiceGroup };
    //       choiceGroupCopy.choices = updatedChoiceGroupChoices;
    //       filteredChoiceGroups.push(choiceGroupCopy);
    //     }
    //     return filteredChoiceGroups;
    //   }

    useEffect(() => {
        let choiceGroupsMax: Record<string, number> = {}
        selectedItem?.choiceGroups?.map((choiceGroup: any) => {
            choiceGroupsMax[choiceGroup.id] = choiceGroup.maxNumberOfChoices
        })
        setChoiceGroupsMax(choiceGroupsMax)
        if (EditedcartItem && selectedChoices) {
            for (const choice of selectedChoices) {
                handleCheckboxChange(choice.id, choice.price, choice.choiceGroupID, "add")
            }
        }
    }, [])

    useEffect(() => {
        if (!selectedItem) return
        let choiceGroupsMax: Record<string, number> = {}
        selectedItem?.choiceGroups?.map((choiceGroup: any) => {
            choiceGroupsMax[choiceGroup.id] = choiceGroup.maxNumberOfChoices
        })
        setChoiceGroupsMax(choiceGroupsMax)
        // if (selectedChoices) {
        //   for (const choice of selectedChoices) {
        //     handleCheckboxChange(choice.id, choice.price, choice.choiceGroupID);
        //   }
        // }
        setSubTaxTotal(selectedItem.prices[0]?.price)
    }, [selectedItem])

    useEffect(() => {
        // if (!selectedItem) {
        //   navigate("/");
        // }
        async function fetchSyncData() {
            setLoading(true)

            if (categories.listing.length === 0 && selectedConcept?.id) {
                const data = await fetchData(dispatch, selectedConcept?.id)
                const catgs = data?.categories
                if (catgs && catgs.length > 0) {
                    const selectedCategory = catgs.find((catg) => {
                        // return catg.menuItems.some(
                        //   (item: any) => item.id === state?.selectedItemID
                        // );
                        return catg.id === categoryId
                    })
                    const selectedItem = selectedCategory?.menuItems?.find((item: any) => item.id === itemId)

                    if (selectedItem) {
                        dispatch(setSelectedItem(selectedItem))
                    } else {
                        navigate("/")
                    }
                }
            }
        }
        try {
            // fetchSyncData();
            if (!selectedItem) {
                navigate("/")
            }
        } catch (e: any) {
            console.log("error", e?.message || e)

            navigate("/")
        } finally {
            setLoading(false)
        }
    }, [selectedItem, selectedConcept?.id])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant" as ScrollBehavior,
        })
    }, [])

    const disbled =
        !selectedItem || selectedItem.outOfStock || (selectedItem.hasLimitedQuantity && selectedItem.quantity === 0)

    if (!selectedItem) {
        return (
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "60vh",
                    flex: "1",
                }}
            >
                <CircularProgress />
            </Box>
        )
    }

    return (
        <>
            <Grid sx={{ padding: "1rem" }}>
                <Grid container>
                    <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        open={validationWarning}
                        autoHideDuration={3000}
                        onClose={handleValidationWarningClose}
                    >
                        <Alert
                            onClose={handleValidationWarningClose}
                            severity="warning"
                            sx={{
                                position: "fixed",
                                top: "16px",
                                right: "16px",
                            }}
                            action={
                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={handleValidationWarningClose}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            }
                        >
                            {t("PleaseSelect")}
                        </Alert>
                    </Snackbar>
                    <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        open={outOfStockWarning}
                        autoHideDuration={3000}
                        onClose={handleOutOfStockWarningClose}
                    >
                        <Alert
                            onClose={handleOutOfStockWarningClose}
                            severity="warning"
                            sx={{
                                position: "fixed",
                                top: "16px",
                                right: "16px",
                            }}
                            action={
                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={handleOutOfStockWarningClose}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            }
                        >
                            {t("OutOfStockWarning", { name })}
                        </Alert>
                    </Snackbar>

                    <Box display="flex" flexDirection="column">
                        <Typography
                            style={{
                                fontSize: 30,
                                color: theme.palette.primary.main,
                                fontWeight: 700,
                                fontFamily: `bold${i18n.language}`,
                            }}
                        >
                            {capitalization(
                                new Map(JSON.parse(selectedItem.multiLanguages))?.get(`${i18n.language}-${"name"}`) ??
                                    selectedItem.name
                            )}
                        </Typography>
                        <Typography
                            sx={{
                                overflow: "hidden",
                                marginBottom: "0.5rem",
                                color: "#9B9B9B",
                                fontFamily: `bold${i18n.language}`,
                            }}
                        >
                            {capitalization(
                                new Map(JSON.parse(selectedItem.multiLanguages))?.get(
                                    `${i18n.language}-${"description"}`
                                ) ?? selectedItem.description
                            )}
                        </Typography>
                    </Box>
                    <Grid
                        container
                        sx={{
                            display: "flex",
                            flex: "wrap",
                            justifyContent: "space-between",
                        }}
                    >
                        <Grid item xs={6}>
                            {subTaxTotal !== 0 ? (
                                <Typography
                                    style={{
                                        fontSize: 30,
                                        fontWeight: 700,
                                        fontFamily: `bold${i18n.language}`,
                                        color: "#F07EAA",
                                    }}
                                >
                                    {numberTranslation(subTaxTotal, i18n.language)}
                                    {t("currency.EGP")}
                                </Typography>
                            ) : (
                                <Typography
                                    style={{
                                        fontSize: 20,
                                        fontWeight: 700,
                                        fontFamily: `bold${i18n.language}`,
                                        color: "#F07EAA",
                                    }}
                                >
                                    {t("Price on selection")}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item sx={{ display: "flex", justifyContent: "flex-end" }} sm={6}>
                            <Box display="flex" justifyContent="space-between">
                                <Button
                                    variant="contained"
                                    sx={{
                                        font: "0.3rem",
                                        width: "95%",
                                        minWidth: "41px",
                                        height: "2rem",
                                        color: "#FFFFFF",
                                        backgroundColor: "#F07EAA",
                                        boxShadow: "none",
                                        transition: "box-shadow 0.3s",
                                        borderRadius: "1rem",
                                        padding: "0rem",
                                        "&:hover": {
                                            boxShadow: "0px 0px 5px 2px rgba(240, 126, 170, 0.7)",
                                            backgroundColor: "#F07EAA",
                                        },
                                    }}
                                    disabled={addToCartDisabled}
                                    onClick={handleDecrement}
                                >
                                    -
                                </Button>
                                <Button
                                    sx={{
                                        width: "1rem",
                                        height: "2rem",
                                        padding: "0rem",
                                        color: theme.palette.primary.main,
                                    }}
                                >
                                    {numberTranslation(quantity, i18n.language)}
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{
                                        font: "0.3rem",
                                        width: "95%",
                                        minWidth: "41px",
                                        height: "2rem",
                                        color: "#FFFFFF",
                                        backgroundColor: "#F07EAA",
                                        boxShadow: "none",
                                        transition: "box-shadow 0.3s",
                                        borderRadius: "1rem",
                                        padding: "0rem",
                                        "&:hover": {
                                            boxShadow: "0px 0px 5px 2px rgba(240, 126, 170, 0.7)",
                                            backgroundColor: "#F07EAA",
                                        },
                                    }}
                                    disabled={addToCartDisabled}
                                    onClick={handleIncrement}
                                >
                                    +
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    {selectedItem.prices.length > 1 && (
                        <Grid container justifyContent="space-between">
                            <Grid item xs={2.2}>
                                <Typography
                                    sx={{
                                        fontSize: 24,
                                        color: theme.palette.primary.main,
                                        fontWeight: 700,
                                        fontFamily: `bold${i18n.language}`,
                                    }}
                                >
                                    {t("size")}
                                </Typography>
                            </Grid>

                            {selectedItem?.prices[0]?.name !== "" && (
                                <Grid
                                    item
                                    container
                                    xs={9.8}
                                    sx={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        justifyContent: "end",
                                        alignItems: "center",
                                    }}
                                >
                                    {selectedItem.prices.map((price: Price) => (
                                        <Box
                                            key={price.id}
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Button
                                                variant={selectedSize.priceId === price.id ? "contained" : "outlined"}
                                                sx={{
                                                    margin: "0.2rem",
                                                    fontFamily: `bold${i18n.language}`,
                                                    color: selectedSize.priceId === price.id ? "white" : "#33BDE8",
                                                    borderColor:
                                                        selectedSize.priceId === price.id ? "#33BDE8" : "inherit",
                                                    backgroundColor:
                                                        selectedSize.priceId === price.id ? "#33BDE8" : "inherit",

                                                    "&:hover": {
                                                        backgroundColor:
                                                            selectedSize.priceId === price.id
                                                                ? "#33BDE8"
                                                                : "rgba(51,189,232,0.1)",
                                                        borderColor: "#33BDE8",
                                                    },
                                                    padding: "0.1rem 0.5rem",
                                                    fontSize: "1rem",
                                                }}
                                                onClick={() => {
                                                    handleSizeSelection(
                                                        price.name,
                                                        price.price,
                                                        price.id,
                                                        price.multiLanguages,
                                                        price.symphonyID
                                                    )
                                                }}
                                            >
                                                {capitalization(
                                                    new Map<string, string>(JSON.parse(price.multiLanguages)).get(
                                                        `${i18n.language}-name`
                                                    ) ?? price.name
                                                )}
                                            </Button>
                                        </Box>
                                    ))}
                                </Grid>
                            )}
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Divider
                sx={{
                    borderColor: "#393744",
                    borderWidth: "0.2rem",
                    marginY: "0.5rem",
                }}
            />
            {/* ///extrasss */}

            <Grid sx={{ padding: "1rem" }}>
                <Grid container justifyContent="space-between">
                    {selectedItem.choiceGroups &&
                        selectedItem.choiceGroups.map((choiceGroup: any) =>
                            choiceGroup.choiceItems.items.length > 0 ? (
                                <Grid key={choiceGroup.id} container item sm={12}>
                                    <Typography
                                        key={choiceGroup.id}
                                        style={{
                                            fontSize: 24,
                                            fontWeight: 700,
                                            color: theme.palette.primary.main,
                                        }}
                                    >
                                        {capitalization(
                                            new Map(JSON.parse(choiceGroup.multiLanguages))?.get(
                                                `${i18n.language}-${"name"}`
                                            ) ?? choiceGroup.name
                                        )}
                                    </Typography>
                                    <Grid container justifyContent="space-between">
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            display={"flex"}
                                            flexDirection={"row"}
                                            justifyContent="space-between"
                                        >
                                            <Typography
                                                sx={{
                                                    overflow: "hidden",
                                                    fontSize: 12,
                                                    marginBottom: "0.5rem",
                                                    color: "#9B9B9B",
                                                }}
                                            >
                                                {choiceGroup.name !== "remove" &&
                                                    `${t("Min")} ${
                                                        selectedItem?.choiceGroupsRules?.find(
                                                            (cg: ChoiceGroupRules) => cg?.id === choiceGroup.id
                                                        )?.minNumberOfChoices ?? choiceGroup.minNumberOfChoices
                                                    }`}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    overflow: "hidden",
                                                    fontSize: 12,
                                                    marginBottom: "0.5rem",
                                                    color: "#9B9B9B",
                                                }}
                                            >
                                                {choiceGroup.name !== "remove" &&
                                                    `${t("Max")} ${
                                                        selectedItem?.choiceGroupsRules?.find(
                                                            (cg: ChoiceGroupRules) => cg?.id === choiceGroup.id
                                                        )?.maxNumberOfChoices ?? choiceGroup.maxNumberOfChoices
                                                    }`}
                                            </Typography>
                                        </Grid>
                                        {/* <Grid container justifyContent="space-between">
                    <Grid item sm={6}>
                      {choiceGroup.maxNumberOfChoices > 0 &&
                        choiceGroup.choiceItems.items.length > 0 && (
                          <Typography
                            sx={{
                              overflow: "hidden",
                              fontSize: 12,
                              marginBottom: "0.5rem",
                              color: "#9B9B9B",
                            }}
                          >
                            {choiceGroup.name !== "remove" &&
                              `${t("Choose")} ${
                                choiceGroup.maxNumberOfChoices
                              }`}
                          </Typography>
                        )}
                    </Grid> */}

                                        {/*  <Grid item sm={2}>
                      <Button
                        key={choiceGroup.id}
                        variant="contained"
                        sx={{
                          fontSize: "0.6rem",
                          borderRadius: 50,
                          color: "white",
                          backgroundColor: "#393744",
                          pointerEvents: "none", // Disable pointer events to prevent interaction
                        }}
                      >
                        {`${
                          choiceGroup.required ? t("Required") : t("Optional")
                        }`}
                      </Button>
                    </Grid> */}
                                    </Grid>
                                    {choiceGroup.choiceItems &&
                                        choiceGroup.choiceItems.items.map((choice: any) => (
                                            <Grid container key={choice.id} justifyContent="space-between">
                                                <Grid item sm={6} xs={6}>
                                                    <Typography
                                                        sx={{
                                                            font: "1rem",
                                                            fontWeight: 500,
                                                            marginTop: "0.5rem",
                                                            fontFamily: `regular${i18n.language}`,
                                                            color: theme.palette.primary.main,
                                                        }}
                                                    >
                                                        {capitalization(
                                                            new Map(JSON.parse(choice.multiLanguages))?.get(
                                                                `${i18n.language}-${"name"}`
                                                            ) ?? choice.name
                                                        )}
                                                    </Typography>
                                                </Grid>
                                                <Grid container justifyContent={"end"} item sm={2.5} xs={2.5}>
                                                    {choice.price !== 0 && (
                                                        <Typography
                                                            sx={{
                                                                color: "#9B9B9B",
                                                                marginTop: "0.5rem",
                                                                fontFamily: `regular${i18n.language}`,
                                                            }}
                                                        >
                                                            + {numberTranslation(choice.price, i18n.language)}{" "}
                                                            {t("currency.EGP")}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                                {/* <Grid item sm={1.5} xs={1.5}> */}
                                                {/* <Checkbox
                            disabled={
                              choiceGroupsMax[choiceGroup.id] === 0 &&
                              !checkedItems[choice.id]
                            }
                            sx={{
                              color: theme.palette.primary.main, // Border color in default state
                              "&.Mui-checked": {
                                color: "#33BDE8", // Border color when checked
                              },
                              "&:hover": {
                                color: "#33BDE8", // Border color on hover (optional)
                              },
                            }}
                            onChange={() =>
                              handleCheckboxChange(
                                choice.id,
                                choice.price,
                                choiceGroup.id
                              )
                            }
                            checked={checkedItems[choice.id] || false}
                          /> */}

                                                {/* Quantity Section */}
                                                <Grid item xs={2.5} container alignItems="center" spacing={0}>
                                                    <Grid
                                                        item
                                                        xs={3}
                                                        container
                                                        alignItems="center"
                                                        justifyContent="center"
                                                    >
                                                        <IconButton
                                                            disabled={!checkedItems[choice.id]}
                                                            onClick={() =>
                                                                handleCheckboxChange(
                                                                    choice.id,
                                                                    choice.price,
                                                                    choiceGroup.id,
                                                                    "remove"
                                                                )
                                                            }
                                                            sx={{
                                                                color: loading ? "grey" : "#C19A5D",
                                                                fontSize: 15,
                                                            }}
                                                        >
                                                            <Remove />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={4}
                                                        container
                                                        alignItems="center"
                                                        justifyContent="center"
                                                    >
                                                        <Typography
                                                            align="center"
                                                            textAlign="center"
                                                            style={{
                                                                fontSize: 15,
                                                                fontWeight: 400,
                                                                fontFamily: "Roboto",
                                                            }}
                                                        >
                                                            {checkedItems[choice.id] ?? 0}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={4}
                                                        container
                                                        alignItems="center"
                                                        justifyContent="center"
                                                    >
                                                        <IconButton
                                                            disabled={
                                                                !isSelectionValidatedForDisabled(
                                                                    checkedItems,
                                                                    selectedItem.choiceGroups,
                                                                    choiceGroup?.id
                                                                )
                                                            }
                                                            onClick={() =>
                                                                handleCheckboxChange(
                                                                    choice.id,
                                                                    choice.price,
                                                                    choiceGroup.id,
                                                                    "add"
                                                                )
                                                            }
                                                            sx={{
                                                                color: loading ? "grey" : "#C19A5D",
                                                                fontSize: 15,
                                                            }}
                                                        >
                                                            <Add />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            // </Grid>
                                        ))}
                                </Grid>
                            ) : (
                                ""
                            )
                        )}

                    <Grid container sx={{ marginTop: "1rem", marginBottom: "3rem" }}>
                        <Grid item sm={12} xs={12} sx={{ marginBottom: "1rem" }}>
                            <TextField
                                id="outlined-basic"
                                placeholder={t("specialRequest")}
                                multiline
                                rows={4}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: theme.palette.primary.main,
                                        },
                                        "&:hover fieldset": {
                                            borderColor: theme.palette.primary.main,
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: theme.palette.primary.main,
                                        },
                                        "& input": {
                                            color: theme.palette.primary.main,
                                        },
                                        "& input::placeholder": {
                                            color: theme.palette.primary.main,
                                            display: "flex",
                                            alignItems: "center",
                                        },
                                        display: "flex",
                                        alignItems: "flex-start",
                                        color: theme.palette.primary.main,
                                    },
                                    borderRadius: "20px",
                                    fontFamily: `regular${i18n.language}`,
                                    width: "100%",
                                    height: "3rem",
                                    margin: "1rem auto",
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" sx={{ color: theme.palette.primary.main }}>
                                            <EditNoteIcon
                                                sx={{
                                                    color: theme.palette.primary.main,
                                                    marginTop: "1.25rem",
                                                    marginX: "0.3rem",
                                                }}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                                value={notes}
                                onChange={handleNotesChange}
                            />
                        </Grid>
                    </Grid>

                    <Box sx={{ marginTop: "1rem", marginBottom: "3rem", width: "100%" }}>
                        <LoadingButton
                            sx={{
                                font: "0.3rem",
                                width: "100%",
                                color: "#FFFFFF",
                                backgroundColor: "#F07EAA",
                                boxShadow: "none",
                                transition: "box-shadow 0.3s",
                                borderRadius: "1rem",
                                padding: "1rem",
                                "&:hover": {
                                    boxShadow: "0px 0px 5px 2px rgba(240, 126, 170, 0.7)",
                                    backgroundColor: "#F07EAA",
                                },
                            }}
                            onClick={handleAddToCart}
                            // disabled={addToCartDisabled || disbled}
                            loading={addToCartDisabled || disbled}
                        >
                            <Box display="flex" justifyContent="space-between" width="100%">
                                <Box> {t(EditedcartItem ? "Edit" : "AddtoCart")} </Box>
                                <Box>
                                    {numberTranslation(subTaxTotal, i18n.language)}
                                    {t("currency.EGP")}
                                </Box>
                            </Box>
                        </LoadingButton>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default MenuItems
