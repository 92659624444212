import { PayloadAction, createSlice } from "@reduxjs/toolkit"
interface AppState {
    mode: string
    app: any
    user: any
    isFetchingUser: boolean
    splashScreen: boolean
    selectedAddress: any
    zonesListing: any[]
    selectedConcept: any
    forceSelectConcept: boolean
    fetchingCategoriesID: string
    //categories
    categoriesState: {
        listing: any[]
    }
    mainCategoriesState: {
        listing: any[]
    }
    subCategoriesState: {
        listing: any[]
    }
    menuItems: any[]
    cart: {
        orderedItems: any
        quantity: number
        total: number
        subTotal: number
        deliveryFee: number
        subTaxTotal: number
        discount: number
        specialRequest: string
        notes: any
        ready?: boolean
    }
    channel: string
    promocode: any
    discountedValue: number | null | undefined
    promocodeName: string | undefined
    table: {
        listing: any
        tableId: any
        encryptedID: any
    }
    concept: {
        listing: any
    }
    orders: any
    ordersNextToken: string | null | undefined
    currentOrder: any
    selectedItem: any
    rewardItems: any[]
    transactions: any
    homeScroll: any
}

// Retrieve mode from local storage or default to "dark"
const initialMode = localStorage.getItem("mode") || "dark"
const initialState: AppState = {
    mode: initialMode,
    app: null,
    user: null,
    isFetchingUser: false,
    splashScreen: true,
    selectedAddress: null,
    zonesListing: [],
    selectedConcept: null,
    forceSelectConcept: false,
    fetchingCategoriesID: "",
    //categories
    categoriesState: {
        listing: [],
    },
    mainCategoriesState: {
        listing: [],
    },
    subCategoriesState: {
        listing: [],
    },
    menuItems: [],
    cart: {
        orderedItems: null,
        quantity: 0,
        total: 0,
        subTaxTotal: 0,
        subTotal: 0,
        deliveryFee: 0,
        discount: 0,
        specialRequest: "",
        notes: [],
        ready: false,
    },
    channel: "delivery",
    promocode: null,
    discountedValue: null,
    promocodeName: undefined,
    table: {
        listing: null,
        tableId: null,
        encryptedID: null,
    },
    concept: {
        listing: null,
    },
    orders: null,
    ordersNextToken: null,
    currentOrder: null,
    selectedItem: null,
    rewardItems: [],
    transactions: {
        page: 2,
        items: [],
    },
    homeScroll: {
        x: 0,
        y: 0,
    },
}

export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setMode: (state: AppState) => {
            const newMode = state.mode === "dark" ? "light" : "dark"
            state.mode = newMode
            localStorage.setItem("mode", newMode)
        },
        //categories
        setCategories: (state: AppState, action: PayloadAction<any>) => {
            state.categoriesState.listing = action.payload
        },
        setMainCategories: (state: AppState, action: PayloadAction<any>) => {
            state.mainCategoriesState.listing = action.payload
        },
        setSubCategories: (state: AppState, action: PayloadAction<any>) => {
            state.subCategoriesState.listing = action.payload
        },

        setMenuItems: (state: AppState, action: PayloadAction<any[]>) => {
            state.categoriesState.listing = action.payload
        },
        setLogin: (state: AppState, action: PayloadAction<any>) => {
            const { user } = action.payload
            state.user = user
        },
        setIsFetchingUser: (state: AppState, action: PayloadAction<boolean>) => {
            state.isFetchingUser = action.payload
        },

        //cart
        setCart: (state: AppState, action: PayloadAction<any>) => {
            state.cart.orderedItems = action.payload
        },
        setCartQuantity: (state: AppState, action: PayloadAction<number>) => {
            state.cart.quantity = action.payload
        },
        setCartReady: (state: AppState, action: PayloadAction<boolean>) => {
            state.cart.ready = action.payload
        },
        setCartTotal: (state: AppState, action: PayloadAction<number>) => {
            state.cart.total = action.payload
        },
        setCartSubTotal: (state: AppState, action: PayloadAction<number>) => {
            state.cart.subTotal = action.payload
        },
        setCartSubTaxTotal: (state: AppState, action: PayloadAction<number>) => {
            state.cart.subTaxTotal = action.payload
        },
        setLoyaltyDiscount: (state: AppState, action: PayloadAction<number>) => {
            state.cart.discount = action.payload
        },
        setCartDeliveryFee: (state: AppState, action: PayloadAction<number>) => {
            state.cart.deliveryFee = action.payload
        },
        setCartSpecialRequest: (state: AppState, action: PayloadAction<string>) => {
            state.cart.specialRequest = action.payload
        },
        setCartNotes: (state: AppState, action: PayloadAction<any>) => {
            state.cart.notes = action.payload
        },
        emptyCart: (state: AppState) => {
            state.cart = {
                orderedItems: [],
                quantity: 0,
                total: 0,
                subTaxTotal: 0,
                subTotal: 0,
                discount: 0,
                deliveryFee: 0,
                specialRequest: "",
                notes: [],
                ready: false,
            }
        },
        // promocode
        setPromocode: (state: AppState, action: PayloadAction<any>) => {
            state.promocode = action.payload
        },
        setDiscountedValue: (state: AppState, action: PayloadAction<number | null | undefined>) => {
            state.discountedValue = action.payload
        },
        setPromocodeName: (state: AppState, action: PayloadAction<string | undefined>) => {
            state.promocodeName = action.payload
        },
        //table
        setTable: (state: AppState, action: PayloadAction<any>) => {
            state.table.listing = action.payload
        },
        setTableId: (state: AppState, action: PayloadAction<any>) => {
            state.table.tableId = action.payload
        },
        setEncryptedID: (state: AppState, action: PayloadAction<any>) => {
            state.table.encryptedID = action.payload
        },
        //cart
        setConcept: (state: AppState, action: PayloadAction<any>) => {
            state.concept.listing = action.payload
        },

        //order
        setOrder: (state: AppState, action: PayloadAction<any>) => {
            state.orders = action.payload
        },
        setOrdersNextToken: (state: AppState, action: PayloadAction<any>) => {
            state.ordersNextToken = action.payload
        },

        // current order
        setCurrentOrder: (state: AppState, action: PayloadAction) => {
            state.currentOrder = action.payload
        },

        //selectedItem
        setSelectedItem: (state: AppState, action: PayloadAction<any>) => {
            state.selectedItem = action.payload
        },
        setSplashScreen: (state: AppState, action: PayloadAction<any>) => {
            state.splashScreen = action.payload
        },
        setSelectedAddress: (state: AppState, action: PayloadAction<any>) => {
            state.selectedAddress = action.payload
        },
        setZonesListing: (state: AppState, action: PayloadAction<any>) => {
            state.zonesListing = action.payload
        },
        setSelectedConcept: (state: AppState, action: PayloadAction<any>) => {
            state.selectedConcept = action.payload
        },
        setForceSelectConcept: (state: AppState, action: PayloadAction<any>) => {
            state.forceSelectConcept = action.payload
        },
        setFetchingCategoriesID: (state: AppState, action: PayloadAction<any>) => {
            state.fetchingCategoriesID = action.payload
        },
        // loyalty
        setRewardItems: (state: AppState, action: PayloadAction<any>) => {
            state.rewardItems = action.payload
        },
        setTransactions: (state: AppState, action: PayloadAction<any>) => {
            state.transactions = action.payload
        },
        setHomeScroll: (state: AppState, action: PayloadAction<any>) => {
            state.homeScroll = action.payload
        },
        // channel
        setChannel: (state: AppState, action: PayloadAction<string>) => {
            state.channel = action.payload
        },
    },
})

export const {
    setMode,
    setLogin,
    setIsFetchingUser,
    setCategories,
    setMainCategories,
    setSubCategories,
    setMenuItems,
    //cart
    setCart,
    setCartQuantity,
    setCartTotal,
    setCartDeliveryFee,
    setCartSubTotal,
    setLoyaltyDiscount,
    setCartSubTaxTotal,
    setCartSpecialRequest,
    setCartNotes,
    emptyCart,
    // promocode
    setPromocode,
    setDiscountedValue,
    setPromocodeName,
    //table
    setTable,
    setTableId,
    setEncryptedID,
    //concept
    setConcept,
    //order
    setOrder,
    setOrdersNextToken,
    setCurrentOrder,
    //selectedItem,
    setSelectedItem,
    setSplashScreen,
    setSelectedAddress,
    setZonesListing,
    setSelectedConcept,
    setForceSelectConcept,
    setFetchingCategoriesID,
    // loyalty
    setRewardItems,
    setTransactions,
    // category page scroll
    setHomeScroll,
    // channel
    setChannel,
    setCartReady,
} = appSlice.actions
export default appSlice.reducer
