// Pablo & Abdo
export const EndPoints = {
    getCategoryData: "https://n75zwduzytlwnkkx6xhczict6i0rhrtx.lambda-url.us-east-2.on.aws",
    getPaymentURL: "https://cendfombfr3xk4y3m43oyxxtvi0fpvvi.lambda-url.us-east-2.on.aws",
}

// Debelop
// export const EndPoints = {
//   getCategoryData:
//     "https://hmled4sy73n434cffbs2itawz40wddgg.lambda-url.us-east-2.on.aws/",
//   getPaymentURL:
//     "https://y2gnhnmogfbaqqr5fwlmvorzui0fescg.lambda-url.us-east-2.on.aws/",
// };

export const simphonyURL = "https://enyoqfusqvpwt6zuiaw7anhw4m0ytewh.lambda-url.us-east-2.on.aws"

export const cloudinaryURL = "https://callcentersolutionassests100317-testing.s3.us-east-2.amazonaws.com/public/"

export const Pages = {
    MENU_LIST: "menuList",
    ORDERS: "myorders",
    ORDER_DETAILS: "orderdetails",
    TRACK_ORDER: "trackOrder",

    CART: "cart",
    LANGUAGES: "languages",
    REVIEW: "review",
    LANG: "lang",
}

export const HelipolisConcept = {
    id: "65acbf07-1a9a-4dde-9605-a20f19753404",
}

export const Keys = {
    CONCEPT: "Concept",
    MAIN_CATEGORIES: "MainCategories",
    SUB_CATEGORIES: "SubCategories",
    CATEGORIES: "Categories",
    CART: "Cart",
}

export const OrderStatus = {
    pending: "pending",
    sent: "sent to restaurant",
    failed: "failed to sent to restaurant",
    confirmed: "confirmed",
    review: "review",
    cancelled: "cancelled",
    pendingCancellation: "pending Cancellation",
    updated: "updated",
    acceptedByWaiter: "accepted by waiter",
    sentToKitchen: "sent To Kitchen",
    acceptedByKitchen: "accepted by kitchen",
    ready: "ready",
    readyForPickup: "ready for pickup",
    outForDelivery: "out for delivery",
    delivered: "delivered",
    deliveredAndSettled: "delivered and settled",
    settled: "settled",
}

export const OrderReviewRate = {
    veryDisSatisfied: "very dissatisfied",
    disSatisfied: "dissatisfied",
    neutral: "neutral",
    satisfied: "satisfied",
    verySatisfied: "very satisfied",
}
export const OrderReviewRateAR = {
    veryDisSatisfied: "غير راض جدا",
    disSatisfied: "غير راض",
    neutral: "محايد",
    satisfied: "راض",
    verySatisfied: "راض جدا",
}

export const WaiterCallingStatus = {
    pending: "pending",
    arrived: "arrived",
}

export const currency = {
    EGP: "EGP",
    AED: "AED",
}

export const account = {
    accountID: "5b57fcdf-f9f8-4351-84c0-14f437e3aasb",
}
export const SimphonyOrderOperation = {
    CREATE: 1,
    UPDATE: 2,
    CANCEL: 3,
}

export const OrderType = "Dine In"
export const OrderTime = "now"
export const MySecretKey = "MySecretKey"

export const TAX = 1.14

export enum LambdaFunctions {
    callCenterOperations = "callCenterOperations",
    paymentOperations = "paymentOperations",
}

export enum PromocodeAppliesTo {
    WHOLE_CART = "Whole cart",
    SHIPPING_FEES = "Cart Items",
}
