/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateEmailVerification = /* GraphQL */ `subscription OnCreateEmailVerification(
  $filter: ModelSubscriptionEmailVerificationFilterInput
) {
  onCreateEmailVerification(filter: $filter) {
    id
    accountID
    name
    email
    verification_code
    createdAt
    deleted
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateEmailVerificationSubscriptionVariables,
  APITypes.OnCreateEmailVerificationSubscription
>;
export const onUpdateEmailVerification = /* GraphQL */ `subscription OnUpdateEmailVerification(
  $filter: ModelSubscriptionEmailVerificationFilterInput
) {
  onUpdateEmailVerification(filter: $filter) {
    id
    accountID
    name
    email
    verification_code
    createdAt
    deleted
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateEmailVerificationSubscriptionVariables,
  APITypes.OnUpdateEmailVerificationSubscription
>;
export const onDeleteEmailVerification = /* GraphQL */ `subscription OnDeleteEmailVerification(
  $filter: ModelSubscriptionEmailVerificationFilterInput
) {
  onDeleteEmailVerification(filter: $filter) {
    id
    accountID
    name
    email
    verification_code
    createdAt
    deleted
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteEmailVerificationSubscriptionVariables,
  APITypes.OnDeleteEmailVerificationSubscription
>;
export const onCreateSocialLink = /* GraphQL */ `subscription OnCreateSocialLink(
  $filter: ModelSubscriptionSocialLinkFilterInput
) {
  onCreateSocialLink(filter: $filter) {
    id
    name
    icon
    slug
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateSocialLinkSubscriptionVariables,
  APITypes.OnCreateSocialLinkSubscription
>;
export const onUpdateSocialLink = /* GraphQL */ `subscription OnUpdateSocialLink(
  $filter: ModelSubscriptionSocialLinkFilterInput
) {
  onUpdateSocialLink(filter: $filter) {
    id
    name
    icon
    slug
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSocialLinkSubscriptionVariables,
  APITypes.OnUpdateSocialLinkSubscription
>;
export const onDeleteSocialLink = /* GraphQL */ `subscription OnDeleteSocialLink(
  $filter: ModelSubscriptionSocialLinkFilterInput
) {
  onDeleteSocialLink(filter: $filter) {
    id
    name
    icon
    slug
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteSocialLinkSubscriptionVariables,
  APITypes.OnDeleteSocialLinkSubscription
>;
export const onCreateLanguage = /* GraphQL */ `subscription OnCreateLanguage($filter: ModelSubscriptionLanguageFilterInput) {
  onCreateLanguage(filter: $filter) {
    id
    name
    code
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateLanguageSubscriptionVariables,
  APITypes.OnCreateLanguageSubscription
>;
export const onUpdateLanguage = /* GraphQL */ `subscription OnUpdateLanguage($filter: ModelSubscriptionLanguageFilterInput) {
  onUpdateLanguage(filter: $filter) {
    id
    name
    code
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateLanguageSubscriptionVariables,
  APITypes.OnUpdateLanguageSubscription
>;
export const onDeleteLanguage = /* GraphQL */ `subscription OnDeleteLanguage($filter: ModelSubscriptionLanguageFilterInput) {
  onDeleteLanguage(filter: $filter) {
    id
    name
    code
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteLanguageSubscriptionVariables,
  APITypes.OnDeleteLanguageSubscription
>;
export const onCreateAttachment = /* GraphQL */ `subscription OnCreateAttachment(
  $filter: ModelSubscriptionAttachmentFilterInput
) {
  onCreateAttachment(filter: $filter) {
    id
    mediaID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAttachmentSubscriptionVariables,
  APITypes.OnCreateAttachmentSubscription
>;
export const onUpdateAttachment = /* GraphQL */ `subscription OnUpdateAttachment(
  $filter: ModelSubscriptionAttachmentFilterInput
) {
  onUpdateAttachment(filter: $filter) {
    id
    mediaID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAttachmentSubscriptionVariables,
  APITypes.OnUpdateAttachmentSubscription
>;
export const onDeleteAttachment = /* GraphQL */ `subscription OnDeleteAttachment(
  $filter: ModelSubscriptionAttachmentFilterInput
) {
  onDeleteAttachment(filter: $filter) {
    id
    mediaID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAttachmentSubscriptionVariables,
  APITypes.OnDeleteAttachmentSubscription
>;
export const onCreateGroup = /* GraphQL */ `subscription OnCreateGroup($filter: ModelSubscriptionGroupFilterInput) {
  onCreateGroup(filter: $filter) {
    id
    accountID
    name
    description
    color
    requireApproval
    status
    agents
    groupSettings
    numToUpgrade
    numToDowngrade
    upgradeGroup
    downgradeGroup
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateGroupSubscriptionVariables,
  APITypes.OnCreateGroupSubscription
>;
export const onUpdateGroup = /* GraphQL */ `subscription OnUpdateGroup($filter: ModelSubscriptionGroupFilterInput) {
  onUpdateGroup(filter: $filter) {
    id
    accountID
    name
    description
    color
    requireApproval
    status
    agents
    groupSettings
    numToUpgrade
    numToDowngrade
    upgradeGroup
    downgradeGroup
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateGroupSubscriptionVariables,
  APITypes.OnUpdateGroupSubscription
>;
export const onDeleteGroup = /* GraphQL */ `subscription OnDeleteGroup($filter: ModelSubscriptionGroupFilterInput) {
  onDeleteGroup(filter: $filter) {
    id
    accountID
    name
    description
    color
    requireApproval
    status
    agents
    groupSettings
    numToUpgrade
    numToDowngrade
    upgradeGroup
    downgradeGroup
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteGroupSubscriptionVariables,
  APITypes.OnDeleteGroupSubscription
>;
export const onCreateGroupSetting = /* GraphQL */ `subscription OnCreateGroupSetting(
  $filter: ModelSubscriptionGroupSettingFilterInput
) {
  onCreateGroupSetting(filter: $filter) {
    id
    groupID
    statusID
    needsVerification
    needsDeposit
    isDefault
    amount
    days
    timeSlots
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateGroupSettingSubscriptionVariables,
  APITypes.OnCreateGroupSettingSubscription
>;
export const onUpdateGroupSetting = /* GraphQL */ `subscription OnUpdateGroupSetting(
  $filter: ModelSubscriptionGroupSettingFilterInput
) {
  onUpdateGroupSetting(filter: $filter) {
    id
    groupID
    statusID
    needsVerification
    needsDeposit
    isDefault
    amount
    days
    timeSlots
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateGroupSettingSubscriptionVariables,
  APITypes.OnUpdateGroupSettingSubscription
>;
export const onDeleteGroupSetting = /* GraphQL */ `subscription OnDeleteGroupSetting(
  $filter: ModelSubscriptionGroupSettingFilterInput
) {
  onDeleteGroupSetting(filter: $filter) {
    id
    groupID
    statusID
    needsVerification
    needsDeposit
    isDefault
    amount
    days
    timeSlots
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteGroupSettingSubscriptionVariables,
  APITypes.OnDeleteGroupSettingSubscription
>;
export const onCreateCustomerStats = /* GraphQL */ `subscription OnCreateCustomerStats(
  $filter: ModelSubscriptionCustomerStatsFilterInput
) {
  onCreateCustomerStats(filter: $filter) {
    id
    conceptID
    totalReservation
    confirmed
    notConfirmed
    canceled
    checkIn
    noShow
    userID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCustomerStatsSubscriptionVariables,
  APITypes.OnCreateCustomerStatsSubscription
>;
export const onUpdateCustomerStats = /* GraphQL */ `subscription OnUpdateCustomerStats(
  $filter: ModelSubscriptionCustomerStatsFilterInput
) {
  onUpdateCustomerStats(filter: $filter) {
    id
    conceptID
    totalReservation
    confirmed
    notConfirmed
    canceled
    checkIn
    noShow
    userID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCustomerStatsSubscriptionVariables,
  APITypes.OnUpdateCustomerStatsSubscription
>;
export const onDeleteCustomerStats = /* GraphQL */ `subscription OnDeleteCustomerStats(
  $filter: ModelSubscriptionCustomerStatsFilterInput
) {
  onDeleteCustomerStats(filter: $filter) {
    id
    conceptID
    totalReservation
    confirmed
    notConfirmed
    canceled
    checkIn
    noShow
    userID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCustomerStatsSubscriptionVariables,
  APITypes.OnDeleteCustomerStatsSubscription
>;
export const onCreateFlag = /* GraphQL */ `subscription OnCreateFlag($filter: ModelSubscriptionFlagFilterInput) {
  onCreateFlag(filter: $filter) {
    id
    accountID
    name
    icon
    color
    customers
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateFlagSubscriptionVariables,
  APITypes.OnCreateFlagSubscription
>;
export const onUpdateFlag = /* GraphQL */ `subscription OnUpdateFlag($filter: ModelSubscriptionFlagFilterInput) {
  onUpdateFlag(filter: $filter) {
    id
    accountID
    name
    icon
    color
    customers
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFlagSubscriptionVariables,
  APITypes.OnUpdateFlagSubscription
>;
export const onDeleteFlag = /* GraphQL */ `subscription OnDeleteFlag($filter: ModelSubscriptionFlagFilterInput) {
  onDeleteFlag(filter: $filter) {
    id
    accountID
    name
    icon
    color
    customers
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteFlagSubscriptionVariables,
  APITypes.OnDeleteFlagSubscription
>;
export const onCreateInterest = /* GraphQL */ `subscription OnCreateInterest($filter: ModelSubscriptionInterestFilterInput) {
  onCreateInterest(filter: $filter) {
    id
    accountID
    name
    icon
    color
    customers
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateInterestSubscriptionVariables,
  APITypes.OnCreateInterestSubscription
>;
export const onUpdateInterest = /* GraphQL */ `subscription OnUpdateInterest($filter: ModelSubscriptionInterestFilterInput) {
  onUpdateInterest(filter: $filter) {
    id
    accountID
    name
    icon
    color
    customers
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateInterestSubscriptionVariables,
  APITypes.OnUpdateInterestSubscription
>;
export const onDeleteInterest = /* GraphQL */ `subscription OnDeleteInterest($filter: ModelSubscriptionInterestFilterInput) {
  onDeleteInterest(filter: $filter) {
    id
    accountID
    name
    icon
    color
    customers
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteInterestSubscriptionVariables,
  APITypes.OnDeleteInterestSubscription
>;
export const onCreateReservationStatus = /* GraphQL */ `subscription OnCreateReservationStatus(
  $filter: ModelSubscriptionReservationStatusFilterInput
) {
  onCreateReservationStatus(filter: $filter) {
    id
    accountID
    name
    category
    image {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    reservationStatusImageId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateReservationStatusSubscriptionVariables,
  APITypes.OnCreateReservationStatusSubscription
>;
export const onUpdateReservationStatus = /* GraphQL */ `subscription OnUpdateReservationStatus(
  $filter: ModelSubscriptionReservationStatusFilterInput
) {
  onUpdateReservationStatus(filter: $filter) {
    id
    accountID
    name
    category
    image {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    reservationStatusImageId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateReservationStatusSubscriptionVariables,
  APITypes.OnUpdateReservationStatusSubscription
>;
export const onDeleteReservationStatus = /* GraphQL */ `subscription OnDeleteReservationStatus(
  $filter: ModelSubscriptionReservationStatusFilterInput
) {
  onDeleteReservationStatus(filter: $filter) {
    id
    accountID
    name
    category
    image {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    reservationStatusImageId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteReservationStatusSubscriptionVariables,
  APITypes.OnDeleteReservationStatusSubscription
>;
export const onCreateTimeSlot = /* GraphQL */ `subscription OnCreateTimeSlot($filter: ModelSubscriptionTimeSlotFilterInput) {
  onCreateTimeSlot(filter: $filter) {
    id
    conceptID
    name
    friendlyName
    bookings
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTimeSlotSubscriptionVariables,
  APITypes.OnCreateTimeSlotSubscription
>;
export const onUpdateTimeSlot = /* GraphQL */ `subscription OnUpdateTimeSlot($filter: ModelSubscriptionTimeSlotFilterInput) {
  onUpdateTimeSlot(filter: $filter) {
    id
    conceptID
    name
    friendlyName
    bookings
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTimeSlotSubscriptionVariables,
  APITypes.OnUpdateTimeSlotSubscription
>;
export const onDeleteTimeSlot = /* GraphQL */ `subscription OnDeleteTimeSlot($filter: ModelSubscriptionTimeSlotFilterInput) {
  onDeleteTimeSlot(filter: $filter) {
    id
    conceptID
    name
    friendlyName
    bookings
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTimeSlotSubscriptionVariables,
  APITypes.OnDeleteTimeSlotSubscription
>;
export const onCreateArea = /* GraphQL */ `subscription OnCreateArea($filter: ModelSubscriptionAreaFilterInput) {
  onCreateArea(filter: $filter) {
    id
    conceptID
    name
    isDefault
    expiryDate
    days
    timeSlots
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAreaSubscriptionVariables,
  APITypes.OnCreateAreaSubscription
>;
export const onUpdateArea = /* GraphQL */ `subscription OnUpdateArea($filter: ModelSubscriptionAreaFilterInput) {
  onUpdateArea(filter: $filter) {
    id
    conceptID
    name
    isDefault
    expiryDate
    days
    timeSlots
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAreaSubscriptionVariables,
  APITypes.OnUpdateAreaSubscription
>;
export const onDeleteArea = /* GraphQL */ `subscription OnDeleteArea($filter: ModelSubscriptionAreaFilterInput) {
  onDeleteArea(filter: $filter) {
    id
    conceptID
    name
    isDefault
    expiryDate
    days
    timeSlots
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAreaSubscriptionVariables,
  APITypes.OnDeleteAreaSubscription
>;
export const onCreateTable = /* GraphQL */ `subscription OnCreateTable($filter: ModelSubscriptionTableFilterInput) {
  onCreateTable(filter: $filter) {
    id
    conceptID
    label
    name
    width
    height
    capacity
    image
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTableSubscriptionVariables,
  APITypes.OnCreateTableSubscription
>;
export const onUpdateTable = /* GraphQL */ `subscription OnUpdateTable($filter: ModelSubscriptionTableFilterInput) {
  onUpdateTable(filter: $filter) {
    id
    conceptID
    label
    name
    width
    height
    capacity
    image
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTableSubscriptionVariables,
  APITypes.OnUpdateTableSubscription
>;
export const onDeleteTable = /* GraphQL */ `subscription OnDeleteTable($filter: ModelSubscriptionTableFilterInput) {
  onDeleteTable(filter: $filter) {
    id
    conceptID
    label
    name
    width
    height
    capacity
    image
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTableSubscriptionVariables,
  APITypes.OnDeleteTableSubscription
>;
export const onCreateFriend = /* GraphQL */ `subscription OnCreateFriend($filter: ModelSubscriptionFriendFilterInput) {
  onCreateFriend(filter: $filter) {
    id
    customerId
    friendId
    bookingID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateFriendSubscriptionVariables,
  APITypes.OnCreateFriendSubscription
>;
export const onUpdateFriend = /* GraphQL */ `subscription OnUpdateFriend($filter: ModelSubscriptionFriendFilterInput) {
  onUpdateFriend(filter: $filter) {
    id
    customerId
    friendId
    bookingID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFriendSubscriptionVariables,
  APITypes.OnUpdateFriendSubscription
>;
export const onDeleteFriend = /* GraphQL */ `subscription OnDeleteFriend($filter: ModelSubscriptionFriendFilterInput) {
  onDeleteFriend(filter: $filter) {
    id
    customerId
    friendId
    bookingID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteFriendSubscriptionVariables,
  APITypes.OnDeleteFriendSubscription
>;
export const onCreateComment = /* GraphQL */ `subscription OnCreateComment($filter: ModelSubscriptionCommentFilterInput) {
  onCreateComment(filter: $filter) {
    id
    message
    customerId
    bookingId
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCommentSubscriptionVariables,
  APITypes.OnCreateCommentSubscription
>;
export const onUpdateComment = /* GraphQL */ `subscription OnUpdateComment($filter: ModelSubscriptionCommentFilterInput) {
  onUpdateComment(filter: $filter) {
    id
    message
    customerId
    bookingId
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCommentSubscriptionVariables,
  APITypes.OnUpdateCommentSubscription
>;
export const onDeleteComment = /* GraphQL */ `subscription OnDeleteComment($filter: ModelSubscriptionCommentFilterInput) {
  onDeleteComment(filter: $filter) {
    id
    message
    customerId
    bookingId
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCommentSubscriptionVariables,
  APITypes.OnDeleteCommentSubscription
>;
export const onCreateServingArea = /* GraphQL */ `subscription OnCreateServingArea(
  $filter: ModelSubscriptionServingAreaFilterInput
) {
  onCreateServingArea(filter: $filter) {
    id
    name
    fees
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateServingAreaSubscriptionVariables,
  APITypes.OnCreateServingAreaSubscription
>;
export const onUpdateServingArea = /* GraphQL */ `subscription OnUpdateServingArea(
  $filter: ModelSubscriptionServingAreaFilterInput
) {
  onUpdateServingArea(filter: $filter) {
    id
    name
    fees
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateServingAreaSubscriptionVariables,
  APITypes.OnUpdateServingAreaSubscription
>;
export const onDeleteServingArea = /* GraphQL */ `subscription OnDeleteServingArea(
  $filter: ModelSubscriptionServingAreaFilterInput
) {
  onDeleteServingArea(filter: $filter) {
    id
    name
    fees
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteServingAreaSubscriptionVariables,
  APITypes.OnDeleteServingAreaSubscription
>;
export const onCreateNotification = /* GraphQL */ `subscription OnCreateNotification(
  $filter: ModelSubscriptionNotificationFilterInput
) {
  onCreateNotification(filter: $filter) {
    id
    accountID
    conceptID
    name
    description
    channel
    message
    from
    enabled
    autoSend
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateNotificationSubscriptionVariables,
  APITypes.OnCreateNotificationSubscription
>;
export const onUpdateNotification = /* GraphQL */ `subscription OnUpdateNotification(
  $filter: ModelSubscriptionNotificationFilterInput
) {
  onUpdateNotification(filter: $filter) {
    id
    accountID
    conceptID
    name
    description
    channel
    message
    from
    enabled
    autoSend
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateNotificationSubscriptionVariables,
  APITypes.OnUpdateNotificationSubscription
>;
export const onDeleteNotification = /* GraphQL */ `subscription OnDeleteNotification(
  $filter: ModelSubscriptionNotificationFilterInput
) {
  onDeleteNotification(filter: $filter) {
    id
    accountID
    conceptID
    name
    description
    channel
    message
    from
    enabled
    autoSend
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteNotificationSubscriptionVariables,
  APITypes.OnDeleteNotificationSubscription
>;
export const onCreateMobileNotification = /* GraphQL */ `subscription OnCreateMobileNotification(
  $filter: ModelSubscriptionMobileNotificationFilterInput
) {
  onCreateMobileNotification(filter: $filter) {
    id
    title
    description
    body
    default
    event
    image
    concepts
    condition
    startDate
    endDate
    group
    read
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMobileNotificationSubscriptionVariables,
  APITypes.OnCreateMobileNotificationSubscription
>;
export const onUpdateMobileNotification = /* GraphQL */ `subscription OnUpdateMobileNotification(
  $filter: ModelSubscriptionMobileNotificationFilterInput
) {
  onUpdateMobileNotification(filter: $filter) {
    id
    title
    description
    body
    default
    event
    image
    concepts
    condition
    startDate
    endDate
    group
    read
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMobileNotificationSubscriptionVariables,
  APITypes.OnUpdateMobileNotificationSubscription
>;
export const onDeleteMobileNotification = /* GraphQL */ `subscription OnDeleteMobileNotification(
  $filter: ModelSubscriptionMobileNotificationFilterInput
) {
  onDeleteMobileNotification(filter: $filter) {
    id
    title
    description
    body
    default
    event
    image
    concepts
    condition
    startDate
    endDate
    group
    read
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMobileNotificationSubscriptionVariables,
  APITypes.OnDeleteMobileNotificationSubscription
>;
export const onCreateUserPushToken = /* GraphQL */ `subscription OnCreateUserPushToken(
  $filter: ModelSubscriptionUserPushTokenFilterInput
) {
  onCreateUserPushToken(filter: $filter) {
    id
    userID
    token
    reciveNotification
    type
    os
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserPushTokenSubscriptionVariables,
  APITypes.OnCreateUserPushTokenSubscription
>;
export const onUpdateUserPushToken = /* GraphQL */ `subscription OnUpdateUserPushToken(
  $filter: ModelSubscriptionUserPushTokenFilterInput
) {
  onUpdateUserPushToken(filter: $filter) {
    id
    userID
    token
    reciveNotification
    type
    os
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserPushTokenSubscriptionVariables,
  APITypes.OnUpdateUserPushTokenSubscription
>;
export const onDeleteUserPushToken = /* GraphQL */ `subscription OnDeleteUserPushToken(
  $filter: ModelSubscriptionUserPushTokenFilterInput
) {
  onDeleteUserPushToken(filter: $filter) {
    id
    userID
    token
    reciveNotification
    type
    os
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserPushTokenSubscriptionVariables,
  APITypes.OnDeleteUserPushTokenSubscription
>;
export const onCreateNotificationReceiver = /* GraphQL */ `subscription OnCreateNotificationReceiver(
  $filter: ModelSubscriptionNotificationReceiverFilterInput
) {
  onCreateNotificationReceiver(filter: $filter) {
    id
    userID
    notificationID
    seen
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateNotificationReceiverSubscriptionVariables,
  APITypes.OnCreateNotificationReceiverSubscription
>;
export const onUpdateNotificationReceiver = /* GraphQL */ `subscription OnUpdateNotificationReceiver(
  $filter: ModelSubscriptionNotificationReceiverFilterInput
) {
  onUpdateNotificationReceiver(filter: $filter) {
    id
    userID
    notificationID
    seen
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateNotificationReceiverSubscriptionVariables,
  APITypes.OnUpdateNotificationReceiverSubscription
>;
export const onDeleteNotificationReceiver = /* GraphQL */ `subscription OnDeleteNotificationReceiver(
  $filter: ModelSubscriptionNotificationReceiverFilterInput
) {
  onDeleteNotificationReceiver(filter: $filter) {
    id
    userID
    notificationID
    seen
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteNotificationReceiverSubscriptionVariables,
  APITypes.OnDeleteNotificationReceiverSubscription
>;
export const onCreatePreparationArea = /* GraphQL */ `subscription OnCreatePreparationArea(
  $filter: ModelSubscriptionPreparationAreaFilterInput
) {
  onCreatePreparationArea(filter: $filter) {
    id
    accountID
    name
    isAutoAccept
    hasKDS
    hasPrinter
    users
    deleted
    precedence
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePreparationAreaSubscriptionVariables,
  APITypes.OnCreatePreparationAreaSubscription
>;
export const onUpdatePreparationArea = /* GraphQL */ `subscription OnUpdatePreparationArea(
  $filter: ModelSubscriptionPreparationAreaFilterInput
) {
  onUpdatePreparationArea(filter: $filter) {
    id
    accountID
    name
    isAutoAccept
    hasKDS
    hasPrinter
    users
    deleted
    precedence
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePreparationAreaSubscriptionVariables,
  APITypes.OnUpdatePreparationAreaSubscription
>;
export const onDeletePreparationArea = /* GraphQL */ `subscription OnDeletePreparationArea(
  $filter: ModelSubscriptionPreparationAreaFilterInput
) {
  onDeletePreparationArea(filter: $filter) {
    id
    accountID
    name
    isAutoAccept
    hasKDS
    hasPrinter
    users
    deleted
    precedence
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePreparationAreaSubscriptionVariables,
  APITypes.OnDeletePreparationAreaSubscription
>;
export const onCreateQRcode = /* GraphQL */ `subscription OnCreateQRcode($filter: ModelSubscriptionQRcodeFilterInput) {
  onCreateQRcode(filter: $filter) {
    id
    tableID
    conceptID
    tableName
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateQRcodeSubscriptionVariables,
  APITypes.OnCreateQRcodeSubscription
>;
export const onUpdateQRcode = /* GraphQL */ `subscription OnUpdateQRcode($filter: ModelSubscriptionQRcodeFilterInput) {
  onUpdateQRcode(filter: $filter) {
    id
    tableID
    conceptID
    tableName
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateQRcodeSubscriptionVariables,
  APITypes.OnUpdateQRcodeSubscription
>;
export const onDeleteQRcode = /* GraphQL */ `subscription OnDeleteQRcode($filter: ModelSubscriptionQRcodeFilterInput) {
  onDeleteQRcode(filter: $filter) {
    id
    tableID
    conceptID
    tableName
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteQRcodeSubscriptionVariables,
  APITypes.OnDeleteQRcodeSubscription
>;
export const onCreateCallWaiter = /* GraphQL */ `subscription OnCreateCallWaiter(
  $filter: ModelSubscriptionCallWaiterFilterInput
) {
  onCreateCallWaiter(filter: $filter) {
    id
    conceptID
    tableID
    tableName
    waiterID
    waiterName
    userID
    count
    status
    deleted
    createdAt
    acceptedAt
    arrivedAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCallWaiterSubscriptionVariables,
  APITypes.OnCreateCallWaiterSubscription
>;
export const onUpdateCallWaiter = /* GraphQL */ `subscription OnUpdateCallWaiter(
  $filter: ModelSubscriptionCallWaiterFilterInput
) {
  onUpdateCallWaiter(filter: $filter) {
    id
    conceptID
    tableID
    tableName
    waiterID
    waiterName
    userID
    count
    status
    deleted
    createdAt
    acceptedAt
    arrivedAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCallWaiterSubscriptionVariables,
  APITypes.OnUpdateCallWaiterSubscription
>;
export const onDeleteCallWaiter = /* GraphQL */ `subscription OnDeleteCallWaiter(
  $filter: ModelSubscriptionCallWaiterFilterInput
) {
  onDeleteCallWaiter(filter: $filter) {
    id
    conceptID
    tableID
    tableName
    waiterID
    waiterName
    userID
    count
    status
    deleted
    createdAt
    acceptedAt
    arrivedAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCallWaiterSubscriptionVariables,
  APITypes.OnDeleteCallWaiterSubscription
>;
export const onCreateUser = /* GraphQL */ `subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
  onCreateUser(filter: $filter) {
    id
    accountID
    username
    preferred_username
    email
    email_verified
    phone_number
    phone_number_verified
    name
    nickname
    given_name
    middle_name
    family_name
    address
    birthdate
    gender
    locale
    picture
    pictureCrop
    website
    zoneinfo
    verification_code
    facebook
    instagram
    group
    phones
    flags
    flagsName
    interests
    interestsName
    status
    stats
    spending
    prevOrderDate
    lastOrderDate
    numOfOrders
    promocodes {
      name
      promocodeId
      canBeUsed
      discountType
      discountVal
      discountLimit
      isDisplayed
      __typename
    }
    mostOrderedItems
    points
    deleted
    createdAt
    createdByID
    createdByName
    cognitoUsername
    cognitoSub
    updated
    sub
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserSubscriptionVariables,
  APITypes.OnCreateUserSubscription
>;
export const onUpdateUser = /* GraphQL */ `subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
  onUpdateUser(filter: $filter) {
    id
    accountID
    username
    preferred_username
    email
    email_verified
    phone_number
    phone_number_verified
    name
    nickname
    given_name
    middle_name
    family_name
    address
    birthdate
    gender
    locale
    picture
    pictureCrop
    website
    zoneinfo
    verification_code
    facebook
    instagram
    group
    phones
    flags
    flagsName
    interests
    interestsName
    status
    stats
    spending
    prevOrderDate
    lastOrderDate
    numOfOrders
    promocodes {
      name
      promocodeId
      canBeUsed
      discountType
      discountVal
      discountLimit
      isDisplayed
      __typename
    }
    mostOrderedItems
    points
    deleted
    createdAt
    createdByID
    createdByName
    cognitoUsername
    cognitoSub
    updated
    sub
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserSubscriptionVariables,
  APITypes.OnUpdateUserSubscription
>;
export const onDeleteUser = /* GraphQL */ `subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
  onDeleteUser(filter: $filter) {
    id
    accountID
    username
    preferred_username
    email
    email_verified
    phone_number
    phone_number_verified
    name
    nickname
    given_name
    middle_name
    family_name
    address
    birthdate
    gender
    locale
    picture
    pictureCrop
    website
    zoneinfo
    verification_code
    facebook
    instagram
    group
    phones
    flags
    flagsName
    interests
    interestsName
    status
    stats
    spending
    prevOrderDate
    lastOrderDate
    numOfOrders
    promocodes {
      name
      promocodeId
      canBeUsed
      discountType
      discountVal
      discountLimit
      isDisplayed
      __typename
    }
    mostOrderedItems
    points
    deleted
    createdAt
    createdByID
    createdByName
    cognitoUsername
    cognitoSub
    updated
    sub
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserSubscriptionVariables,
  APITypes.OnDeleteUserSubscription
>;
export const onCreateReview = /* GraphQL */ `subscription OnCreateReview($filter: ModelSubscriptionReviewFilterInput) {
  onCreateReview(filter: $filter) {
    id
    accountID
    bookingID
    guestName
    guestId
    reason
    value
    userID
    orderID
    rate
    satisfaction
    thoughts
    itemReviews {
      itemID
      rate
      satisfaction
      multiLanguages
      __typename
    }
    photos
    multiLanguages
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateReviewSubscriptionVariables,
  APITypes.OnCreateReviewSubscription
>;
export const onUpdateReview = /* GraphQL */ `subscription OnUpdateReview($filter: ModelSubscriptionReviewFilterInput) {
  onUpdateReview(filter: $filter) {
    id
    accountID
    bookingID
    guestName
    guestId
    reason
    value
    userID
    orderID
    rate
    satisfaction
    thoughts
    itemReviews {
      itemID
      rate
      satisfaction
      multiLanguages
      __typename
    }
    photos
    multiLanguages
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateReviewSubscriptionVariables,
  APITypes.OnUpdateReviewSubscription
>;
export const onDeleteReview = /* GraphQL */ `subscription OnDeleteReview($filter: ModelSubscriptionReviewFilterInput) {
  onDeleteReview(filter: $filter) {
    id
    accountID
    bookingID
    guestName
    guestId
    reason
    value
    userID
    orderID
    rate
    satisfaction
    thoughts
    itemReviews {
      itemID
      rate
      satisfaction
      multiLanguages
      __typename
    }
    photos
    multiLanguages
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteReviewSubscriptionVariables,
  APITypes.OnDeleteReviewSubscription
>;
export const onCreateAccount = /* GraphQL */ `subscription OnCreateAccount($filter: ModelSubscriptionAccountFilterInput) {
  onCreateAccount(filter: $filter) {
    id
    logo
    domain
    siteTitle
    guestsCount
    tagline
    description
    siteAddress
    defaultLanguage
    languages
    features
    status
    socialLinks
    deleted
    createdAt
    createdByID
    createdByName
    callCenterEmployeesIds
    kpiReportResetFrequency
    agentBarResetFrequency
    dynamicStatus
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAccountSubscriptionVariables,
  APITypes.OnCreateAccountSubscription
>;
export const onUpdateAccount = /* GraphQL */ `subscription OnUpdateAccount($filter: ModelSubscriptionAccountFilterInput) {
  onUpdateAccount(filter: $filter) {
    id
    logo
    domain
    siteTitle
    guestsCount
    tagline
    description
    siteAddress
    defaultLanguage
    languages
    features
    status
    socialLinks
    deleted
    createdAt
    createdByID
    createdByName
    callCenterEmployeesIds
    kpiReportResetFrequency
    agentBarResetFrequency
    dynamicStatus
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAccountSubscriptionVariables,
  APITypes.OnUpdateAccountSubscription
>;
export const onDeleteAccount = /* GraphQL */ `subscription OnDeleteAccount($filter: ModelSubscriptionAccountFilterInput) {
  onDeleteAccount(filter: $filter) {
    id
    logo
    domain
    siteTitle
    guestsCount
    tagline
    description
    siteAddress
    defaultLanguage
    languages
    features
    status
    socialLinks
    deleted
    createdAt
    createdByID
    createdByName
    callCenterEmployeesIds
    kpiReportResetFrequency
    agentBarResetFrequency
    dynamicStatus
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAccountSubscriptionVariables,
  APITypes.OnDeleteAccountSubscription
>;
export const onCreateFeature = /* GraphQL */ `subscription OnCreateFeature($filter: ModelSubscriptionFeatureFilterInput) {
  onCreateFeature(filter: $filter) {
    id
    name
    icon
    slug
    precedence
    parent
    private
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateFeatureSubscriptionVariables,
  APITypes.OnCreateFeatureSubscription
>;
export const onUpdateFeature = /* GraphQL */ `subscription OnUpdateFeature($filter: ModelSubscriptionFeatureFilterInput) {
  onUpdateFeature(filter: $filter) {
    id
    name
    icon
    slug
    precedence
    parent
    private
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFeatureSubscriptionVariables,
  APITypes.OnUpdateFeatureSubscription
>;
export const onDeleteFeature = /* GraphQL */ `subscription OnDeleteFeature($filter: ModelSubscriptionFeatureFilterInput) {
  onDeleteFeature(filter: $filter) {
    id
    name
    icon
    slug
    precedence
    parent
    private
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteFeatureSubscriptionVariables,
  APITypes.OnDeleteFeatureSubscription
>;
export const onCreateMedia = /* GraphQL */ `subscription OnCreateMedia($filter: ModelSubscriptionMediaFilterInput) {
  onCreateMedia(filter: $filter) {
    id
    accountID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    attachments
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMediaSubscriptionVariables,
  APITypes.OnCreateMediaSubscription
>;
export const onUpdateMedia = /* GraphQL */ `subscription OnUpdateMedia($filter: ModelSubscriptionMediaFilterInput) {
  onUpdateMedia(filter: $filter) {
    id
    accountID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    attachments
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMediaSubscriptionVariables,
  APITypes.OnUpdateMediaSubscription
>;
export const onDeleteMedia = /* GraphQL */ `subscription OnDeleteMedia($filter: ModelSubscriptionMediaFilterInput) {
  onDeleteMedia(filter: $filter) {
    id
    accountID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    attachments
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMediaSubscriptionVariables,
  APITypes.OnDeleteMediaSubscription
>;
export const onCreateConcept = /* GraphQL */ `subscription OnCreateConcept($filter: ModelSubscriptionConceptFilterInput) {
  onCreateConcept(filter: $filter) {
    id
    accountID
    name
    kiosks
    merchantID
    merchantUsername
    merchantPassword
    merchantIntegrationNumber
    merchantAPIKey
    version
    simphonyServerBaseURL
    simphonyShortOrgName
    simphonyLocRef
    simphonyToken
    revenueCenterID
    employeeID
    tenderID
    tenders {
      items {
        id
        name
        simphonyID
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        conceptTendersId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    orderType {
      items {
        id
        name
        simphonyID
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        conceptOrderTypeId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    discountID
    discountPercentID
    discountAmountID
    useServiceCharge
    serviceChargeId
    specialRequestID
    addressID
    simphonyAuthClientId
    simphonyAuthUsername
    simphonyAuthPassword
    simphonyAuthBaseURL
    orderWay {
      name
      simphonyID
      priceSequence
      orderTypeWayPayment {
        name
        simphonyID
        __typename
      }
      __typename
    }
    orderTypeDineIn
    orderTypeTakeAway
    orderTypeDelivery
    simphonyTakeAwayRvc
    simphonyDeliveryRvc
    exportInterval
    deleted
    createdAt
    createdByID
    createdByName
    foodicsToken
    enableTableFoodics
    image
    logo
    album
    type
    rating
    location
    description
    areas
    seatTypes
    needAssistance
    spaBooking
    showerBooking
    cigarMenu
    precedence
    isPinRequired
    viewOnly
    openTime
    closeTime
    varifyForAmount
    varifyFirstOrder
    varifyNewAddress
    sliderImages
    receiveWaiters
    WaiterNotificationOption
    applyLoyalty
    rewardPoints
    moneySpent
    afterVat
    afterDeliveryFee
    beforePromoCode
    pointsCalcWithPromoCode
    isBusy
    allowOnlineOrdering
    minOrderVal
    vatPercentage
    serviceChargePercentage
    addDeliveryToVat
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateConceptSubscriptionVariables,
  APITypes.OnCreateConceptSubscription
>;
export const onUpdateConcept = /* GraphQL */ `subscription OnUpdateConcept($filter: ModelSubscriptionConceptFilterInput) {
  onUpdateConcept(filter: $filter) {
    id
    accountID
    name
    kiosks
    merchantID
    merchantUsername
    merchantPassword
    merchantIntegrationNumber
    merchantAPIKey
    version
    simphonyServerBaseURL
    simphonyShortOrgName
    simphonyLocRef
    simphonyToken
    revenueCenterID
    employeeID
    tenderID
    tenders {
      items {
        id
        name
        simphonyID
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        conceptTendersId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    orderType {
      items {
        id
        name
        simphonyID
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        conceptOrderTypeId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    discountID
    discountPercentID
    discountAmountID
    useServiceCharge
    serviceChargeId
    specialRequestID
    addressID
    simphonyAuthClientId
    simphonyAuthUsername
    simphonyAuthPassword
    simphonyAuthBaseURL
    orderWay {
      name
      simphonyID
      priceSequence
      orderTypeWayPayment {
        name
        simphonyID
        __typename
      }
      __typename
    }
    orderTypeDineIn
    orderTypeTakeAway
    orderTypeDelivery
    simphonyTakeAwayRvc
    simphonyDeliveryRvc
    exportInterval
    deleted
    createdAt
    createdByID
    createdByName
    foodicsToken
    enableTableFoodics
    image
    logo
    album
    type
    rating
    location
    description
    areas
    seatTypes
    needAssistance
    spaBooking
    showerBooking
    cigarMenu
    precedence
    isPinRequired
    viewOnly
    openTime
    closeTime
    varifyForAmount
    varifyFirstOrder
    varifyNewAddress
    sliderImages
    receiveWaiters
    WaiterNotificationOption
    applyLoyalty
    rewardPoints
    moneySpent
    afterVat
    afterDeliveryFee
    beforePromoCode
    pointsCalcWithPromoCode
    isBusy
    allowOnlineOrdering
    minOrderVal
    vatPercentage
    serviceChargePercentage
    addDeliveryToVat
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateConceptSubscriptionVariables,
  APITypes.OnUpdateConceptSubscription
>;
export const onDeleteConcept = /* GraphQL */ `subscription OnDeleteConcept($filter: ModelSubscriptionConceptFilterInput) {
  onDeleteConcept(filter: $filter) {
    id
    accountID
    name
    kiosks
    merchantID
    merchantUsername
    merchantPassword
    merchantIntegrationNumber
    merchantAPIKey
    version
    simphonyServerBaseURL
    simphonyShortOrgName
    simphonyLocRef
    simphonyToken
    revenueCenterID
    employeeID
    tenderID
    tenders {
      items {
        id
        name
        simphonyID
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        conceptTendersId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    orderType {
      items {
        id
        name
        simphonyID
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        conceptOrderTypeId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    discountID
    discountPercentID
    discountAmountID
    useServiceCharge
    serviceChargeId
    specialRequestID
    addressID
    simphonyAuthClientId
    simphonyAuthUsername
    simphonyAuthPassword
    simphonyAuthBaseURL
    orderWay {
      name
      simphonyID
      priceSequence
      orderTypeWayPayment {
        name
        simphonyID
        __typename
      }
      __typename
    }
    orderTypeDineIn
    orderTypeTakeAway
    orderTypeDelivery
    simphonyTakeAwayRvc
    simphonyDeliveryRvc
    exportInterval
    deleted
    createdAt
    createdByID
    createdByName
    foodicsToken
    enableTableFoodics
    image
    logo
    album
    type
    rating
    location
    description
    areas
    seatTypes
    needAssistance
    spaBooking
    showerBooking
    cigarMenu
    precedence
    isPinRequired
    viewOnly
    openTime
    closeTime
    varifyForAmount
    varifyFirstOrder
    varifyNewAddress
    sliderImages
    receiveWaiters
    WaiterNotificationOption
    applyLoyalty
    rewardPoints
    moneySpent
    afterVat
    afterDeliveryFee
    beforePromoCode
    pointsCalcWithPromoCode
    isBusy
    allowOnlineOrdering
    minOrderVal
    vatPercentage
    serviceChargePercentage
    addDeliveryToVat
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteConceptSubscriptionVariables,
  APITypes.OnDeleteConceptSubscription
>;
export const onCreateTender = /* GraphQL */ `subscription OnCreateTender($filter: ModelSubscriptionTenderFilterInput) {
  onCreateTender(filter: $filter) {
    id
    name
    simphonyID
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    conceptTendersId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTenderSubscriptionVariables,
  APITypes.OnCreateTenderSubscription
>;
export const onUpdateTender = /* GraphQL */ `subscription OnUpdateTender($filter: ModelSubscriptionTenderFilterInput) {
  onUpdateTender(filter: $filter) {
    id
    name
    simphonyID
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    conceptTendersId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTenderSubscriptionVariables,
  APITypes.OnUpdateTenderSubscription
>;
export const onDeleteTender = /* GraphQL */ `subscription OnDeleteTender($filter: ModelSubscriptionTenderFilterInput) {
  onDeleteTender(filter: $filter) {
    id
    name
    simphonyID
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    conceptTendersId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTenderSubscriptionVariables,
  APITypes.OnDeleteTenderSubscription
>;
export const onCreateOrderType = /* GraphQL */ `subscription OnCreateOrderType($filter: ModelSubscriptionOrderTypeFilterInput) {
  onCreateOrderType(filter: $filter) {
    id
    name
    simphonyID
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    conceptOrderTypeId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateOrderTypeSubscriptionVariables,
  APITypes.OnCreateOrderTypeSubscription
>;
export const onUpdateOrderType = /* GraphQL */ `subscription OnUpdateOrderType($filter: ModelSubscriptionOrderTypeFilterInput) {
  onUpdateOrderType(filter: $filter) {
    id
    name
    simphonyID
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    conceptOrderTypeId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateOrderTypeSubscriptionVariables,
  APITypes.OnUpdateOrderTypeSubscription
>;
export const onDeleteOrderType = /* GraphQL */ `subscription OnDeleteOrderType($filter: ModelSubscriptionOrderTypeFilterInput) {
  onDeleteOrderType(filter: $filter) {
    id
    name
    simphonyID
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    conceptOrderTypeId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteOrderTypeSubscriptionVariables,
  APITypes.OnDeleteOrderTypeSubscription
>;
export const onCreateParentConcept = /* GraphQL */ `subscription OnCreateParentConcept(
  $filter: ModelSubscriptionParentConceptFilterInput
) {
  onCreateParentConcept(filter: $filter) {
    id
    deleted
    createdAt
    createdByID
    createdByName
    image
    name
    logo
    type
    venues
    index
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateParentConceptSubscriptionVariables,
  APITypes.OnCreateParentConceptSubscription
>;
export const onUpdateParentConcept = /* GraphQL */ `subscription OnUpdateParentConcept(
  $filter: ModelSubscriptionParentConceptFilterInput
) {
  onUpdateParentConcept(filter: $filter) {
    id
    deleted
    createdAt
    createdByID
    createdByName
    image
    name
    logo
    type
    venues
    index
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateParentConceptSubscriptionVariables,
  APITypes.OnUpdateParentConceptSubscription
>;
export const onDeleteParentConcept = /* GraphQL */ `subscription OnDeleteParentConcept(
  $filter: ModelSubscriptionParentConceptFilterInput
) {
  onDeleteParentConcept(filter: $filter) {
    id
    deleted
    createdAt
    createdByID
    createdByName
    image
    name
    logo
    type
    venues
    index
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteParentConceptSubscriptionVariables,
  APITypes.OnDeleteParentConceptSubscription
>;
export const onCreatePlanItem = /* GraphQL */ `subscription OnCreatePlanItem($filter: ModelSubscriptionPlanItemFilterInput) {
  onCreatePlanItem(filter: $filter) {
    id
    areaID
    conceptID
    xPosition
    yPosition
    name
    isReserved
    bookings
    table
    object
    lockDays
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePlanItemSubscriptionVariables,
  APITypes.OnCreatePlanItemSubscription
>;
export const onUpdatePlanItem = /* GraphQL */ `subscription OnUpdatePlanItem($filter: ModelSubscriptionPlanItemFilterInput) {
  onUpdatePlanItem(filter: $filter) {
    id
    areaID
    conceptID
    xPosition
    yPosition
    name
    isReserved
    bookings
    table
    object
    lockDays
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePlanItemSubscriptionVariables,
  APITypes.OnUpdatePlanItemSubscription
>;
export const onDeletePlanItem = /* GraphQL */ `subscription OnDeletePlanItem($filter: ModelSubscriptionPlanItemFilterInput) {
  onDeletePlanItem(filter: $filter) {
    id
    areaID
    conceptID
    xPosition
    yPosition
    name
    isReserved
    bookings
    table
    object
    lockDays
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePlanItemSubscriptionVariables,
  APITypes.OnDeletePlanItemSubscription
>;
export const onCreateObject = /* GraphQL */ `subscription OnCreateObject($filter: ModelSubscriptionObjectFilterInput) {
  onCreateObject(filter: $filter) {
    id
    conceptID
    label
    name
    image {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    width
    height
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    objectImageId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateObjectSubscriptionVariables,
  APITypes.OnCreateObjectSubscription
>;
export const onUpdateObject = /* GraphQL */ `subscription OnUpdateObject($filter: ModelSubscriptionObjectFilterInput) {
  onUpdateObject(filter: $filter) {
    id
    conceptID
    label
    name
    image {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    width
    height
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    objectImageId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateObjectSubscriptionVariables,
  APITypes.OnUpdateObjectSubscription
>;
export const onDeleteObject = /* GraphQL */ `subscription OnDeleteObject($filter: ModelSubscriptionObjectFilterInput) {
  onDeleteObject(filter: $filter) {
    id
    conceptID
    label
    name
    image {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    width
    height
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    objectImageId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteObjectSubscriptionVariables,
  APITypes.OnDeleteObjectSubscription
>;
export const onCreateBooking = /* GraphQL */ `subscription OnCreateBooking($filter: ModelSubscriptionBookingFilterInput) {
  onCreateBooking(filter: $filter) {
    id
    depositValue
    depositStatus
    eventStatus
    eventName
    paymentStatus
    paymentValue
    disablePartialPayment
    disablePayment
    eventDate
    eventGuests
    timeSlotID
    timeSlots
    conceptID
    mainGuest
    customerName
    customerPhone
    customerGroup
    accompaniedCount
    channel
    statusID
    rating
    review
    confirmationHash
    isConfirmedByUser
    date
    tables
    tableName
    comments
    lastComment
    commentCreatedBy
    commentCreatedDate
    timeLeft
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBookingSubscriptionVariables,
  APITypes.OnCreateBookingSubscription
>;
export const onUpdateBooking = /* GraphQL */ `subscription OnUpdateBooking($filter: ModelSubscriptionBookingFilterInput) {
  onUpdateBooking(filter: $filter) {
    id
    depositValue
    depositStatus
    eventStatus
    eventName
    paymentStatus
    paymentValue
    disablePartialPayment
    disablePayment
    eventDate
    eventGuests
    timeSlotID
    timeSlots
    conceptID
    mainGuest
    customerName
    customerPhone
    customerGroup
    accompaniedCount
    channel
    statusID
    rating
    review
    confirmationHash
    isConfirmedByUser
    date
    tables
    tableName
    comments
    lastComment
    commentCreatedBy
    commentCreatedDate
    timeLeft
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBookingSubscriptionVariables,
  APITypes.OnUpdateBookingSubscription
>;
export const onDeleteBooking = /* GraphQL */ `subscription OnDeleteBooking($filter: ModelSubscriptionBookingFilterInput) {
  onDeleteBooking(filter: $filter) {
    id
    depositValue
    depositStatus
    eventStatus
    eventName
    paymentStatus
    paymentValue
    disablePartialPayment
    disablePayment
    eventDate
    eventGuests
    timeSlotID
    timeSlots
    conceptID
    mainGuest
    customerName
    customerPhone
    customerGroup
    accompaniedCount
    channel
    statusID
    rating
    review
    confirmationHash
    isConfirmedByUser
    date
    tables
    tableName
    comments
    lastComment
    commentCreatedBy
    commentCreatedDate
    timeLeft
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBookingSubscriptionVariables,
  APITypes.OnDeleteBookingSubscription
>;
export const onCreateTimeline = /* GraphQL */ `subscription OnCreateTimeline($filter: ModelSubscriptionTimelineFilterInput) {
  onCreateTimeline(filter: $filter) {
    id
    actionName
    oldStatus
    newStatus
    bookingId
    transactionId
    customerId
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTimelineSubscriptionVariables,
  APITypes.OnCreateTimelineSubscription
>;
export const onUpdateTimeline = /* GraphQL */ `subscription OnUpdateTimeline($filter: ModelSubscriptionTimelineFilterInput) {
  onUpdateTimeline(filter: $filter) {
    id
    actionName
    oldStatus
    newStatus
    bookingId
    transactionId
    customerId
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTimelineSubscriptionVariables,
  APITypes.OnUpdateTimelineSubscription
>;
export const onDeleteTimeline = /* GraphQL */ `subscription OnDeleteTimeline($filter: ModelSubscriptionTimelineFilterInput) {
  onDeleteTimeline(filter: $filter) {
    id
    actionName
    oldStatus
    newStatus
    bookingId
    transactionId
    customerId
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTimelineSubscriptionVariables,
  APITypes.OnDeleteTimelineSubscription
>;
export const onCreateCall = /* GraphQL */ `subscription OnCreateCall($filter: ModelSubscriptionCallFilterInput) {
  onCreateCall(filter: $filter) {
    id
    phone_number
    customer {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      pictureCrop
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      spending
      prevOrderDate
      lastOrderDate
      numOfOrders
      promocodes {
        name
        promocodeId
        canBeUsed
        discountType
        discountVal
        discountLimit
        isDisplayed
        __typename
      }
      mostOrderedItems
      points
      deleted
      createdAt
      createdByID
      createdByName
      cognitoUsername
      cognitoSub
      updated
      sub
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    createdByID
    createdByName
    endedAt
    duration
    date
    employeeId
    updatedAt
    _version
    _deleted
    _lastChangedAt
    callCustomerId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCallSubscriptionVariables,
  APITypes.OnCreateCallSubscription
>;
export const onUpdateCall = /* GraphQL */ `subscription OnUpdateCall($filter: ModelSubscriptionCallFilterInput) {
  onUpdateCall(filter: $filter) {
    id
    phone_number
    customer {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      pictureCrop
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      spending
      prevOrderDate
      lastOrderDate
      numOfOrders
      promocodes {
        name
        promocodeId
        canBeUsed
        discountType
        discountVal
        discountLimit
        isDisplayed
        __typename
      }
      mostOrderedItems
      points
      deleted
      createdAt
      createdByID
      createdByName
      cognitoUsername
      cognitoSub
      updated
      sub
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    createdByID
    createdByName
    endedAt
    duration
    date
    employeeId
    updatedAt
    _version
    _deleted
    _lastChangedAt
    callCustomerId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCallSubscriptionVariables,
  APITypes.OnUpdateCallSubscription
>;
export const onDeleteCall = /* GraphQL */ `subscription OnDeleteCall($filter: ModelSubscriptionCallFilterInput) {
  onDeleteCall(filter: $filter) {
    id
    phone_number
    customer {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      pictureCrop
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      spending
      prevOrderDate
      lastOrderDate
      numOfOrders
      promocodes {
        name
        promocodeId
        canBeUsed
        discountType
        discountVal
        discountLimit
        isDisplayed
        __typename
      }
      mostOrderedItems
      points
      deleted
      createdAt
      createdByID
      createdByName
      cognitoUsername
      cognitoSub
      updated
      sub
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    createdByID
    createdByName
    endedAt
    duration
    date
    employeeId
    updatedAt
    _version
    _deleted
    _lastChangedAt
    callCustomerId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCallSubscriptionVariables,
  APITypes.OnDeleteCallSubscription
>;
export const onCreateAdminRole = /* GraphQL */ `subscription OnCreateAdminRole($filter: ModelSubscriptionAdminRoleFilterInput) {
  onCreateAdminRole(filter: $filter) {
    id
    name
    description
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAdminRoleSubscriptionVariables,
  APITypes.OnCreateAdminRoleSubscription
>;
export const onUpdateAdminRole = /* GraphQL */ `subscription OnUpdateAdminRole($filter: ModelSubscriptionAdminRoleFilterInput) {
  onUpdateAdminRole(filter: $filter) {
    id
    name
    description
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAdminRoleSubscriptionVariables,
  APITypes.OnUpdateAdminRoleSubscription
>;
export const onDeleteAdminRole = /* GraphQL */ `subscription OnDeleteAdminRole($filter: ModelSubscriptionAdminRoleFilterInput) {
  onDeleteAdminRole(filter: $filter) {
    id
    name
    description
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAdminRoleSubscriptionVariables,
  APITypes.OnDeleteAdminRoleSubscription
>;
export const onCreateAdminGroup = /* GraphQL */ `subscription OnCreateAdminGroup(
  $filter: ModelSubscriptionAdminGroupFilterInput
) {
  onCreateAdminGroup(filter: $filter) {
    id
    name
    description
    roles
    users
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAdminGroupSubscriptionVariables,
  APITypes.OnCreateAdminGroupSubscription
>;
export const onUpdateAdminGroup = /* GraphQL */ `subscription OnUpdateAdminGroup(
  $filter: ModelSubscriptionAdminGroupFilterInput
) {
  onUpdateAdminGroup(filter: $filter) {
    id
    name
    description
    roles
    users
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAdminGroupSubscriptionVariables,
  APITypes.OnUpdateAdminGroupSubscription
>;
export const onDeleteAdminGroup = /* GraphQL */ `subscription OnDeleteAdminGroup(
  $filter: ModelSubscriptionAdminGroupFilterInput
) {
  onDeleteAdminGroup(filter: $filter) {
    id
    name
    description
    roles
    users
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAdminGroupSubscriptionVariables,
  APITypes.OnDeleteAdminGroupSubscription
>;
export const onCreateUserConcepts = /* GraphQL */ `subscription OnCreateUserConcepts(
  $filter: ModelSubscriptionUserConceptsFilterInput
) {
  onCreateUserConcepts(filter: $filter) {
    id
    defaultConcept
    concepts
    conceptsRoles
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserConceptsSubscriptionVariables,
  APITypes.OnCreateUserConceptsSubscription
>;
export const onUpdateUserConcepts = /* GraphQL */ `subscription OnUpdateUserConcepts(
  $filter: ModelSubscriptionUserConceptsFilterInput
) {
  onUpdateUserConcepts(filter: $filter) {
    id
    defaultConcept
    concepts
    conceptsRoles
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserConceptsSubscriptionVariables,
  APITypes.OnUpdateUserConceptsSubscription
>;
export const onDeleteUserConcepts = /* GraphQL */ `subscription OnDeleteUserConcepts(
  $filter: ModelSubscriptionUserConceptsFilterInput
) {
  onDeleteUserConcepts(filter: $filter) {
    id
    defaultConcept
    concepts
    conceptsRoles
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserConceptsSubscriptionVariables,
  APITypes.OnDeleteUserConceptsSubscription
>;
export const onCreateTransaction = /* GraphQL */ `subscription OnCreateTransaction(
  $filter: ModelSubscriptionTransactionFilterInput
) {
  onCreateTransaction(filter: $filter) {
    id
    transactionID
    date
    guestName
    guestPhone
    guestID
    guestGroup
    guestsNames
    bookingID
    bookingDate
    amount_cents
    status
    failureReason
    type
    timeSlots
    tables
    conceptID
    currency
    refund
    reference_orderID
    ownerID
    refunded_amount_cents
    deleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTransactionSubscriptionVariables,
  APITypes.OnCreateTransactionSubscription
>;
export const onUpdateTransaction = /* GraphQL */ `subscription OnUpdateTransaction(
  $filter: ModelSubscriptionTransactionFilterInput
) {
  onUpdateTransaction(filter: $filter) {
    id
    transactionID
    date
    guestName
    guestPhone
    guestID
    guestGroup
    guestsNames
    bookingID
    bookingDate
    amount_cents
    status
    failureReason
    type
    timeSlots
    tables
    conceptID
    currency
    refund
    reference_orderID
    ownerID
    refunded_amount_cents
    deleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTransactionSubscriptionVariables,
  APITypes.OnUpdateTransactionSubscription
>;
export const onDeleteTransaction = /* GraphQL */ `subscription OnDeleteTransaction(
  $filter: ModelSubscriptionTransactionFilterInput
) {
  onDeleteTransaction(filter: $filter) {
    id
    transactionID
    date
    guestName
    guestPhone
    guestID
    guestGroup
    guestsNames
    bookingID
    bookingDate
    amount_cents
    status
    failureReason
    type
    timeSlots
    tables
    conceptID
    currency
    refund
    reference_orderID
    ownerID
    refunded_amount_cents
    deleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTransactionSubscriptionVariables,
  APITypes.OnDeleteTransactionSubscription
>;
export const onCreatePaymentStat = /* GraphQL */ `subscription OnCreatePaymentStat(
  $filter: ModelSubscriptionPaymentStatFilterInput
) {
  onCreatePaymentStat(filter: $filter) {
    id
    accountID
    conceptID
    year
    month
    monthName
    earning
    refund
    earningTrxCount
    refundTrxCount
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePaymentStatSubscriptionVariables,
  APITypes.OnCreatePaymentStatSubscription
>;
export const onUpdatePaymentStat = /* GraphQL */ `subscription OnUpdatePaymentStat(
  $filter: ModelSubscriptionPaymentStatFilterInput
) {
  onUpdatePaymentStat(filter: $filter) {
    id
    accountID
    conceptID
    year
    month
    monthName
    earning
    refund
    earningTrxCount
    refundTrxCount
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePaymentStatSubscriptionVariables,
  APITypes.OnUpdatePaymentStatSubscription
>;
export const onDeletePaymentStat = /* GraphQL */ `subscription OnDeletePaymentStat(
  $filter: ModelSubscriptionPaymentStatFilterInput
) {
  onDeletePaymentStat(filter: $filter) {
    id
    accountID
    conceptID
    year
    month
    monthName
    earning
    refund
    earningTrxCount
    refundTrxCount
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePaymentStatSubscriptionVariables,
  APITypes.OnDeletePaymentStatSubscription
>;
export const onCreateBookingGuest = /* GraphQL */ `subscription OnCreateBookingGuest(
  $filter: ModelSubscriptionBookingGuestFilterInput
) {
  onCreateBookingGuest(filter: $filter) {
    id
    bookingID
    guestName
    paidAmount
    paymentAmount
    paymentStatus
    transactionID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBookingGuestSubscriptionVariables,
  APITypes.OnCreateBookingGuestSubscription
>;
export const onUpdateBookingGuest = /* GraphQL */ `subscription OnUpdateBookingGuest(
  $filter: ModelSubscriptionBookingGuestFilterInput
) {
  onUpdateBookingGuest(filter: $filter) {
    id
    bookingID
    guestName
    paidAmount
    paymentAmount
    paymentStatus
    transactionID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBookingGuestSubscriptionVariables,
  APITypes.OnUpdateBookingGuestSubscription
>;
export const onDeleteBookingGuest = /* GraphQL */ `subscription OnDeleteBookingGuest(
  $filter: ModelSubscriptionBookingGuestFilterInput
) {
  onDeleteBookingGuest(filter: $filter) {
    id
    bookingID
    guestName
    paidAmount
    paymentAmount
    paymentStatus
    transactionID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBookingGuestSubscriptionVariables,
  APITypes.OnDeleteBookingGuestSubscription
>;
export const onCreateCategory = /* GraphQL */ `subscription OnCreateCategory($filter: ModelSubscriptionCategoryFilterInput) {
  onCreateCategory(filter: $filter) {
    id
    conceptID
    preparationAreaID
    subCategory
    name
    guestView
    guestOrder
    staffOrder
    image
    darkImage
    menuItems {
      items {
        id
        conceptID
        kioskID
        name
        description
        image
        precedence
        categoryID
        categoryName
        outOfStock
        requiredPoints
        symphonyID
        comboItemID
        ratings
        prices
        choiceGroups
        choiceGroupsRules {
          id
          minNumberOfChoices
          maxNumberOfChoices
          __typename
        }
        ExtraChoiceGroups
        enabled
        hasLimitedQuantity
        quantity
        autoRestock
        restockQuantity
        deleted
        createdAt
        createdByID
        createdByName
        quantityOrdered
        performancePerDay
        multiLanguages
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    symphonyID
    isHidden
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCategorySubscriptionVariables,
  APITypes.OnCreateCategorySubscription
>;
export const onUpdateCategory = /* GraphQL */ `subscription OnUpdateCategory($filter: ModelSubscriptionCategoryFilterInput) {
  onUpdateCategory(filter: $filter) {
    id
    conceptID
    preparationAreaID
    subCategory
    name
    guestView
    guestOrder
    staffOrder
    image
    darkImage
    menuItems {
      items {
        id
        conceptID
        kioskID
        name
        description
        image
        precedence
        categoryID
        categoryName
        outOfStock
        requiredPoints
        symphonyID
        comboItemID
        ratings
        prices
        choiceGroups
        choiceGroupsRules {
          id
          minNumberOfChoices
          maxNumberOfChoices
          __typename
        }
        ExtraChoiceGroups
        enabled
        hasLimitedQuantity
        quantity
        autoRestock
        restockQuantity
        deleted
        createdAt
        createdByID
        createdByName
        quantityOrdered
        performancePerDay
        multiLanguages
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    symphonyID
    isHidden
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCategorySubscriptionVariables,
  APITypes.OnUpdateCategorySubscription
>;
export const onDeleteCategory = /* GraphQL */ `subscription OnDeleteCategory($filter: ModelSubscriptionCategoryFilterInput) {
  onDeleteCategory(filter: $filter) {
    id
    conceptID
    preparationAreaID
    subCategory
    name
    guestView
    guestOrder
    staffOrder
    image
    darkImage
    menuItems {
      items {
        id
        conceptID
        kioskID
        name
        description
        image
        precedence
        categoryID
        categoryName
        outOfStock
        requiredPoints
        symphonyID
        comboItemID
        ratings
        prices
        choiceGroups
        choiceGroupsRules {
          id
          minNumberOfChoices
          maxNumberOfChoices
          __typename
        }
        ExtraChoiceGroups
        enabled
        hasLimitedQuantity
        quantity
        autoRestock
        restockQuantity
        deleted
        createdAt
        createdByID
        createdByName
        quantityOrdered
        performancePerDay
        multiLanguages
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    symphonyID
    isHidden
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCategorySubscriptionVariables,
  APITypes.OnDeleteCategorySubscription
>;
export const onCreateMenuItem = /* GraphQL */ `subscription OnCreateMenuItem($filter: ModelSubscriptionMenuItemFilterInput) {
  onCreateMenuItem(filter: $filter) {
    id
    conceptID
    kioskID
    name
    description
    image
    precedence
    categoryID
    categoryName
    outOfStock
    requiredPoints
    symphonyID
    comboItemID
    ratings
    prices
    choiceGroups
    choiceGroupsRules {
      id
      minNumberOfChoices
      maxNumberOfChoices
      __typename
    }
    ExtraChoiceGroups
    enabled
    hasLimitedQuantity
    quantity
    autoRestock
    restockQuantity
    deleted
    createdAt
    createdByID
    createdByName
    quantityOrdered
    performancePerDay
    multiLanguages
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMenuItemSubscriptionVariables,
  APITypes.OnCreateMenuItemSubscription
>;
export const onUpdateMenuItem = /* GraphQL */ `subscription OnUpdateMenuItem($filter: ModelSubscriptionMenuItemFilterInput) {
  onUpdateMenuItem(filter: $filter) {
    id
    conceptID
    kioskID
    name
    description
    image
    precedence
    categoryID
    categoryName
    outOfStock
    requiredPoints
    symphonyID
    comboItemID
    ratings
    prices
    choiceGroups
    choiceGroupsRules {
      id
      minNumberOfChoices
      maxNumberOfChoices
      __typename
    }
    ExtraChoiceGroups
    enabled
    hasLimitedQuantity
    quantity
    autoRestock
    restockQuantity
    deleted
    createdAt
    createdByID
    createdByName
    quantityOrdered
    performancePerDay
    multiLanguages
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMenuItemSubscriptionVariables,
  APITypes.OnUpdateMenuItemSubscription
>;
export const onDeleteMenuItem = /* GraphQL */ `subscription OnDeleteMenuItem($filter: ModelSubscriptionMenuItemFilterInput) {
  onDeleteMenuItem(filter: $filter) {
    id
    conceptID
    kioskID
    name
    description
    image
    precedence
    categoryID
    categoryName
    outOfStock
    requiredPoints
    symphonyID
    comboItemID
    ratings
    prices
    choiceGroups
    choiceGroupsRules {
      id
      minNumberOfChoices
      maxNumberOfChoices
      __typename
    }
    ExtraChoiceGroups
    enabled
    hasLimitedQuantity
    quantity
    autoRestock
    restockQuantity
    deleted
    createdAt
    createdByID
    createdByName
    quantityOrdered
    performancePerDay
    multiLanguages
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMenuItemSubscriptionVariables,
  APITypes.OnDeleteMenuItemSubscription
>;
export const onCreatePrice = /* GraphQL */ `subscription OnCreatePrice($filter: ModelSubscriptionPriceFilterInput) {
  onCreatePrice(filter: $filter) {
    id
    conceptID
    name
    multiLanguages
    price
    symphonyID
    priceSequence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePriceSubscriptionVariables,
  APITypes.OnCreatePriceSubscription
>;
export const onUpdatePrice = /* GraphQL */ `subscription OnUpdatePrice($filter: ModelSubscriptionPriceFilterInput) {
  onUpdatePrice(filter: $filter) {
    id
    conceptID
    name
    multiLanguages
    price
    symphonyID
    priceSequence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePriceSubscriptionVariables,
  APITypes.OnUpdatePriceSubscription
>;
export const onDeletePrice = /* GraphQL */ `subscription OnDeletePrice($filter: ModelSubscriptionPriceFilterInput) {
  onDeletePrice(filter: $filter) {
    id
    conceptID
    name
    multiLanguages
    price
    symphonyID
    priceSequence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePriceSubscriptionVariables,
  APITypes.OnDeletePriceSubscription
>;
export const onCreateChoiceGroups = /* GraphQL */ `subscription OnCreateChoiceGroups(
  $filter: ModelSubscriptionChoiceGroupsFilterInput
) {
  onCreateChoiceGroups(filter: $filter) {
    id
    conceptID
    name
    required
    maxNumberOfChoices
    minNumberOfChoices
    precedence
    type
    choices
    choiceItems {
      items {
        id
        conceptID
        name
        price
        precedence
        choiceGroupID
        choiceGroupName
        deleted
        createdAt
        createdByID
        createdByName
        symphonyID
        multiLanguages
        definitionNumber
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    simphonyID
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateChoiceGroupsSubscriptionVariables,
  APITypes.OnCreateChoiceGroupsSubscription
>;
export const onUpdateChoiceGroups = /* GraphQL */ `subscription OnUpdateChoiceGroups(
  $filter: ModelSubscriptionChoiceGroupsFilterInput
) {
  onUpdateChoiceGroups(filter: $filter) {
    id
    conceptID
    name
    required
    maxNumberOfChoices
    minNumberOfChoices
    precedence
    type
    choices
    choiceItems {
      items {
        id
        conceptID
        name
        price
        precedence
        choiceGroupID
        choiceGroupName
        deleted
        createdAt
        createdByID
        createdByName
        symphonyID
        multiLanguages
        definitionNumber
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    simphonyID
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateChoiceGroupsSubscriptionVariables,
  APITypes.OnUpdateChoiceGroupsSubscription
>;
export const onDeleteChoiceGroups = /* GraphQL */ `subscription OnDeleteChoiceGroups(
  $filter: ModelSubscriptionChoiceGroupsFilterInput
) {
  onDeleteChoiceGroups(filter: $filter) {
    id
    conceptID
    name
    required
    maxNumberOfChoices
    minNumberOfChoices
    precedence
    type
    choices
    choiceItems {
      items {
        id
        conceptID
        name
        price
        precedence
        choiceGroupID
        choiceGroupName
        deleted
        createdAt
        createdByID
        createdByName
        symphonyID
        multiLanguages
        definitionNumber
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    simphonyID
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteChoiceGroupsSubscriptionVariables,
  APITypes.OnDeleteChoiceGroupsSubscription
>;
export const onCreateChoice = /* GraphQL */ `subscription OnCreateChoice($filter: ModelSubscriptionChoiceFilterInput) {
  onCreateChoice(filter: $filter) {
    id
    conceptID
    name
    price
    precedence
    choiceGroupID
    choiceGroupName
    deleted
    createdAt
    createdByID
    createdByName
    symphonyID
    multiLanguages
    definitionNumber
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateChoiceSubscriptionVariables,
  APITypes.OnCreateChoiceSubscription
>;
export const onUpdateChoice = /* GraphQL */ `subscription OnUpdateChoice($filter: ModelSubscriptionChoiceFilterInput) {
  onUpdateChoice(filter: $filter) {
    id
    conceptID
    name
    price
    precedence
    choiceGroupID
    choiceGroupName
    deleted
    createdAt
    createdByID
    createdByName
    symphonyID
    multiLanguages
    definitionNumber
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateChoiceSubscriptionVariables,
  APITypes.OnUpdateChoiceSubscription
>;
export const onDeleteChoice = /* GraphQL */ `subscription OnDeleteChoice($filter: ModelSubscriptionChoiceFilterInput) {
  onDeleteChoice(filter: $filter) {
    id
    conceptID
    name
    price
    precedence
    choiceGroupID
    choiceGroupName
    deleted
    createdAt
    createdByID
    createdByName
    symphonyID
    multiLanguages
    definitionNumber
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteChoiceSubscriptionVariables,
  APITypes.OnDeleteChoiceSubscription
>;
export const onCreateCart = /* GraphQL */ `subscription OnCreateCart($filter: ModelSubscriptionCartFilterInput) {
  onCreateCart(filter: $filter) {
    id
    conceptID
    userID
    orderedItems {
      menuItemID
      symphonyID
      preparationAreaID
      name
      quantity
      choiceIDs
      choiceNames
      choiceSymphonyID
      extraChoiceIDs
      extraChoiceSymphonyID
      price
      priceName
      priceID
      priceMultiLanguages
      multiLanguages
      notes
      image
      comboItemID
      isLoyalty
      __typename
    }
    specialRequest
    deliveryFee
    subTotal
    discount
    subTaxTotal
    totalPrice
    deleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCartSubscriptionVariables,
  APITypes.OnCreateCartSubscription
>;
export const onUpdateCart = /* GraphQL */ `subscription OnUpdateCart($filter: ModelSubscriptionCartFilterInput) {
  onUpdateCart(filter: $filter) {
    id
    conceptID
    userID
    orderedItems {
      menuItemID
      symphonyID
      preparationAreaID
      name
      quantity
      choiceIDs
      choiceNames
      choiceSymphonyID
      extraChoiceIDs
      extraChoiceSymphonyID
      price
      priceName
      priceID
      priceMultiLanguages
      multiLanguages
      notes
      image
      comboItemID
      isLoyalty
      __typename
    }
    specialRequest
    deliveryFee
    subTotal
    discount
    subTaxTotal
    totalPrice
    deleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCartSubscriptionVariables,
  APITypes.OnUpdateCartSubscription
>;
export const onDeleteCart = /* GraphQL */ `subscription OnDeleteCart($filter: ModelSubscriptionCartFilterInput) {
  onDeleteCart(filter: $filter) {
    id
    conceptID
    userID
    orderedItems {
      menuItemID
      symphonyID
      preparationAreaID
      name
      quantity
      choiceIDs
      choiceNames
      choiceSymphonyID
      extraChoiceIDs
      extraChoiceSymphonyID
      price
      priceName
      priceID
      priceMultiLanguages
      multiLanguages
      notes
      image
      comboItemID
      isLoyalty
      __typename
    }
    specialRequest
    deliveryFee
    subTotal
    discount
    subTaxTotal
    totalPrice
    deleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCartSubscriptionVariables,
  APITypes.OnDeleteCartSubscription
>;
export const onCreateAdminStatus = /* GraphQL */ `subscription OnCreateAdminStatus(
  $filter: ModelSubscriptionAdminStatusFilterInput
) {
  onCreateAdminStatus(filter: $filter) {
    id
    sub
    accountID
    name
    averageCallTime
    numberOfCalls
    idelDuration
    activeDuration
    awayDuration
    lastCallTime
    agentBarAverageCallTime
    agentBarNumberOfCalls
    type
    waiterStatus
    totalAverageRatio
    performancePerDay
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAdminStatusSubscriptionVariables,
  APITypes.OnCreateAdminStatusSubscription
>;
export const onUpdateAdminStatus = /* GraphQL */ `subscription OnUpdateAdminStatus(
  $filter: ModelSubscriptionAdminStatusFilterInput
) {
  onUpdateAdminStatus(filter: $filter) {
    id
    sub
    accountID
    name
    averageCallTime
    numberOfCalls
    idelDuration
    activeDuration
    awayDuration
    lastCallTime
    agentBarAverageCallTime
    agentBarNumberOfCalls
    type
    waiterStatus
    totalAverageRatio
    performancePerDay
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAdminStatusSubscriptionVariables,
  APITypes.OnUpdateAdminStatusSubscription
>;
export const onDeleteAdminStatus = /* GraphQL */ `subscription OnDeleteAdminStatus(
  $filter: ModelSubscriptionAdminStatusFilterInput
) {
  onDeleteAdminStatus(filter: $filter) {
    id
    sub
    accountID
    name
    averageCallTime
    numberOfCalls
    idelDuration
    activeDuration
    awayDuration
    lastCallTime
    agentBarAverageCallTime
    agentBarNumberOfCalls
    type
    waiterStatus
    totalAverageRatio
    performancePerDay
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAdminStatusSubscriptionVariables,
  APITypes.OnDeleteAdminStatusSubscription
>;
export const onCreateOnlineOrder = /* GraphQL */ `subscription OnCreateOnlineOrder(
  $filter: ModelSubscriptionOnlineOrderFilterInput
) {
  onCreateOnlineOrder(filter: $filter) {
    id
    conceptID
    statusID
    status
    reason
    orderType
    channel
    userID
    userName
    userPhoneNumber
    dispatcherID
    dispatcherName
    cartID
    tenderId
    employeeId
    rvcId
    checkNumber
    totalAmount
    discountedValue
    discountedPercentage
    subTotal
    subTaxTotal
    tax
    deliveryFee
    orderTime
    executionArn
    specialRequest
    orderAddress
    orderedItems {
      menuItemID
      symphonyID
      preparationAreaID
      name
      quantity
      choiceIDs
      choiceNames
      choiceSymphonyID
      extraChoiceIDs
      extraChoiceSymphonyID
      price
      priceName
      priceID
      priceMultiLanguages
      multiLanguages
      notes
      image
      comboItemID
      isLoyalty
      __typename
    }
    checkRef
    discountID
    amount_cents
    transactionID
    paymentStatus
    paymentType
    referenceId
    cardHolderNumber
    cardHolderName
    cardSchemeName
    deleted
    acceptedByWaiterID
    acceptedByWaiterName
    deliveredByWaiterID
    tableID
    tableName
    shift
    parentOrder
    preparationAreaID
    childrenStatus
    createdAt
    acceptedByWaiterAt
    sentToKitchenAt
    acceptedByKitchenAt
    readyAt
    outForDeliveryAt
    deliveredAt
    promoCodeID
    promoCodeName
    promoCodeAppliedTo
    reviewID
    review {
      id
      accountID
      bookingID
      guestName
      guestId
      reason
      value
      userID
      orderID
      rate
      satisfaction
      thoughts
      itemReviews {
        itemID
        rate
        satisfaction
        multiLanguages
        __typename
      }
      photos
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    simphEmpID
    createdByID
    createdByName
    tmpField
    updatedByID
    updatedByName
    testFieldNotUsed
    tenderID
    test
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateOnlineOrderSubscriptionVariables,
  APITypes.OnCreateOnlineOrderSubscription
>;
export const onUpdateOnlineOrder = /* GraphQL */ `subscription OnUpdateOnlineOrder(
  $filter: ModelSubscriptionOnlineOrderFilterInput
) {
  onUpdateOnlineOrder(filter: $filter) {
    id
    conceptID
    statusID
    status
    reason
    orderType
    channel
    userID
    userName
    userPhoneNumber
    dispatcherID
    dispatcherName
    cartID
    tenderId
    employeeId
    rvcId
    checkNumber
    totalAmount
    discountedValue
    discountedPercentage
    subTotal
    subTaxTotal
    tax
    deliveryFee
    orderTime
    executionArn
    specialRequest
    orderAddress
    orderedItems {
      menuItemID
      symphonyID
      preparationAreaID
      name
      quantity
      choiceIDs
      choiceNames
      choiceSymphonyID
      extraChoiceIDs
      extraChoiceSymphonyID
      price
      priceName
      priceID
      priceMultiLanguages
      multiLanguages
      notes
      image
      comboItemID
      isLoyalty
      __typename
    }
    checkRef
    discountID
    amount_cents
    transactionID
    paymentStatus
    paymentType
    referenceId
    cardHolderNumber
    cardHolderName
    cardSchemeName
    deleted
    acceptedByWaiterID
    acceptedByWaiterName
    deliveredByWaiterID
    tableID
    tableName
    shift
    parentOrder
    preparationAreaID
    childrenStatus
    createdAt
    acceptedByWaiterAt
    sentToKitchenAt
    acceptedByKitchenAt
    readyAt
    outForDeliveryAt
    deliveredAt
    promoCodeID
    promoCodeName
    promoCodeAppliedTo
    reviewID
    review {
      id
      accountID
      bookingID
      guestName
      guestId
      reason
      value
      userID
      orderID
      rate
      satisfaction
      thoughts
      itemReviews {
        itemID
        rate
        satisfaction
        multiLanguages
        __typename
      }
      photos
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    simphEmpID
    createdByID
    createdByName
    tmpField
    updatedByID
    updatedByName
    testFieldNotUsed
    tenderID
    test
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateOnlineOrderSubscriptionVariables,
  APITypes.OnUpdateOnlineOrderSubscription
>;
export const onDeleteOnlineOrder = /* GraphQL */ `subscription OnDeleteOnlineOrder(
  $filter: ModelSubscriptionOnlineOrderFilterInput
) {
  onDeleteOnlineOrder(filter: $filter) {
    id
    conceptID
    statusID
    status
    reason
    orderType
    channel
    userID
    userName
    userPhoneNumber
    dispatcherID
    dispatcherName
    cartID
    tenderId
    employeeId
    rvcId
    checkNumber
    totalAmount
    discountedValue
    discountedPercentage
    subTotal
    subTaxTotal
    tax
    deliveryFee
    orderTime
    executionArn
    specialRequest
    orderAddress
    orderedItems {
      menuItemID
      symphonyID
      preparationAreaID
      name
      quantity
      choiceIDs
      choiceNames
      choiceSymphonyID
      extraChoiceIDs
      extraChoiceSymphonyID
      price
      priceName
      priceID
      priceMultiLanguages
      multiLanguages
      notes
      image
      comboItemID
      isLoyalty
      __typename
    }
    checkRef
    discountID
    amount_cents
    transactionID
    paymentStatus
    paymentType
    referenceId
    cardHolderNumber
    cardHolderName
    cardSchemeName
    deleted
    acceptedByWaiterID
    acceptedByWaiterName
    deliveredByWaiterID
    tableID
    tableName
    shift
    parentOrder
    preparationAreaID
    childrenStatus
    createdAt
    acceptedByWaiterAt
    sentToKitchenAt
    acceptedByKitchenAt
    readyAt
    outForDeliveryAt
    deliveredAt
    promoCodeID
    promoCodeName
    promoCodeAppliedTo
    reviewID
    review {
      id
      accountID
      bookingID
      guestName
      guestId
      reason
      value
      userID
      orderID
      rate
      satisfaction
      thoughts
      itemReviews {
        itemID
        rate
        satisfaction
        multiLanguages
        __typename
      }
      photos
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    simphEmpID
    createdByID
    createdByName
    tmpField
    updatedByID
    updatedByName
    testFieldNotUsed
    tenderID
    test
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteOnlineOrderSubscriptionVariables,
  APITypes.OnDeleteOnlineOrderSubscription
>;
export const onCreateOrderStatus = /* GraphQL */ `subscription OnCreateOrderStatus(
  $filter: ModelSubscriptionOrderStatusFilterInput
) {
  onCreateOrderStatus(filter: $filter) {
    id
    conceptID
    name
    color
    precedence
    accessibleStatus
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateOrderStatusSubscriptionVariables,
  APITypes.OnCreateOrderStatusSubscription
>;
export const onUpdateOrderStatus = /* GraphQL */ `subscription OnUpdateOrderStatus(
  $filter: ModelSubscriptionOrderStatusFilterInput
) {
  onUpdateOrderStatus(filter: $filter) {
    id
    conceptID
    name
    color
    precedence
    accessibleStatus
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateOrderStatusSubscriptionVariables,
  APITypes.OnUpdateOrderStatusSubscription
>;
export const onDeleteOrderStatus = /* GraphQL */ `subscription OnDeleteOrderStatus(
  $filter: ModelSubscriptionOrderStatusFilterInput
) {
  onDeleteOrderStatus(filter: $filter) {
    id
    conceptID
    name
    color
    precedence
    accessibleStatus
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteOrderStatusSubscriptionVariables,
  APITypes.OnDeleteOrderStatusSubscription
>;
export const onCreateDiscountCoupon = /* GraphQL */ `subscription OnCreateDiscountCoupon(
  $filter: ModelSubscriptionDiscountCouponFilterInput
) {
  onCreateDiscountCoupon(filter: $filter) {
    id
    discountPercent
    usedBy
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateDiscountCouponSubscriptionVariables,
  APITypes.OnCreateDiscountCouponSubscription
>;
export const onUpdateDiscountCoupon = /* GraphQL */ `subscription OnUpdateDiscountCoupon(
  $filter: ModelSubscriptionDiscountCouponFilterInput
) {
  onUpdateDiscountCoupon(filter: $filter) {
    id
    discountPercent
    usedBy
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateDiscountCouponSubscriptionVariables,
  APITypes.OnUpdateDiscountCouponSubscription
>;
export const onDeleteDiscountCoupon = /* GraphQL */ `subscription OnDeleteDiscountCoupon(
  $filter: ModelSubscriptionDiscountCouponFilterInput
) {
  onDeleteDiscountCoupon(filter: $filter) {
    id
    discountPercent
    usedBy
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteDiscountCouponSubscriptionVariables,
  APITypes.OnDeleteDiscountCouponSubscription
>;
export const onCreateZone = /* GraphQL */ `subscription OnCreateZone($filter: ModelSubscriptionZoneFilterInput) {
  onCreateZone(filter: $filter) {
    id
    conceptID
    location
    deliveryFee
    concepts {
      id
      deliveryFee
      __typename
    }
    polygon
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateZoneSubscriptionVariables,
  APITypes.OnCreateZoneSubscription
>;
export const onUpdateZone = /* GraphQL */ `subscription OnUpdateZone($filter: ModelSubscriptionZoneFilterInput) {
  onUpdateZone(filter: $filter) {
    id
    conceptID
    location
    deliveryFee
    concepts {
      id
      deliveryFee
      __typename
    }
    polygon
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateZoneSubscriptionVariables,
  APITypes.OnUpdateZoneSubscription
>;
export const onDeleteZone = /* GraphQL */ `subscription OnDeleteZone($filter: ModelSubscriptionZoneFilterInput) {
  onDeleteZone(filter: $filter) {
    id
    conceptID
    location
    deliveryFee
    concepts {
      id
      deliveryFee
      __typename
    }
    polygon
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteZoneSubscriptionVariables,
  APITypes.OnDeleteZoneSubscription
>;
export const onCreateUserIP = /* GraphQL */ `subscription OnCreateUserIP($filter: ModelSubscriptionUserIPFilterInput) {
  onCreateUserIP(filter: $filter) {
    id
    ip
    date
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserIPSubscriptionVariables,
  APITypes.OnCreateUserIPSubscription
>;
export const onUpdateUserIP = /* GraphQL */ `subscription OnUpdateUserIP($filter: ModelSubscriptionUserIPFilterInput) {
  onUpdateUserIP(filter: $filter) {
    id
    ip
    date
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserIPSubscriptionVariables,
  APITypes.OnUpdateUserIPSubscription
>;
export const onDeleteUserIP = /* GraphQL */ `subscription OnDeleteUserIP($filter: ModelSubscriptionUserIPFilterInput) {
  onDeleteUserIP(filter: $filter) {
    id
    ip
    date
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserIPSubscriptionVariables,
  APITypes.OnDeleteUserIPSubscription
>;
export const onCreateMainCategory = /* GraphQL */ `subscription OnCreateMainCategory(
  $filter: ModelSubscriptionMainCategoryFilterInput
) {
  onCreateMainCategory(filter: $filter) {
    id
    conceptID
    name
    precedence
    openTime
    closeTime
    guestView
    guestOrder
    staffOrder
    image
    menuPDF
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMainCategorySubscriptionVariables,
  APITypes.OnCreateMainCategorySubscription
>;
export const onUpdateMainCategory = /* GraphQL */ `subscription OnUpdateMainCategory(
  $filter: ModelSubscriptionMainCategoryFilterInput
) {
  onUpdateMainCategory(filter: $filter) {
    id
    conceptID
    name
    precedence
    openTime
    closeTime
    guestView
    guestOrder
    staffOrder
    image
    menuPDF
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMainCategorySubscriptionVariables,
  APITypes.OnUpdateMainCategorySubscription
>;
export const onDeleteMainCategory = /* GraphQL */ `subscription OnDeleteMainCategory(
  $filter: ModelSubscriptionMainCategoryFilterInput
) {
  onDeleteMainCategory(filter: $filter) {
    id
    conceptID
    name
    precedence
    openTime
    closeTime
    guestView
    guestOrder
    staffOrder
    image
    menuPDF
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMainCategorySubscriptionVariables,
  APITypes.OnDeleteMainCategorySubscription
>;
export const onCreateSubCategory = /* GraphQL */ `subscription OnCreateSubCategory(
  $filter: ModelSubscriptionSubCategoryFilterInput
) {
  onCreateSubCategory(filter: $filter) {
    id
    conceptID
    name
    precedence
    openTime
    closeTime
    mainCategoryID
    guestView
    guestOrder
    staffOrder
    image
    menuPDF
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateSubCategorySubscriptionVariables,
  APITypes.OnCreateSubCategorySubscription
>;
export const onUpdateSubCategory = /* GraphQL */ `subscription OnUpdateSubCategory(
  $filter: ModelSubscriptionSubCategoryFilterInput
) {
  onUpdateSubCategory(filter: $filter) {
    id
    conceptID
    name
    precedence
    openTime
    closeTime
    mainCategoryID
    guestView
    guestOrder
    staffOrder
    image
    menuPDF
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSubCategorySubscriptionVariables,
  APITypes.OnUpdateSubCategorySubscription
>;
export const onDeleteSubCategory = /* GraphQL */ `subscription OnDeleteSubCategory(
  $filter: ModelSubscriptionSubCategoryFilterInput
) {
  onDeleteSubCategory(filter: $filter) {
    id
    conceptID
    name
    precedence
    openTime
    closeTime
    mainCategoryID
    guestView
    guestOrder
    staffOrder
    image
    menuPDF
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteSubCategorySubscriptionVariables,
  APITypes.OnDeleteSubCategorySubscription
>;
export const onCreateShift = /* GraphQL */ `subscription OnCreateShift($filter: ModelSubscriptionShiftFilterInput) {
  onCreateShift(filter: $filter) {
    id
    accountID
    name
    precedence
    startTime
    endTime
    users
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateShiftSubscriptionVariables,
  APITypes.OnCreateShiftSubscription
>;
export const onUpdateShift = /* GraphQL */ `subscription OnUpdateShift($filter: ModelSubscriptionShiftFilterInput) {
  onUpdateShift(filter: $filter) {
    id
    accountID
    name
    precedence
    startTime
    endTime
    users
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateShiftSubscriptionVariables,
  APITypes.OnUpdateShiftSubscription
>;
export const onDeleteShift = /* GraphQL */ `subscription OnDeleteShift($filter: ModelSubscriptionShiftFilterInput) {
  onDeleteShift(filter: $filter) {
    id
    accountID
    name
    precedence
    startTime
    endTime
    users
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteShiftSubscriptionVariables,
  APITypes.OnDeleteShiftSubscription
>;
export const onCreateChannel = /* GraphQL */ `subscription OnCreateChannel($filter: ModelSubscriptionChannelFilterInput) {
  onCreateChannel(filter: $filter) {
    id
    name
    description
    numberOfOrders
    isActive
    multiLanguages
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateChannelSubscriptionVariables,
  APITypes.OnCreateChannelSubscription
>;
export const onUpdateChannel = /* GraphQL */ `subscription OnUpdateChannel($filter: ModelSubscriptionChannelFilterInput) {
  onUpdateChannel(filter: $filter) {
    id
    name
    description
    numberOfOrders
    isActive
    multiLanguages
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateChannelSubscriptionVariables,
  APITypes.OnUpdateChannelSubscription
>;
export const onDeleteChannel = /* GraphQL */ `subscription OnDeleteChannel($filter: ModelSubscriptionChannelFilterInput) {
  onDeleteChannel(filter: $filter) {
    id
    name
    description
    numberOfOrders
    isActive
    multiLanguages
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteChannelSubscriptionVariables,
  APITypes.OnDeleteChannelSubscription
>;
export const onCreatePromocode = /* GraphQL */ `subscription OnCreatePromocode($filter: ModelSubscriptionPromocodeFilterInput) {
  onCreatePromocode(filter: $filter) {
    id
    name
    redemptions
    status
    startDate
    endDate
    appliesTo
    minOrderReq
    minOrderVal
    minOrderQty
    tenderReq
    discountVal
    discountType
    discountLimit
    quota
    quotaUsed
    totalRevenue
    appliedDiscountedVal
    usePerCustomer
    channelReq
    customerEligibility
    customerReq
    spendingExceeds
    spendingBelow
    orderSinceDate
    orderFromDate
    orderToDate
    isDisplayed
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePromocodeSubscriptionVariables,
  APITypes.OnCreatePromocodeSubscription
>;
export const onUpdatePromocode = /* GraphQL */ `subscription OnUpdatePromocode($filter: ModelSubscriptionPromocodeFilterInput) {
  onUpdatePromocode(filter: $filter) {
    id
    name
    redemptions
    status
    startDate
    endDate
    appliesTo
    minOrderReq
    minOrderVal
    minOrderQty
    tenderReq
    discountVal
    discountType
    discountLimit
    quota
    quotaUsed
    totalRevenue
    appliedDiscountedVal
    usePerCustomer
    channelReq
    customerEligibility
    customerReq
    spendingExceeds
    spendingBelow
    orderSinceDate
    orderFromDate
    orderToDate
    isDisplayed
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePromocodeSubscriptionVariables,
  APITypes.OnUpdatePromocodeSubscription
>;
export const onDeletePromocode = /* GraphQL */ `subscription OnDeletePromocode($filter: ModelSubscriptionPromocodeFilterInput) {
  onDeletePromocode(filter: $filter) {
    id
    name
    redemptions
    status
    startDate
    endDate
    appliesTo
    minOrderReq
    minOrderVal
    minOrderQty
    tenderReq
    discountVal
    discountType
    discountLimit
    quota
    quotaUsed
    totalRevenue
    appliedDiscountedVal
    usePerCustomer
    channelReq
    customerEligibility
    customerReq
    spendingExceeds
    spendingBelow
    orderSinceDate
    orderFromDate
    orderToDate
    isDisplayed
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePromocodeSubscriptionVariables,
  APITypes.OnDeletePromocodeSubscription
>;
export const onCreatePromocodeDashboard = /* GraphQL */ `subscription OnCreatePromocodeDashboard(
  $filter: ModelSubscriptionPromocodeDashboardFilterInput
) {
  onCreatePromocodeDashboard(filter: $filter) {
    id
    conceptID
    totalRevenue
    totalRedemptions
    totalDiscount
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePromocodeDashboardSubscriptionVariables,
  APITypes.OnCreatePromocodeDashboardSubscription
>;
export const onUpdatePromocodeDashboard = /* GraphQL */ `subscription OnUpdatePromocodeDashboard(
  $filter: ModelSubscriptionPromocodeDashboardFilterInput
) {
  onUpdatePromocodeDashboard(filter: $filter) {
    id
    conceptID
    totalRevenue
    totalRedemptions
    totalDiscount
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePromocodeDashboardSubscriptionVariables,
  APITypes.OnUpdatePromocodeDashboardSubscription
>;
export const onDeletePromocodeDashboard = /* GraphQL */ `subscription OnDeletePromocodeDashboard(
  $filter: ModelSubscriptionPromocodeDashboardFilterInput
) {
  onDeletePromocodeDashboard(filter: $filter) {
    id
    conceptID
    totalRevenue
    totalRedemptions
    totalDiscount
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePromocodeDashboardSubscriptionVariables,
  APITypes.OnDeletePromocodeDashboardSubscription
>;
export const onCreateLoyaltyItem = /* GraphQL */ `subscription OnCreateLoyaltyItem(
  $filter: ModelSubscriptionLoyaltyItemFilterInput
) {
  onCreateLoyaltyItem(filter: $filter) {
    id
    name
    description
    startDate
    endDate
    points
    price
    redemptionPerUser
    image
    simphonyID
    multiLanguages
    isActive
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateLoyaltyItemSubscriptionVariables,
  APITypes.OnCreateLoyaltyItemSubscription
>;
export const onUpdateLoyaltyItem = /* GraphQL */ `subscription OnUpdateLoyaltyItem(
  $filter: ModelSubscriptionLoyaltyItemFilterInput
) {
  onUpdateLoyaltyItem(filter: $filter) {
    id
    name
    description
    startDate
    endDate
    points
    price
    redemptionPerUser
    image
    simphonyID
    multiLanguages
    isActive
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateLoyaltyItemSubscriptionVariables,
  APITypes.OnUpdateLoyaltyItemSubscription
>;
export const onDeleteLoyaltyItem = /* GraphQL */ `subscription OnDeleteLoyaltyItem(
  $filter: ModelSubscriptionLoyaltyItemFilterInput
) {
  onDeleteLoyaltyItem(filter: $filter) {
    id
    name
    description
    startDate
    endDate
    points
    price
    redemptionPerUser
    image
    simphonyID
    multiLanguages
    isActive
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteLoyaltyItemSubscriptionVariables,
  APITypes.OnDeleteLoyaltyItemSubscription
>;
export const onCreateLoyaltyTransactions = /* GraphQL */ `subscription OnCreateLoyaltyTransactions(
  $filter: ModelSubscriptionLoyaltyTransactionsFilterInput
) {
  onCreateLoyaltyTransactions(filter: $filter) {
    id
    conceptID
    conceptName
    orderID
    orderType
    userID
    userName
    type
    points
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateLoyaltyTransactionsSubscriptionVariables,
  APITypes.OnCreateLoyaltyTransactionsSubscription
>;
export const onUpdateLoyaltyTransactions = /* GraphQL */ `subscription OnUpdateLoyaltyTransactions(
  $filter: ModelSubscriptionLoyaltyTransactionsFilterInput
) {
  onUpdateLoyaltyTransactions(filter: $filter) {
    id
    conceptID
    conceptName
    orderID
    orderType
    userID
    userName
    type
    points
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateLoyaltyTransactionsSubscriptionVariables,
  APITypes.OnUpdateLoyaltyTransactionsSubscription
>;
export const onDeleteLoyaltyTransactions = /* GraphQL */ `subscription OnDeleteLoyaltyTransactions(
  $filter: ModelSubscriptionLoyaltyTransactionsFilterInput
) {
  onDeleteLoyaltyTransactions(filter: $filter) {
    id
    conceptID
    conceptName
    orderID
    orderType
    userID
    userName
    type
    points
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteLoyaltyTransactionsSubscriptionVariables,
  APITypes.OnDeleteLoyaltyTransactionsSubscription
>;
export const onCreateOTP = /* GraphQL */ `subscription OnCreateOTP($filter: ModelSubscriptionOTPFilterInput) {
  onCreateOTP(filter: $filter) {
    id
    OTP
    redeemed
    deleted
    userID
    conceptID
    loyaltyItemID
    loyaltyItem {
      id
      name
      description
      startDate
      endDate
      points
      price
      redemptionPerUser
      image
      simphonyID
      multiLanguages
      isActive
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    user {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      pictureCrop
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      spending
      prevOrderDate
      lastOrderDate
      numOfOrders
      promocodes {
        name
        promocodeId
        canBeUsed
        discountType
        discountVal
        discountLimit
        isDisplayed
        __typename
      }
      mostOrderedItems
      points
      deleted
      createdAt
      createdByID
      createdByName
      cognitoUsername
      cognitoSub
      updated
      sub
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    concept {
      id
      accountID
      name
      kiosks
      merchantID
      merchantUsername
      merchantPassword
      merchantIntegrationNumber
      merchantAPIKey
      version
      simphonyServerBaseURL
      simphonyShortOrgName
      simphonyLocRef
      simphonyToken
      revenueCenterID
      employeeID
      tenderID
      tenders {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptTendersId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orderType {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptOrderTypeId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      discountID
      discountPercentID
      discountAmountID
      useServiceCharge
      serviceChargeId
      specialRequestID
      addressID
      simphonyAuthClientId
      simphonyAuthUsername
      simphonyAuthPassword
      simphonyAuthBaseURL
      orderWay {
        name
        simphonyID
        priceSequence
        orderTypeWayPayment {
          name
          simphonyID
          __typename
        }
        __typename
      }
      orderTypeDineIn
      orderTypeTakeAway
      orderTypeDelivery
      simphonyTakeAwayRvc
      simphonyDeliveryRvc
      exportInterval
      deleted
      createdAt
      createdByID
      createdByName
      foodicsToken
      enableTableFoodics
      image
      logo
      album
      type
      rating
      location
      description
      areas
      seatTypes
      needAssistance
      spaBooking
      showerBooking
      cigarMenu
      precedence
      isPinRequired
      viewOnly
      openTime
      closeTime
      varifyForAmount
      varifyFirstOrder
      varifyNewAddress
      sliderImages
      receiveWaiters
      WaiterNotificationOption
      applyLoyalty
      rewardPoints
      moneySpent
      afterVat
      afterDeliveryFee
      beforePromoCode
      pointsCalcWithPromoCode
      isBusy
      allowOnlineOrdering
      minOrderVal
      vatPercentage
      serviceChargePercentage
      addDeliveryToVat
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateOTPSubscriptionVariables,
  APITypes.OnCreateOTPSubscription
>;
export const onUpdateOTP = /* GraphQL */ `subscription OnUpdateOTP($filter: ModelSubscriptionOTPFilterInput) {
  onUpdateOTP(filter: $filter) {
    id
    OTP
    redeemed
    deleted
    userID
    conceptID
    loyaltyItemID
    loyaltyItem {
      id
      name
      description
      startDate
      endDate
      points
      price
      redemptionPerUser
      image
      simphonyID
      multiLanguages
      isActive
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    user {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      pictureCrop
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      spending
      prevOrderDate
      lastOrderDate
      numOfOrders
      promocodes {
        name
        promocodeId
        canBeUsed
        discountType
        discountVal
        discountLimit
        isDisplayed
        __typename
      }
      mostOrderedItems
      points
      deleted
      createdAt
      createdByID
      createdByName
      cognitoUsername
      cognitoSub
      updated
      sub
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    concept {
      id
      accountID
      name
      kiosks
      merchantID
      merchantUsername
      merchantPassword
      merchantIntegrationNumber
      merchantAPIKey
      version
      simphonyServerBaseURL
      simphonyShortOrgName
      simphonyLocRef
      simphonyToken
      revenueCenterID
      employeeID
      tenderID
      tenders {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptTendersId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orderType {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptOrderTypeId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      discountID
      discountPercentID
      discountAmountID
      useServiceCharge
      serviceChargeId
      specialRequestID
      addressID
      simphonyAuthClientId
      simphonyAuthUsername
      simphonyAuthPassword
      simphonyAuthBaseURL
      orderWay {
        name
        simphonyID
        priceSequence
        orderTypeWayPayment {
          name
          simphonyID
          __typename
        }
        __typename
      }
      orderTypeDineIn
      orderTypeTakeAway
      orderTypeDelivery
      simphonyTakeAwayRvc
      simphonyDeliveryRvc
      exportInterval
      deleted
      createdAt
      createdByID
      createdByName
      foodicsToken
      enableTableFoodics
      image
      logo
      album
      type
      rating
      location
      description
      areas
      seatTypes
      needAssistance
      spaBooking
      showerBooking
      cigarMenu
      precedence
      isPinRequired
      viewOnly
      openTime
      closeTime
      varifyForAmount
      varifyFirstOrder
      varifyNewAddress
      sliderImages
      receiveWaiters
      WaiterNotificationOption
      applyLoyalty
      rewardPoints
      moneySpent
      afterVat
      afterDeliveryFee
      beforePromoCode
      pointsCalcWithPromoCode
      isBusy
      allowOnlineOrdering
      minOrderVal
      vatPercentage
      serviceChargePercentage
      addDeliveryToVat
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateOTPSubscriptionVariables,
  APITypes.OnUpdateOTPSubscription
>;
export const onDeleteOTP = /* GraphQL */ `subscription OnDeleteOTP($filter: ModelSubscriptionOTPFilterInput) {
  onDeleteOTP(filter: $filter) {
    id
    OTP
    redeemed
    deleted
    userID
    conceptID
    loyaltyItemID
    loyaltyItem {
      id
      name
      description
      startDate
      endDate
      points
      price
      redemptionPerUser
      image
      simphonyID
      multiLanguages
      isActive
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    user {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      pictureCrop
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      spending
      prevOrderDate
      lastOrderDate
      numOfOrders
      promocodes {
        name
        promocodeId
        canBeUsed
        discountType
        discountVal
        discountLimit
        isDisplayed
        __typename
      }
      mostOrderedItems
      points
      deleted
      createdAt
      createdByID
      createdByName
      cognitoUsername
      cognitoSub
      updated
      sub
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    concept {
      id
      accountID
      name
      kiosks
      merchantID
      merchantUsername
      merchantPassword
      merchantIntegrationNumber
      merchantAPIKey
      version
      simphonyServerBaseURL
      simphonyShortOrgName
      simphonyLocRef
      simphonyToken
      revenueCenterID
      employeeID
      tenderID
      tenders {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptTendersId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orderType {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptOrderTypeId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      discountID
      discountPercentID
      discountAmountID
      useServiceCharge
      serviceChargeId
      specialRequestID
      addressID
      simphonyAuthClientId
      simphonyAuthUsername
      simphonyAuthPassword
      simphonyAuthBaseURL
      orderWay {
        name
        simphonyID
        priceSequence
        orderTypeWayPayment {
          name
          simphonyID
          __typename
        }
        __typename
      }
      orderTypeDineIn
      orderTypeTakeAway
      orderTypeDelivery
      simphonyTakeAwayRvc
      simphonyDeliveryRvc
      exportInterval
      deleted
      createdAt
      createdByID
      createdByName
      foodicsToken
      enableTableFoodics
      image
      logo
      album
      type
      rating
      location
      description
      areas
      seatTypes
      needAssistance
      spaBooking
      showerBooking
      cigarMenu
      precedence
      isPinRequired
      viewOnly
      openTime
      closeTime
      varifyForAmount
      varifyFirstOrder
      varifyNewAddress
      sliderImages
      receiveWaiters
      WaiterNotificationOption
      applyLoyalty
      rewardPoints
      moneySpent
      afterVat
      afterDeliveryFee
      beforePromoCode
      pointsCalcWithPromoCode
      isBusy
      allowOnlineOrdering
      minOrderVal
      vatPercentage
      serviceChargePercentage
      addDeliveryToVat
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteOTPSubscriptionVariables,
  APITypes.OnDeleteOTPSubscription
>;
