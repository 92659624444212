import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import EditNoteIcon from "@mui/icons-material/EditNote";
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import {
  OrderStatus,
  OrderTime,
  PromocodeAppliesTo,
  cloudinaryURL,
  simphonyURL,
} from "../../constants/Enums";
import {
  capitalization,
  getMenuItemById,
  numberTranslation,
  truncateToTwoDecimals,
} from "../../constants/helperFunction";
import { IRewardItem } from "../../constants/interfaces";
import {
  CartItem,
  Category,
  Concept,
  MenuItem as MenuItemType,
  Zone,
  ZoneConcept,
} from "../../models";
import { getCart } from "../../services/cartServices";
import {
  decrementOrRemoveItem,
  getCartItemsQuantity,
  getCartLoyaltyDiscount,
  getCartSubTotal,
  getCartTotalPrice,
  getLocalCart,
  mergeLocalAndOnlineCart,
  removeTypeNameFromItems,
  updateCartState,
  updateOfflineCart,
  updateOnlineCart,
} from "../../services/cartUtils";
import { fetchData } from "../../services/categoryPageUtils";
import {
  getAvailableConcepts,
  isPlaceOpen,
  sortConceptsASCByDeliveryFee,
} from "../../services/conceptUtils";
import createOnlineOrder from "../../services/createOrder";
import {
  getMenuItems,
  getUserByPhoneNumber,
} from "../../services/getOperation";
import { getLoyaltyItems } from "../../services/loyaltyServices";
import type { PaymentData } from "../../services/paymentServices";
import { createPaymentURL } from "../../services/paymentServices";
import {
  applyPromocode,
  validatePromocode,
} from "../../services/promocodeServices";
import { constructPromocodeErrorMessage } from "../../services/promocodeUtils";
import {
  setCartNotes,
  setCartSpecialRequest,
  setCartSubTotal,
  setCartTotal,
  setCurrentOrder,
  setDiscountedValue,
  setLoyaltyDiscount,
  setLogin,
  setOrder,
  setPromocode,
  setPromocodeName,
  setSelectedItem,
  setRewardItems,
} from "../../state/index";
import ContentLoader from "../contentLoader/ContentLoader";
import EmptyCart from "./EmtyCart";
import RedeemableItemsList from "./RedeemList";

function Cart() {
  const defaultImage = "../../assets/logo.svg";

  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const Reorderd = location?.state?.CartData;
  const ordersList = useSelector((state: any) => state.orders);
  const items: any[] = useSelector(
    (state: any) => state.cart.orderedItems || []
  );
  const cart = useSelector((state: any) => state.cart);
  const totalQuantity = useSelector((state: any) => state.cart.quantity || 0);
  const totalPrice = useSelector((state: any) => state.cart.total || 0);
  const discount = useSelector((state: any) => state.cart.discount || 0);
  const subTaxTotal = useSelector((state: any) => state.cart.subTaxTotal || 0);
  const subTotal = useSelector((state: any) => state.cart.subTotal || 0);
  const specialRequest = useSelector((state: any) => state.cart.specialRequest);
  const notes = useSelector((state: any) => state.cart.notes);
  const promocode = useSelector((state: any) => state.promocode);
  const discountedValue = useSelector((state: any) => state.discountedValue);
  //le.log({ totalPrice, subTotal, subTaxTotal, discountedValue });

  const promocodeName = useSelector((state: any) => state.promocodeName);
  const themeMode = useSelector((state: any) => state.mode);
  const user = useSelector((state: any) => state.user);
  const selectedAddress = useSelector((state: any) => state.selectedAddress);
  const zonesListing = useSelector((state: any) => state.zonesListing);
  const categories = useSelector(
    (state: any) => state.categoriesState.listing || []
  );
  const [fetchAgain, setFetchAgain] = useState(true);
  const selectedConcept = useSelector((state: any) => state.selectedConcept);
  const concept = useSelector((state: any) => state.concept);
  const channel = useSelector((state: any) => state.channel);
  // const selectedZone = useSelector((state: any) => state.selectedZone);
  const [deliveryFee, setDeliveryFee] = useState<any>(null);
  const [disableBtns, setDisableBtns] = useState<boolean>(false);
  const [canOrder, setCanOrder] = useState<boolean>(true);
  const [cartData, setCartData] = useState<any>({});
  const [warning, setWarning] = useState(false);
  const [success, setSuccess] = useState(false);
  // const [channel, setChannel] = useState("delivery");
  const [tender, setTender] = useState("Cash");
  const [disc, setDisc] = useState(0);
  const [message, setMessage] = useState("");
  const [promocodeErrorMessage, setPromocodeErrorMessage] = useState<
    string | undefined
  >();
  const [applyPromoCodeClicked, setApplyPromoCodeClicked] = useState(false);
  const [addPromoCodeSelected, setAddPromoCodeSelected] = useState(false);
  const [hasLoyaltyItem, setHasLoyaltyItem] = useState(false);
  const [currentPromocodeIdx, setCurrentPromocodeIdx] = useState(0);
  const [userPromocodes, setUserPromocodes] = useState<any[]>([]);
  const [specialRequestState, setSpecialRequestState] = useState(
    specialRequest ? specialRequest : ""
  );
  const [initialSubTotal, setInitialSubTotal] = useState(cart.subTaxTotal);
  const [selectedAddressZone, setSelectedAddressZone] = useState<Zone>();
  const [taxes, setTaxes] = useState("0");
  const result: any = [];
  items.forEach((item, index) => {
    result.push(item.quantity);
  });
  const [debouncedState, setDebouncedState] = useState<any[]>(result);
  //le.log({ debouncedState });
  const promocodeNameRef = useRef("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // This must be changed after login is fixed to get the real userID
  function formatDateToYYYYMMDDHHMMSS(date: any) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    const hour = String(date.getHours()).padStart(2, "0");
    const minute = String(date.getMinutes()).padStart(2, "0");
    const second = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  }
  async function createOrderRequest(
    tender: any,
    orderItems: any,
    conceptID: any,
    orderType: any,
    operationID: any,
    simphony: boolean
  ) {
    if (!simphony) return;
    let simphonyItems: any = [];

    try {
      const orderData: any = {
        operationID: operationID,
        employeeID: 90004,
        orderType: orderType,
        RevenueCenter: 51,
        TenderType: tender,
        conceptID: conceptID,
      };

      for (let item of orderItems) {
        let simphonyItem = {
          symphonyID: item.symphonyID,
          choiceSymphonyID: item.choiceSymphonyID,
          quantity: item.quantity,
        };
        simphonyItems.push(simphonyItem);
      }
      orderData.simphonyItems = simphonyItems;

      // Create Order request
      const response = await fetch(simphonyURL, {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(orderData),
      });

      const createOrderResponse = await response.json();
      var isSuccess =
        createOrderResponse.pTotalsResponseEx.operationalResult.success;
    } catch (e) {
      //le.error({ createOrderError: e });
    }
  }

  const setDefaultNotes = (cartItems: any) => {
    const updatedNotes = cartItems.map((item: any) => item.notes || "");
    dispatch(setCartNotes(updatedNotes));
  };

  useEffect(() => {
    if (items.length > 0) {
      setDefaultNotes(items);
    }

    if (promocode) {
      dispatch(setPromocode(null));
      dispatch(setDiscountedValue(null));
    }
  }, [items]);

  const handleWarningClose = () => {
    setWarning(false);
  };
  const handleSuccessClose = () => {
    setSuccess(false);
  };

  const handleIncrement = async (index: number) => {
    if (!selectedConcept) return;
    setDisableBtns(true);
    const updatedItems = [...items];
    const updatedItem = {
      ...updatedItems[index],
      quantity: updatedItems[index].quantity + 1,
      notes: notes[index],
    };
    updatedItems[index] = updatedItem;
    const newQuantity = totalQuantity + 1;
    const newSubTaxTotal = subTaxTotal + updatedItem.price;
    if (user) {
      await updateOnlineCart(
        updatedItems,
        deliveryFee,
        user.id,
        selectedConcept.id,
        selectedConcept.vatPercentage,
        selectedConcept.serviceChargePercentage,
        selectedConcept.addDeliveryToVat,
        channel
      );
    } else {
      updateOfflineCart(
        updatedItems,
        deliveryFee,
        selectedConcept.id,
        selectedConcept.vatPercentage,
        selectedConcept.serviceChargePercentage,
        selectedConcept.addDeliveryToVat,
        channel
      );
    }
    updateCartState(
      updatedItems,
      newQuantity,
      newSubTaxTotal,
      deliveryFee,
      dispatch,
      selectedConcept.vatPercentage,
      selectedConcept.serviceChargePercentage,
      selectedConcept.addDeliveryToVat,
      channel
    );
    setDisableBtns(false);

    if (promocode) {
      dispatch(setPromocode(null));
      dispatch(setDiscountedValue(null));
    }
  };

  console.log({ userPromocodes });
  console.log({ concept });

  const handleDecrement = async (index: number) => {
    if (!selectedConcept) return;
    setDisableBtns(true);
    const updatedItems = [...items];
    const updatedItem = {
      ...updatedItems[index],
      quantity: updatedItems[index].quantity - 1,
    };
    updatedItems[index] = updatedItem;
    const finalUpdatedItems = decrementOrRemoveItem(
      updatedItems,
      updatedItem,
      index
    );
    const newQuantity = totalQuantity - 1;
    const newSubTaxTotal = subTaxTotal - updatedItem.price;
    if (user) {
      await updateOnlineCart(
        finalUpdatedItems,
        deliveryFee,
        user.id,
        selectedConcept.id,
        selectedConcept.vatPercentage,
        selectedConcept.serviceChargePercentage,
        selectedConcept.addDeliveryToVat,
        channel
      );
    } else {
      updateOfflineCart(
        finalUpdatedItems,
        deliveryFee,
        selectedConcept.id,
        selectedConcept.vatPercentage,
        selectedConcept.serviceChargePercentage,
        selectedConcept.addDeliveryToVat,
        channel
      );
    }
    updateCartState(
      finalUpdatedItems,
      newQuantity,
      newSubTaxTotal,
      deliveryFee,
      dispatch,
      selectedConcept.vatPercentage,
      selectedConcept.serviceChargePercentage,
      selectedConcept.addDeliveryToVat,
      channel
    );
    setDisableBtns(false);
  };
  const handleRemoveItem = async (index: number) => {
    if (!selectedConcept) return;
    setDisableBtns(true);
    const updatedItems = [...items];
    const item = updatedItems[index];
    const clonedDebounced = [...debouncedState];
    updatedItems.splice(index, 1);
    clonedDebounced.splice(index, 1);
    const newQuantity = totalQuantity - item.quantity;
    const newSubTaxTotal = subTaxTotal - item.price * item.quantity;

    if (user) {
      await updateOnlineCart(
        updatedItems,
        deliveryFee,
        user.id,
        selectedConcept.id,
        selectedConcept.vatPercentage,
        selectedConcept.serviceChargePercentage,
        selectedConcept.addDeliveryToVat,
        channel
      );
    } else {
      updateOfflineCart(
        updatedItems,
        deliveryFee,
        selectedConcept.id,
        selectedConcept.vatPercentage,
        selectedConcept.serviceChargePercentage,
        selectedConcept.addDeliveryToVat,
        channel
      );
    }
    setDebouncedState(clonedDebounced);
    updateCartState(
      updatedItems,
      newQuantity,
      newSubTaxTotal,
      deliveryFee,
      dispatch,
      selectedConcept.vatPercentage,
      selectedConcept.serviceChargePercentage,
      selectedConcept.addDeliveryToVat,
      channel
    );
    setDisableBtns(false);

    // Check if the removed item is Loyalty Item Set the flag to false.
    if (item?.isLoyalty) {
      setFetchAgain((prev) => !prev);
    }
    if (promocode) {
      dispatch(setPromocode(null));
      dispatch(setDiscountedValue(null));
    }
  };
  useEffect(() => {
    if (!discount) setHasLoyaltyItem(false);
    else setHasLoyaltyItem(true);
  }, [discount]);

  const navigateToMenuItem = (cartItem: CartItem) => {
    let items = categories
      .map((category: Category) => category.menuItems)
      .flat();
    let item = items.find(
      (item: MenuItemType) => item.id === cartItem.menuItemID
    );
    if (!item) return;

    dispatch(setSelectedItem(item));
    navigate(`/menu/categoryID/${item.id}`, { state: { cartItem } });
  };

  const handleOrderPlacement = async () => {
    //le.log("From Order Placement");
    let loyaltyDiscount = 0;
    setDisableBtns(true);
    if (!user) {
      navigate("/login");
      setDisableBtns(false);
      return;
    }
    if (!user.address && channel !== "pick-up") {
      setMessage(t("Please add address"));
      setWarning(true);
      setDisableBtns(false);
      return;
    }
    let userInitialAddress = false;
    if (user.address) {
      userInitialAddress = JSON.parse(user.address);
    }

    if (
      !selectedAddress?.address &&
      channel !== "pick-up" &&
      !userInitialAddress
    ) {
      setMessage(t("Please add address"));
      setWarning(true);
      setDisableBtns(false);
      return;
    }
    if (!selectedAddress?.address && channel !== "pick-up") {
      setMessage(t("Please select address first"));
      setWarning(true);
      setDisableBtns(false);
      return;
    }
    if (!selectedConcept || (selectedConcept && selectedConcept.isBusy)) {
      const availableConcepts = getAvailableConcepts(concept?.listing ?? []);
      const availableBranches: string[] = availableConcepts?.map(
        (availableConcept: Concept) => availableConcept?.name ?? ""
      );
      setMessage(
        t("branchIsBusy", {
          availableBranches: availableBranches.join(", "),
        })
      );
      setWarning(true);
      setDisableBtns(false);
      return;
    }
    if (selectedConcept.minOrderVal > totalPrice) {
      setMessage(
        t("minOrderValError", {
          minOrderVal: numberTranslation(
            selectedConcept.minOrderVal,
            i18n.language
          ),
        })
      );
      setWarning(true);
      setDisableBtns(false);
      return;
    }
    // check if any of the items is out of stock before ordering
    let menuItems: any[] = await getMenuItems();
    let cartItemsIDs = items.map((item) => item.menuItemID);
    menuItems = menuItems.filter((menuItem) =>
      cartItemsIDs.includes(menuItem.id)
    );
    for (const item of items) {
      if (item?.menuItemID) {
        let menuItem = menuItems.find(
          (menuItem) => menuItem.id === item.menuItemID
        );
        if (
          menuItem?.outOfStock ||
          (menuItem?.hasLimitedQuantity && menuItem.quantity < item.quantity)
        ) {
          let name =
            new Map(JSON.parse(menuItem.multiLanguages))?.get(
              `${i18n.language}-${"name"}`
            ) ?? menuItem.name;
          if (menuItem?.outOfStock || menuItem.quantity === 0) {
            setMessage(t("OutOfStockWarning", { name }));
          } else {
            setMessage(t("OnlyLeft", { name, quantity: menuItem.quantity }));
          }
          setWarning(true);
          setDisableBtns(false);
          return;
        }

        if (item?.isLoyalty) {
          loyaltyDiscount += item.price;
        }
      } else {
        // check if the cartItem isLoyaltyITem
      }
    }

    if (
      !user.phone_number ||
      user.phone_number === "00" ||
      user.phone_number === ""
    ) {
      setMessage(t("Please add a phone number in your profile"));
      setWarning(true);
      setDisableBtns(false);
      return;
    }
    if (items.length < 1) {
      setMessage(t("SorryMssg"));
      setWarning(true);
      setDisableBtns(false);
      return;
    }

    if (!isPlaceOpen(selectedConcept?.openTime, selectedConcept?.closeTime)) {
      const sortedSelectedZoneConcepts = sortConceptsASCByDeliveryFee(
        selectedAddressZone?.concepts ?? [],
        concept?.listing ?? []
      );
      const availableConcepts = getAvailableConcepts(
        sortedSelectedZoneConcepts
      );
      const availableBranches: string[] = availableConcepts?.map(
        (availableConcept: Concept) => availableConcept?.name ?? ""
      );

      setMessage(
        t("branchNotOpen", {
          openTime: selectedConcept.openTime,
          closeTime: selectedConcept.closeTime,
          availableBranches: availableBranches.join(", "),
        })
      );
      setWarning(true);
      setDisableBtns(false);
      return;
    }

    // apply promocode
    let appliedDiscountedValue = undefined;
    let appliedPromocode = undefined;
    let afterDiscountVal = undefined;

    if (promocode) {
      const responseBody = await applyPromocode(
        promocodeName,
        user.id,
        subTotal + Number(taxes),
        subTaxTotal,
        deliveryFee,
        totalQuantity,
        tender,
        channel
      );
      if (!responseBody.length) {
        const errorMessage = constructPromocodeErrorMessage(
          responseBody.errorMessage,
          t,
          i18n
        );
        setMessage(errorMessage);
        setWarning(true);
        setDisableBtns(false);
        return;
      }
      appliedPromocode = responseBody[0].promocode;
      appliedDiscountedValue = responseBody[0].discountedValue;
      afterDiscountVal = responseBody[0].totalDiscountedPrice;

      if (appliedDiscountedValue !== discountedValue) {
        setMessage(t("promocode has changed. please review changes"));
        setWarning(true);
        setDisableBtns(false);
        dispatch(setPromocode(appliedPromocode));
        dispatch(setDiscountedValue(appliedDiscountedValue));
        return;
      }
    }

    //le.log(discountedValue, appliedDiscountedValue);
    let paymentAmount: any = totalPrice;

    if (appliedDiscountedValue) {
      if (promocode?.appliesTo === PromocodeAppliesTo.SHIPPING_FEES)
        paymentAmount = Number(afterDiscountVal + deliveryFee).toFixed(2);
      else {
        paymentAmount = Number(afterDiscountVal).toFixed(2);
      }
    }
    //le.log(paymentAmount);

    // let tax: number = !discountedValue
    //     ? Number(
    //           ((selectedConcept.vatPercentage ? selectedConcept.vatPercentage : 1) - 1) *
    //               (subTotal + (selectedConcept?.addDeliveryToVat ? deliveryFee ?? 0 : 0))
    //       )
    //     : Number(
    //           ((selectedConcept.vatPercentage ? selectedConcept.vatPercentage : 1) - 1) *
    //               (subTotal -
    //                   (promocode?.appliesTo === PromocodeAppliesTo.WHOLE_CART ? discountedValue : 0) +
    //                   (selectedConcept?.addDeliveryToVat
    //                       ? deliveryFee ??
    //                         0 - (promocode?.appliesTo === PromocodeAppliesTo.SHIPPING_FEES ? discountedValue : 0)
    //                       : 0))
    //       )

    // tax = parseFloat(tax.toFixed(2))

    // businessFlow
    let status = OrderStatus.pending;
    let simphony: boolean = true;

    // //le.log({
    //   varifyForAmount: selectedConcept.varifyForAmount,
    //   varifyFirstOrder: selectedConcept.varifyFirstOrder,
    //   varifyNewAddress: selectedConcept.varifyNewAddress,
    //   paymentAmount,
    //   lastOrderDate: user.lastOrderDate,
    //   sameConditionLastOrderDate: !user.lastOrderDate,
    //   selectedAddress,
    // });
    if (
      paymentAmount <= selectedConcept.varifyForAmount ||
      (selectedConcept.varifyFirstOrder && !user.lastOrderDate) ||
      (selectedConcept.varifyNewAddress && selectedAddress.address.firstTime)
    ) {
      status = OrderStatus.review;
      // do not create symphony
      simphony = false;
    }
    let finalDiscount = 0;
    if (loyaltyDiscount > 0) {
      finalDiscount = loyaltyDiscount;
    }

    if (discountedValue > 0) {
      finalDiscount = discountedValue;
    }
    // create order
    const orderData = {
      cartID: cartData.id,
      conceptID: cartData.conceptID,
      userID: user.id,
      userName: user.name ? user.name : "customer",
      orderedItems: removeTypeNameFromItems(items),
      specialRequest: specialRequest,
      OrderStatus: status,
      orderType: channel,
      channel: "mobile app",
      paymentType: tender,
      orderTime: OrderTime,
      orderAddress: selectedAddress?.address
        ? JSON.stringify(selectedAddress.address)
        : "",
      totalPrice: paymentAmount,
      discountedValue: finalDiscount ?? 0,
      deliveryFee,
      tax: taxes ?? 0,
      subTotal,
      subTaxTotal: Number(subTotal) + Number(taxes),
      tableID: "",
      createdAt: new Date().toISOString(),
      tableName: "",
      parentOrder: "12345678",
      childrenStatus: [""],
      promoCodeID: appliedPromocode ? appliedPromocode.id : "",
      promoCodeName: appliedPromocode ? appliedPromocode.name : "",
      promoCodeAppliedTo: appliedPromocode ? appliedPromocode.appliesTo : "",
      tenderID: selectedConcept.tenderID,
      testFieldNotUsed:
        channel == "delivery"
          ? selectedConcept.orderTypeDelivery
          : selectedConcept.orderTypeTakeAway,
    };

    const channelArabic = channel === "delivery" ? "توصيل" : "استلام من الفرع";
    const channelMultiLanguages = [];
    channelMultiLanguages.push(["English-channel", channel]);
    channelMultiLanguages.push(["Arabic-channel", channelArabic]);

    console.log({ orderData });
    //le.log({ create: orderData });
    // handle different paymentType
    try {
      // throw "throw";
      if (tender === "Cash") {
        const data = await Promise.all([
          createOnlineOrder(orderData),
          updateOnlineCart(
            [],
            0,
            user.id,
            selectedConcept.id,
            selectedConcept.vatPercentage,
            selectedConcept.serviceChargePercentage,
            selectedConcept.addDeliveryToVat,
            channel
          ),
          // createOrderRequest(
          //   "cash",
          //   items,
          //   cartData.conceptID,
          //   channel,
          //   SimphonyOrderOperation.CREATE,
          //   simphony
          // ),
        ]);
        if (!data[0]?.onlineOrder?.id) {
          setMessage(t("SorryMssg"));
          setWarning(true);
          setDisableBtns(false);
          return;
        }
        setSuccess(true);
        updateCartState(
          [],
          0,
          0,
          0,
          dispatch,
          selectedConcept.vatPercentage,
          selectedConcept.serviceChargePercentage,
          selectedConcept.addDeliveryToVat,
          channel
        );
        dispatch(setPromocode(null));
        dispatch(setPromocodeName(undefined));
        dispatch(setDiscountedValue(null));
        dispatch(setCurrentOrder(data[0].onlineOrder));
        if (ordersList && ordersList.length > 0) {
          // add to ordersList to be available in OrderHistory
          dispatch(setOrder([data[0].onlineOrder, ...ordersList]));
        }
        // dispatch(setLogin(data[0].user));
        navigate("/ordertrack");
        let updatedUser: any = await getUserByPhoneNumber(user.phone_number);
        updatedUser = updatedUser.items[0];
        updatedUser.group = user.group;
        dispatch(setLogin({ user: updatedUser }));
        setDisableBtns(false);
      } else {
        // create payment req and redirect to that url
        const paymentData: PaymentData = {
          amount_cents: (orderData.totalPrice * 100).toString(),
          conceptID: orderData.conceptID,
          transactionType: "payment",
          guestName: orderData.userName,
          guestPhone: user.phone_number,
          guestGroup: user.group,
          guestID: orderData.userID,
          email: user.email,
          orderType: orderData.orderType,
          cartID: orderData.cartID,
          orderedItems: orderData.orderedItems,
          orderAddress: orderData.orderAddress,
          specialRequest: orderData.specialRequest,
          orderTime: orderData.orderTime,
          totalPrice: orderData.totalPrice,
          deliveryFee: orderData.deliveryFee,
          tax: Number(orderData.tax),
          subTaxTotal: orderData.subTaxTotal,
          subTotal: orderData.subTotal,
          checkNumber: "1234",
          parentOrder: orderData.parentOrder,
          childrenStatus: orderData.childrenStatus,
          channel: orderData.channel,
          promoCodeID: orderData.promoCodeID,
          promoCodeName: orderData.promoCodeName,
          promoCodeAppliedTo: orderData.promoCodeAppliedTo,
          OrderStatus: orderData.OrderStatus,
          tableID: orderData.tableID,
          tableName: orderData.tableName,
          paymentType: orderData.paymentType,
          createdAt: orderData.createdAt,
        };
        const paymentURL = await createPaymentURL(paymentData);
        if (paymentURL === undefined || paymentURL?.includes("undefined")) {
          throw new Error("Payment URL is undefined");
        }
        window.location.href = paymentURL;
        dispatch(setPromocode(null));
        dispatch(setPromocodeName(undefined));
        dispatch(setDiscountedValue(null));
        setDisableBtns(false);
      }
    } catch (err) {
      //le.log(err);
      setDisableBtns(false);
      setMessage(t("SorryMssg"));
      setWarning(true);
    }
    setDisableBtns(false);
  };
  //
  const handleNoteChange = (index: number, value: string) => {
    const updatedNotes = [...notes];
    updatedNotes[index] = value;
    const cartItems = [...items];
    cartItems[index] = { ...cartItems[index], notes: value };
    updateCartState(
      cartItems,
      totalQuantity,
      subTaxTotal || 0,
      deliveryFee || 0,
      dispatch,
      selectedConcept.vatPercentage,
      selectedConcept.serviceChargePercentage,
      selectedConcept.addDeliveryToVat,
      channel
    );
    dispatch(setCartNotes(updatedNotes));
  };
  const handleSpecialRequestChange = (event: any) => {
    setSpecialRequestState(event.target.value);
  };
  const handlePromocodeNameChange = (event: any) => {
    dispatch(setPromocodeName(event.target.value?.toUpperCase()));
  };
  const handlePromocodeSelectedChange = (name: string) => {
    dispatch(setPromocodeName(name));
  };
  const handleValidatePromocode = async () => {
    if (promocode || hasLoyaltyItem) {
      dispatch(setPromocode(null));
      dispatch(setDiscountedValue(null));
      return;
    }
    if (!promocodeName || promocodeName === "") return;
    setDisableBtns(true);
    setApplyPromoCodeClicked(true);
    try {
      const orderVal = selectedConcept.addDeliveryToVat
        ? subTotal + Number(taxes) + Number(deliveryFee)
        : subTotal + Number(taxes);
      const responseBody = await validatePromocode(
        promocodeName,
        user.id,
        subTotal + Number(taxes),
        subTaxTotal,
        deliveryFee,
        totalQuantity,
        tender,
        channel
      );
      if (!responseBody.length) {
        const errorMessage = constructPromocodeErrorMessage(
          responseBody.errorMessage,
          t,
          i18n
        );
        setPromocodeErrorMessage(errorMessage);
        setDisableBtns(false);
        setApplyPromoCodeClicked(false);
        setTimeout(() => {
          setPromocodeErrorMessage(undefined);
        }, 3000);
        return;
      }
      // promocode is valid
      dispatch(setPromocode(responseBody[0].promocode));
      dispatch(setDiscountedValue(responseBody[0].discountedValue));
      setDisableBtns(false);
      setApplyPromoCodeClicked(false);
      setPromocodeErrorMessage(undefined);
    } catch (err) {
      //le.log(`error validating promocode: ${err}`);
    }
    setDisableBtns(false);
    setApplyPromoCodeClicked(false);
  };

  const handleToggleAddPromocodeSelected = () => {
    if (promocode) return;
    if (addPromoCodeSelected) {
      dispatch(setPromocodeName(userPromocodes[currentPromocodeIdx].name));
    } else {
      dispatch(setPromocodeName(""));
    }
    setAddPromoCodeSelected((prevState: boolean) => !prevState);
  };

  const handleIncCurrentPromocodeIdx = () => {
    if (promocode) return;
    handlePromocodeSelectedChange(userPromocodes[currentPromocodeIdx + 1].name);
    setCurrentPromocodeIdx((prev: number) => prev + 1);
  };

  const handleDecCurrentPromocodeIdx = () => {
    if (promocode) return;
    handlePromocodeSelectedChange(userPromocodes[currentPromocodeIdx - 1].name);
    setCurrentPromocodeIdx((prev: number) => prev - 1);
  };
  const handleTenderChange = (e: any) => {
    setTender(e.target.value);
  };
  const [loading, setLoading] = useState(true);
  const fetchCart = async (userID: string, conceptID: string) => {
    try {
      setLoading(true);
      let existingCart: any = {};
      if (!Reorderd) {
        if (userID.length > 0) {
          const response = await getCart(userID, conceptID);
          const onlineCart = response.length === 0 ? {} : response[0];
          const localCart = { ...getLocalCart(conceptID) };
          updateOfflineCart(
            [],
            0,
            conceptID,
            selectedConcept.vatPercentage,
            selectedConcept.serviceChargePercentage,
            selectedConcept.addDeliveryToVat,
            "delivery" // not used
          );
          existingCart = mergeLocalAndOnlineCart(
            localCart,
            onlineCart,
            selectedConcept.vatPercentage,
            selectedConcept.serviceChargePercentage,
            selectedConcept.addDeliveryToVat,
            "delivery" // not used
          );
          if (
            onlineCart.totalPrice !== existingCart.totalPrice ||
            onlineCart.totalQuantity !== existingCart.totalQuantity
          ) {
            //  Cart was changed after the merge, update the online cart
            const updatedOnlineCart = await updateOnlineCart(
              existingCart.orderedItems,
              existingCart.totalPrice,
              userID,
              conceptID,
              selectedConcept.vatPercentage,
              selectedConcept.serviceChargePercentage,
              selectedConcept.addDeliveryToVat,
              channel
            );
          }
        } else {
          existingCart = getLocalCart(conceptID);
        }
      } else {
        existingCart.orderedItems = Reorderd.orderedItems;
        existingCart.subTaxTotal = Reorderd.orderedItems.reduce(function (
          sum: any,
          item: any
        ) {
          return sum + item.price * item.quantity;
        }, 0);
        existingCart.cartID = Reorderd.cartID;
        existingCart.conceptID = Reorderd.conceptID;

        existingCart.deliveryFee = Reorderd.deliveryFee;
      }
      const totalQuantity = getCartItemsQuantity(existingCart);
      setDefaultNotes(existingCart?.orderedItems || []);
      setCartData(existingCart);
      updateCartState(
        existingCart?.orderedItems || [],
        totalQuantity,
        existingCart?.subTaxTotal || 0,
        deliveryFee || 0,
        dispatch,
        selectedConcept.vatPercentage,
        selectedConcept.serviceChargePercentage,
        selectedConcept.addDeliveryToVat,
        channel
      );
      setInitialSubTotal(existingCart.subTaxTotal);
    } catch (e: any) {
      //le.log(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchRewardItems = async () => {
    const rewardItemsArr = await getLoyaltyItems();

    //le.log(rewardItemsArr);
    dispatch(setRewardItems(rewardItemsArr ? rewardItemsArr : []));
  };

  // const fetchCart = async (userID: string, conceptID: string) => {
  //   let existingCart: any = {};
  //   if (!Reorderd) {
  //     if (userID.length > 0) {
  //       const response = await getCart(userID,conceptID);
  //       const onlineCart = response.length === 0 ? {} : response[0];
  //       const localCart = getLocalCart(conceptID);
  //       existingCart = onlineCart ? onlineCart : localCart;
  //     } else {
  //       existingCart = getLocalCart(conceptID);
  //     }
  //   } else {
  //     existingCart.orderedItems = Reorderd.orderedItems;
  //     existingCart.subTaxTotal = Reorderd.orderedItems.reduce(function (
  //       sum: any,
  //       item: any
  //     ) {
  //       return sum + item.price * item.quantity;
  //     }, 0);
  //     existingCart.deliveryFee = Reorderd.deliveryFee;
  //   }
  //   const totalQuantity = getCartItemsQuantity(existingCart);
  //   setDefaultNotes(existingCart?.orderedItems || []);
  //   setCartData(existingCart);
  //   const deliveryFee = getDeliveryFee();
  //   updateCartState(
  //     existingCart?.orderedItems || [],
  //     totalQuantity,
  //     existingCart?.subTaxTotal || 0,
  //     deliveryFee || 0,
  //     dispatch
  //   );
  // };
  function roundNumbers(number: any): any {
    return number.toFixed(2);
  }

  const getDeliveryFee = () => {
    if (channel === "delivery" && selectedAddress?.address) {
      let deliveryFee: number = selectedAddressZone?.concepts?.find(
        (zoneConcept: ZoneConcept) => zoneConcept?.id === selectedConcept?.id
      )?.deliveryFee;
      setDeliveryFee(deliveryFee);
      const newSubTotal = getCartSubTotal(
        subTaxTotal,
        selectedConcept.serviceChargePercentage,
        channel
      );
      const loyaltyDiscount = getCartLoyaltyDiscount(
        cartData.orderedItems || []
      );
      //le.log({ loyaltyDiscount });
      //le.log({ discount });
      const newTotal = getCartTotalPrice(
        newSubTotal,
        selectedConcept.vatPercentage,
        deliveryFee ?? 0,
        selectedConcept.addDeliveryToVat,
        loyaltyDiscount ?? discount
      );
      dispatch(setCartSubTotal(newSubTotal));
      dispatch(setCartTotal(newTotal));
      // setLoyalty discount
      dispatch(setLoyaltyDiscount(loyaltyDiscount));
      return deliveryFee;
    }
  };

  useEffect(() => {
    getDeliveryFee();
  }, [
    selectedAddress,
    user,
    totalPrice,
    selectedAddressZone,
    selectedConcept,
    cartData,
  ]);

  useEffect(() => {
    if (!user || !selectedConcept) {
      setLoading(false);
      return;
    }
    // if (user && user.address && !selectedAddress) {
    //   dispatch(
    //     setSelectedAddress({
    //       address: JSON.parse(user.address)[0],
    //       index: null,
    //     })
    //   );
    // }
    let userID = user?.id || "";
    if (userPromocodes.length > 0) {
      dispatch(setPromocodeName(userPromocodes[currentPromocodeIdx].name));
    }

    fetchCart(userID, selectedConcept.id);
  }, [user, selectedConcept, fetchAgain]);

  const filterUserPromocodes = (promocodesOnUser: any[]) => {
    return promocodesOnUser.filter(
      (promocodeItemOnUser: any) => promocodeItemOnUser.isDisplayed === true
    );
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant" as ScrollBehavior,
    });
  }, []);
  useEffect(() => {
    setUserPromocodes(filterUserPromocodes(user?.promocodes || []));
  }, [user]);

  useEffect(() => {
    if (userPromocodes.length < 1) return;
    if (!addPromoCodeSelected) {
      dispatch(setPromocodeName(userPromocodes[currentPromocodeIdx].name));
    } else {
      dispatch(setPromocodeName(""));
    }
  }, [userPromocodes, addPromoCodeSelected]);

  useEffect(() => {
    if (specialRequest === specialRequestState) return;
    const timeoutId = setTimeout(() => {
      dispatch(setCartSpecialRequest(specialRequestState));
    }, 500);
    // Cleanup function to clear the previous timeout when the component re-renders
    return () => clearTimeout(timeoutId);
  }, [specialRequestState]);

  // if (!categories || !categories.length) return <Navigate to={"/"} />

  const handleIncrementDebounced = (index: any) => {
    setCanOrder(false);
    if (categories) {
      const menuItem = getMenuItemById(items[index].menuItemID, categories);
      if (
        menuItem.outOfStock ||
        (menuItem.hasLimitedQuantity &&
          menuItem.quantity <= (debouncedState[index] || items[index].quantity))
      ) {
        setMessage("Item is out of stock.");
        setWarning(true);
        return;
      }
    }
    const currentValue = debouncedState[index] || 0;
    const newDebounced = [...debouncedState];
    newDebounced[index] = currentValue + 1;
    setDebouncedState([...newDebounced]);
  };
  const handleDecrementDebounced = (index: any) => {
    setCanOrder(false);
    const currentValue = debouncedState[index] || 0;
    const newDebounced = [...debouncedState];
    newDebounced[index] = currentValue - 1;
    if (newDebounced[index] <= 0) {
      handleDecrement(index);
      return;
    }
    setDebouncedState([...newDebounced]);
  };
  useEffect(() => {
    const handleUpdateRedux = () => {
      const updatedItems = [...items];
      console.log({ updatedItems, debouncedState });
      let increasedQuantity = 0;
      for (const key in debouncedState) {
        let initialQuantity = updatedItems[key].quantity;
        const updatedItem = {
          ...updatedItems[key],
          quantity: debouncedState[key],
        };
        if (updatedItem.quantity === 0) {
          updatedItems.splice(Number(key), 1);
          const newQuantity = totalQuantity + debouncedState[Number(key)];
          const newSubTaxTotal =
            subTaxTotal + updatedItem.price * debouncedState[Number(key)];
          console.log({
            updatedItems,
            updatedItem,
            newQuantity,
            subTaxTotal,
            newSubTaxTotal,
          });
          updateCartState(
            updatedItems,
            newQuantity,
            newSubTaxTotal,
            deliveryFee,
            dispatch,
            selectedConcept.vatPercentage,
            selectedConcept.serviceChargePercentage,
            selectedConcept.addDeliveryToVat,
            channel
          );
          const newDebounced = [...debouncedState];
          // delete newDebounced[Number(key)];
          newDebounced.splice(Number(key), 1);
          setDebouncedState(newDebounced);
        } else {
          // console.log("beforeError", updatedItems);
          updatedItems[Number(key)] = updatedItem;
          increasedQuantity =
            increasedQuantity + debouncedState[Number(key)] - initialQuantity;
          const newQuantity = totalQuantity + increasedQuantity;
          const newSubTaxTotal =
            subTaxTotal +
            updatedItem.price * debouncedState[Number(key)] -
            updatedItem.price * initialQuantity;
          console.log({
            updatedItem,
            newSubTaxTotal,
            subTaxTotal,
            debouncedState,
            key,
            updatedItems,
          });
          updateCartState(
            updatedItems,
            newQuantity,
            newSubTaxTotal,
            deliveryFee,
            dispatch,
            selectedConcept.vatPercentage,
            selectedConcept.serviceChargePercentage,
            selectedConcept.addDeliveryToVat,
            channel
          );
        }
        // const newDebounced = { ...debouncedState };
        // delete newDebounced[key];
        // setDebouncedState(newDebounced);
      }
      setCanOrder(true);
    };
    const timer = setTimeout(() => {
      handleUpdateRedux();
      //   changeUpdating(false);
    }, 700);

    return () => clearTimeout(timer);
  }, [debouncedState]);

  // fetch the cart again

  // useEffect(()=>{
  //     fetchCart(user?.id,concept?.id);
  // },[fetchAgain])

  useEffect(() => {
    const updateCart = async () => {
      if (!selectedConcept) return;
      setDisableBtns(true);
      const updatedItems = [...items];

      if (user) {
      } else {
        updateOfflineCart(
          updatedItems,
          deliveryFee,
          selectedConcept.id,
          selectedConcept.vatPercentage,
          selectedConcept.serviceChargePercentage,
          selectedConcept.addDeliveryToVat,
          channel
        );
      }
      setInitialSubTotal(cart.subTaxTotal);
      setDisableBtns(false);
    };
    if (
      !loading ||
      cart.subTaxTotal.toFixed(2) !== initialSubTotal.toFixed(2)
    ) {
      updateCart();
    }
  }, [cart.subTaxTotal, cart.discount]);
  useEffect(() => {
    const selectedZoneId = selectedAddress?.address?.zoneID;
    const zoneSelected = zonesListing.find(
      (zone: Zone) => zone.id === selectedZoneId
    );
    setSelectedAddressZone(zoneSelected);
  }, [selectedAddress, zonesListing]);
  useEffect(() => {
    fetchRewardItems();
  }, []);
  useEffect(() => {
    console.log({
      subTotal,
      selectedConcept,
    });
    if (subTotal > 0 && selectedConcept?.vatPercentage) {
      let deliveryTax = 0;

      if (selectedConcept.addDeliveryToVat) {
        deliveryTax = Number(
          ((selectedConcept?.vatPercentage - 1) * deliveryFee).toFixed(2)
        );
      }

      const taxAmount = Number(
        Number(((selectedConcept?.vatPercentage - 1) * subTotal).toFixed(2)) +
          deliveryTax
      ).toFixed(2);

      console.log({ taxAmount });

      setTaxes(`${taxAmount}`);
    }
  }, [subTotal, selectedConcept, deliveryFee]);

  const addLoyaltyItem = async (item: IRewardItem) => {
    if (user) {
      const loyaltyCartItem = {
        menuItemID: item.id,
        symphonyID: item.symphonyID,
        preparationAreaID: null,
        name: item.name,
        quantity: 1,
        choiceIDs: [],
        choiceNames: [],
        choiceSymphonyID: [],
        extraChoiceIDs: [],
        extraChoiceSymphonyID: [],
        price: item.price ?? 150,
        priceName: null,
        priceID: null,
        priceMultiLanguages: item.multiLanguages,
        isLoyalty: true,
        multiLanguages: null,
        notes: "",
        image: item.image,
      };

      const updatedItems = [...items, loyaltyCartItem];

      // Update Redux immediately
      const newSubTotal = getCartSubTotal(
        subTaxTotal,
        selectedConcept.serviceChargePercentage,
        channel
      );
      const loyaltyDiscount = getCartLoyaltyDiscount(updatedItems);

      updateCartState(
        updatedItems,
        totalQuantity + 1, // Increase quantity
        newSubTotal,
        deliveryFee,
        dispatch,
        selectedConcept.vatPercentage,
        selectedConcept.serviceChargePercentage,
        selectedConcept.addDeliveryToVat,
        channel
      );

      dispatch(setLoyaltyDiscount(loyaltyDiscount)); // Update discount immediately
      setHasLoyaltyItem(true); // Update state

      await updateOnlineCart(
        updatedItems,
        deliveryFee,
        user.id,
        selectedConcept.id,
        selectedConcept.vatPercentage,
        selectedConcept.serviceChargePercentage,
        selectedConcept.addDeliveryToVat,
        channel
      );
      // Check if the removed item is Loyalty Item Set the flag to false.
      setTimeout(() => {
        setFetchAgain((prev) => !prev);
      }, 700);
    }
  };

  return (
    <Grid container>
      <Grid
        item
        sm={12}
        lg={12}
        width={"100%"}
        height={"100%"}
        sx={{
          backgroundColor: theme.palette.background.default,
        }}
        display={"flex"}
        flexDirection={"row"}
        position={"sticky"}
        top={0}
        zIndex={1}
      >
        <Link to="/">
          <Button
            sx={{
              backgroundColor: "rgba(169, 169, 169, 0.55)",
              color: "rgba(64, 64, 64, 0.7)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minWidth: " 2rem",
              height: "2rem",
              mt: 2,
              ml: 2,
              borderRadius: "0.5rem",
              marginBottom: ".5rem",
              "&:hover": {
                backgroundColor: "#33bde8",
              },
            }}
          >
            <ArrowBackIosNewIcon
              sx={{
                color: "#33BDE8",
                fontSize: "1rem",
                transform:
                  i18n.language === "Arabic" ? "rotate(180deg)" : "none",
              }}
            />
          </Button>
        </Link>
        {selectedAddress?.address && channel === "delivery" && (
          <Grid
            item
            width={"77vw"}
            justifyContent={"center"}
            alignItems={"center"}
            mt={3}
            sx={{ display: "flex", gap: "10px", position: "relative" }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "0.8rem", // Adjust font size
                maxWidth: "10rem", // Fixed width
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                margin: "0 auto", // Center along x-axis
              }}
            >
              {`${selectedAddress.address.buildingNumber}, ${selectedAddress.address.streetName}, ${selectedAddress.address.zone}`}
            </Typography>
            {loading && (
              <Box sx={{ position: "absolute", width: "20%", right: "10px" }}>
                <ContentLoader />
              </Box>
            )}
          </Grid>
        )}
      </Grid>

      {items.length < 1 ? (
        <EmptyCart />
      ) : (
        <Grid
          container
          sx={{
            color: theme.palette.primary.main,
            padding: "1rem",
            marginBottom: "3rem",
          }}
        >
          {" "}
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={warning}
            autoHideDuration={3000}
            onClose={handleWarningClose}
          >
            <Alert
              onClose={handleWarningClose}
              severity="warning"
              sx={{
                position: "fixed",
                top: "16px",
                right: "16px",
              }}
              action={
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleWarningClose}
                >
                  <CloseIcon
                    fontSize="small"
                    fontFamily={`regular${i18n.language}`}
                  />
                </IconButton>
              }
            >
              {message}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={success}
            autoHideDuration={3000}
            onClose={handleSuccessClose}
          >
            <Alert
              onClose={handleSuccessClose}
              severity="success"
              sx={{
                position: "fixed",
                top: "16px",
                right: "16px",
              }}
              action={
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleSuccessClose}
                >
                  <CloseIcon
                    fontSize="small"
                    fontFamily={`regular${i18n.language}`}
                  />
                </IconButton>
              }
            >
              {t("Ordersuccessously")}
            </Alert>
          </Snackbar>
          <Grid container>
            {/* //items */}
            {items.map((item, index) => (
              <Grid
                container
                key={index}
                sx={{
                  backgroundColor: theme.palette.primary.dark,
                  borderRadius: "0.3rem",
                  marginY: "0.1rem",
                  padding: "0.5rem",
                  justifyContent: "space-around",
                  boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)", // Box shadow
                }}
              >
                {/* the image */}
                <Grid item sm={3} xs={3}>
                  <img
                    alt="item"
                    src={`${cloudinaryURL}${item.image}`}
                    style={{
                      width: "90%",
                      borderRadius: "1rem",
                      border: "0.1rem solid red",
                      // border:
                      //   index % 2 === 0
                      //     ? "0.1rem solid #33BDE8 "
                      //     : "0.1rem solid #F07EAA ",
                    }}
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      target.src = defaultImage; // Fallback to default image on error loading API image
                    }}
                  />
                </Grid>
                {/* the info */}
                <Grid item sm={9} xs={9}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      sx={{
                        // fontSize: 30,
                        fontSize: {
                          xs: 20,
                          sm: 30,
                        },
                        fontWeight: 700,
                        fontFamily: `bold${i18n.language}`,
                      }}
                    >
                      {/* {capitalization(item.name)} */}
                      {capitalization(
                        new Map(JSON.parse(item.multiLanguages))?.get(
                          `${i18n.language}-${"name"}`
                        ) ?? item.name
                      )}
                    </Typography>
                    {/* Edit button */}
                    <Button
                      disabled={disableBtns || item?.isLoyalty}
                      onClick={() => navigateToMenuItem(item)}
                      sx={{
                        textTransform: "none",
                        color: "#33BDE8",
                        fontFamily: `regular${i18n.language}`,
                        "&:hover": {
                          backgroundColor: "#red",
                        },
                      }}
                    >
                      {t("Edit")}
                    </Button>
                    {/* Remove button */}
                    <Button
                      disabled={disableBtns}
                      onClick={() => handleRemoveItem(index)}
                      sx={{
                        textTransform: "none",
                        color: "red",
                        fontFamily: `regular${i18n.language}`,
                        "&:hover": {
                          backgroundColor: "#red",
                        },
                      }}
                    >
                      {t("Remove")}
                    </Button>
                  </Box>
                  <Grid container justifyContent="space-between">
                    <Grid item sm={6} xs={12}>
                      {item.priceName && (
                        <Typography
                          sx={{
                            fontSize: {
                              xs: 10,
                              sm: 15,
                            },
                            marginBottom: "0.2rem",
                            fontWeight: 700,
                            fontFamily: `bold${i18n.language}`,
                            whiteSpace: "pre-line", // This CSS property preserves newlines
                          }}
                        >
                          {capitalization(
                            new Map(JSON.parse(item.priceMultiLanguages))?.get(
                              `${i18n.language}-name`
                            ) ?? item.priceName
                          )}
                        </Typography>
                      )}
                      {item.choiceNames.length > 0 && (
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="flex-end"
                        >
                          <Typography
                            sx={{
                              fontSize: {
                                xs: 10,
                                sm: 15,
                              },
                              marginBottom: "0.5rem",
                              fontWeight: 700,
                              fontFamily: `bold${i18n.language}`,
                              whiteSpace: "normal",
                            }}
                          >
                            {/* {item.choiceNames.map(
                              (choiceName: string, choiceIndex: number) => (
                                <span key={choiceIndex}>
                                  {capitalization(choiceName)}
                                  {choiceIndex < item.choiceNames.length - 1
                                    ? ", "
                                    : ""}
                                </span>
                              )
                            )} */}
                            {item.choiceNames.map(
                              (choiceName: string, choiceIndex: number) => {
                                // Parse the JSON string into an object
                                const choiceGroupItem = JSON.parse(choiceName);

                                // Extract the choiceGroup name and choices names
                                const {
                                  choiceGroupName,
                                  choiceGroupMultiLanguages,
                                  choices,
                                } = choiceGroupItem;

                                // Construct the display string for the choiceGroup and its choices
                                const choiceGroupDisplay = capitalization(
                                  new Map(
                                    JSON.parse(choiceGroupMultiLanguages)
                                  )?.get(`${i18n.language}-name`) ??
                                    choiceGroupName
                                );

                                const choiceDisplay = choices
                                  .map((choice: any) =>
                                    capitalization(
                                      new Map(
                                        JSON.parse(choice.multiLanguages)
                                      )?.get(`${i18n.language}-name`) ??
                                        choice.name
                                    )
                                  )
                                  .join(", ");

                                return (
                                  <span key={choiceIndex}>
                                    <span
                                      style={{
                                        color: "#33BDE8",
                                        fontSize: "1rem",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {choiceGroupDisplay}
                                    </span>
                                    : {choiceDisplay}
                                    {choiceIndex < item.choiceNames.length - 1
                                      ? " "
                                      : ""}
                                  </span>
                                );
                              }
                            )}
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                    <Grid item container sm={12} xs={12}>
                      <Grid item xs={4} container alignContent={"center"}>
                        <Typography
                          sx={{
                            // fontSize: 24,
                            fontSize: {
                              xs: 16,
                              sm: 24,
                            },
                            fontWeight: "bold",
                            fontFamily: `bold${i18n.language}`,
                          }}
                        >
                          {numberTranslation(
                            roundNumbers(item.price * item.quantity),
                            i18n.language
                          )}{" "}
                          {t("currency.EGP")}
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-end"
                          alignContent={"c"}
                        >
                          {/* Increment and Decrement buttons with Quantity display */}
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            {/* Decrement button */}
                            <Button
                              sx={{
                                minWidth: "40px",
                                backgroundColor: "#33BDE8",
                                height: "2rem",
                                borderRadius: "14px",
                                marginLeft: "0.3rem",
                                "&:hover": {
                                  backgroundColor: "#33BDE8",
                                },
                              }}
                              onClick={() => handleDecrementDebounced(index)}
                              disabled={disableBtns || item.isLoyalty}
                            >
                              -
                            </Button>

                            {/* Quantity display */}
                            <Typography
                              sx={{
                                color: theme.palette.primary.main,
                                padding: "0.3rem 1rem",
                              }}
                            >
                              {numberTranslation(
                                debouncedState[index],
                                i18n.language
                              )}
                              {/* {items[index].quantity} */}
                            </Typography>

                            {/* Increment button */}
                            <Button
                              sx={{
                                height: "2rem",
                                backgroundColor: "#33BDE8",
                                minWidth: "40px",
                                borderRadius: "14px",
                                padding: "0rem",
                                "&:hover": {
                                  backgroundColor: "#33BDE8",
                                },
                              }}
                              onClick={() => handleIncrementDebounced(index)}
                              disabled={disableBtns || item?.isLoyalty}
                            >
                              +
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <TextField
                    id="outlined-basic"
                    placeholder={t("Notes")}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#D3D3D3",
                        },
                        "&:hover fieldset": {
                          borderColor: "#D3D3D3",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#D3D3D3",
                        },
                        "& input": {
                          marginX: "0.3rem",
                        },
                        "& input::placeholder": {},
                      },
                      borderRadius: "30px",
                      borderColor: "#D3D3D3",
                      width: "100%",
                      height: "3rem",
                      margin: "1rem auto",
                      fontFamily: `regular${i18n.language}`,
                    }}
                    value={notes[index] ?? ""}
                    onChange={(e) => handleNoteChange(index, e.target.value)}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
          {/* //specialRequest */}
          <Grid container>
            <Grid item sm={12} xs={12} sx={{ marginBottom: "2rem" }}>
              <TextField
                id="outlined-basic"
                placeholder={t("specialRequest")}
                multiline
                rows={4}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#D3D3D3",
                    },
                    "&:hover fieldset": {
                      borderColor: "#D3D3D3",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#D3D3D3",
                    },
                    "& input": {},
                    "& input::placeholder": {
                      display: "flex",
                      alignItems: "center",
                    },
                    display: "flex",
                    alignItems: "flex-start",
                  },
                  borderRadius: "20px",
                  width: "100%",
                  height: "3rem",
                  margin: "0.5rem auto",
                  fontFamily: `regular${i18n.language}`,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ color: theme.palette.primary.main }}
                    >
                      <EditNoteIcon
                        sx={{
                          color: theme.palette.primary.main,
                          marginTop: "1.25rem",
                          marginX: "0.3rem",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                value={specialRequestState}
                onChange={handleSpecialRequestChange}
              />
            </Grid>
          </Grid>
          {!user ? (
            ""
          ) : (
            <>
              <Grid
                item
                sm={12}
                xs={12}
                mt={5}
                display={"flex"}
                flexDirection={"column"}
                sx={{
                  direction: i18n.language === "Arabic" ? "rtl" : "ltr",
                }}
              >
                {addPromoCodeSelected && userPromocodes.length > 0 && (
                  <Button
                    disabled={promocode ? true : false}
                    onClick={handleToggleAddPromocodeSelected}
                    sx={{
                      mt: "0.7rem",
                      width: "160px",
                      justifyContent: "flex-start",
                    }}
                  >
                    {t("Cancel")}
                  </Button>
                )}
                {!addPromoCodeSelected && userPromocodes.length > 0 && (
                  <Button
                    disabled={promocode ? true : false}
                    onClick={handleToggleAddPromocodeSelected}
                    sx={{
                      mt: "0.7rem",
                      width: "160px",
                      justifyContent: "flex-start",
                      fontFamily: `regular${i18n.language}`,
                    }}
                  >
                    {t("Add Promo Code")}
                  </Button>
                )}

                {(addPromoCodeSelected || userPromocodes.length === 0) && (
                  <TextField
                    id="outlined-basic"
                    placeholder={t("promocode")}
                    disabled={false}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#D3D3D3",
                        },
                        "&:hover fieldset": {
                          borderColor: "#D3D3D3",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#D3D3D3",
                        },
                        "& input": {
                          marginX: "0.3rem",
                        },
                        "& input::placeholder": {},
                      },
                      borderRadius: "30px",
                      borderColor: "#D3D3D3",
                      width: "100%",
                      height: "3rem",
                      margin: "0.5rem auto",
                      fontFamily: `regular${i18n.language}`,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                            marginBottom: "1.5rem",
                          }}
                        >
                          {applyPromoCodeClicked && (
                            <CircularProgress
                              size={30}
                              sx={{
                                size: "1.5rem",
                              }}
                            />
                          )}
                          {!applyPromoCodeClicked && (
                            <Button
                              onClick={handleValidatePromocode}
                              disabled={false || hasLoyaltyItem}
                              sx={{
                                height: "1.5rem",
                                fontSize: "1rem",
                                fontFamily: `regular${i18n.language}`,
                                color: `${promocode && "red"}`,
                              }}
                            >
                              {promocode
                                ? t("removePromocode")
                                : t("applyPromocode")}
                            </Button>
                          )}
                        </InputAdornment>
                      ),
                    }}
                    value={promocodeName}
                    onChange={handlePromocodeNameChange}
                  />
                )}
                {/* if apply promocode isnot selected then render the user promocodes */}
                {!addPromoCodeSelected && userPromocodes.length > 0 && (
                  <Grid
                    item
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                  >
                    {currentPromocodeIdx > 0 && (
                      <ChevronLeftIcon
                        onClick={handleDecCurrentPromocodeIdx}
                        sx={{ mt: "1.3rem" }}
                      />
                    )}

                    <TextField
                      id="outlined-basic"
                      placeholder={t("promocode")}
                      disabled={false}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#D3D3D3",
                          },
                          "&:hover fieldset": {
                            borderColor: "#D3D3D3",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#D3D3D3",
                          },
                          "& input": {
                            marginX: "0.3rem",
                          },
                          "& input::placeholder": {},
                        },
                        borderRadius: "30px",
                        borderColor: "#D3D3D3",
                        width: "80%",
                        height: "3rem",
                        margin: "0.5rem auto",
                        fontFamily: `regular${i18n.language}`,
                      }}
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-end",
                              marginBottom: "1.5rem",
                            }}
                          >
                            {applyPromoCodeClicked && (
                              <CircularProgress
                                size={30}
                                sx={{
                                  size: "1.5rem",
                                }}
                              />
                            )}
                            {!applyPromoCodeClicked && (
                              <Button
                                onClick={handleValidatePromocode}
                                disabled={disableBtns}
                                sx={{
                                  height: "1.5rem",
                                  fontSize: "1rem",
                                  fontFamily: `regular${i18n.language}`,
                                  color: `${promocode && "red"}`,
                                }}
                              >
                                {promocode
                                  ? t("removePromocode")
                                  : t("applyPromocode")}
                              </Button>
                            )}
                          </InputAdornment>
                        ),
                      }}
                      value={
                        t("saveValuePromocode", {
                          discountVal: numberTranslation(
                            userPromocodes[currentPromocodeIdx].discountVal,
                            i18n.language
                          ),
                        }) +
                        " " +
                        (userPromocodes[currentPromocodeIdx].discountLimit
                          ? t(
                              `up to ${
                                userPromocodes[currentPromocodeIdx]
                                  .discountLimit || 0
                              } EGP`
                            )
                          : "")
                      }
                    />

                    {currentPromocodeIdx <= userPromocodes.length - 2 && (
                      <ChevronRightIcon
                        onClick={handleIncCurrentPromocodeIdx}
                        sx={{ mt: "1.3rem" }}
                      />
                    )}
                  </Grid>
                )}

                {!promocode && promocodeErrorMessage && (
                  <Typography color={"red"}>{promocodeErrorMessage}</Typography>
                )}
              </Grid>

              <Grid item sm={12} xs={12} sx={{ marginTop: "1rem" }}>
                <RedeemableItemsList
                  addToCart={addLoyaltyItem}
                  hasLoyalty={hasLoyaltyItem}
                />
              </Grid>
            </>
          )}
          {/* addMore */}
          <Grid
            container
            item
            sm={12}
            xs={12}
            mt={user ? 0 : 4}
            sx={{
              paddingTop: "1rem",
              paddingBottom: "1rem",
              alignItems: "center",
            }}
          >
            <Link
              to="/"
              style={{
                textDecoration: "none",
                color: "#00F0FF",
                cursor: "pointer",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  height: "2rem",
                  minWidth: "40px",
                  padding: "0rem",
                  marginX: "1rem",
                  color: "white",
                  backgroundColor: "#33bde8",
                  "&:hover": {
                    backgroundColor: "#33bde8",
                  },
                }}
              >
                +
              </Button>
            </Link>
            <Typography sx={{ fontFamily: `bold${i18n.language}` }}>
              {items.length > 0 ? t("Addmore") : t("AddItems")}
            </Typography>
          </Grid>
          {/* pay with */}
          <Grid
            container
            item
            ml={1}
            sm={12}
            xs={12}
            sx={{
              paddingTop: "1rem",
              paddingBottom: "1rem",
              alignItems: "center",
            }}
          >
            <FormControl fullWidth={true}>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                sx={{
                  mt: "0.7rem",
                  width: "300px",
                  display: "flex",
                  justifyContent: "space-between",
                  fontFamily: `regular${i18n.language}`,
                  color: theme.palette.primary.main,
                }}
              >
                {" "}
                <span>{t("Pay with")}</span>
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={tender}
                onChange={handleTenderChange}
              >
                {/* <FormControlLabel
                  value="Credit Card"
                  control={<Radio sx={{ color: theme.palette.primary.main }} />}
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        width="25px"
                        height={"25px"}
                        src={"../../assets/creditcardIcon.svg"}
                        alt={t("Credit Card")}
                        style={{ marginRight: 8 }}
                      />
                      {t("Credit Card")}
                    </div>
                  }
                /> */}
                <FormControlLabel
                  value="Cash"
                  control={<Radio sx={{ color: theme.palette.primary.main }} />}
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        width="25px"
                        height={"25px"}
                        src={"../../assets/cashIcon.svg"}
                        alt={t("Cash")}
                        style={{ marginRight: 8 }}
                      />
                      {t("Cash")}
                    </div>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {/* //order */}
          <Grid
            container
            sx={{
              padding: "1.5rem",
              backgroundColor: themeMode === "dark" ? "#393744" : "#D3D3D3",
              borderRadius: "0.3rem",
            }}
          >
            <Grid item sm={12} xs={12}>
              <Box display="flex" justifyContent="space-between">
                <Typography fontFamily={`regular${i18n.language}`}>
                  {t("SubTotal")}
                </Typography>
                <Typography fontFamily={`regular${i18n.language}`}>
                  {numberTranslation(subTaxTotal, i18n.language)}{" "}
                  {t("currency.EGP")}
                </Typography>
              </Box>
              {/** Tax */}
              <Box display="flex" justifyContent="space-between">
                <Typography fontFamily={`regular${i18n.language}`}>
                  {t("tax")}
                </Typography>
                <Typography fontFamily={`regular${i18n.language}`}>
                  {/* {`${numberTranslation(Math.round(TAX * 100) % 100, i18n.language)} %`}{" "} */}
                  {Number(taxes)} {t("currency.EGP")}
                </Typography>
              </Box>
              {/* discount */}
              {discountedValue && (
                <Box display="flex" justifyContent="space-between">
                  <Typography fontFamily={`regular${i18n.language}`}>
                    {t("you saved")}
                  </Typography>
                  <Typography
                    fontFamily={`regular${i18n.language}`}
                    color="#33bde8"
                  >
                    <>
                      -{" "}
                      {numberTranslation(
                        Number(discountedValue).toFixed(2),
                        i18n.language
                      )}{" "}
                      {t("currency.EGP")}
                    </>
                  </Typography>
                </Box>
              )}
              {discount > 0 && (
                <Box display="flex" justifyContent="space-between">
                  <Typography fontFamily={`regular${i18n.language}`}>
                    {t("you saved")}
                  </Typography>
                  <Typography
                    fontFamily={`regular${i18n.language}`}
                    color="#33bde8"
                  >
                    <>
                      -{" "}
                      {numberTranslation(
                        Number(discount).toFixed(2),
                        i18n.language
                      )}{" "}
                      {t("currency.EGP")}
                    </>
                  </Typography>
                </Box>
              )}

              {channel === "delivery" && (
                <Box display="flex" justifyContent="space-between">
                  <Typography
                    // sx={{ color: "white" }}

                    fontFamily={`regular${i18n.language}`}
                  >
                    {t("Delivery")}
                  </Typography>
                  {deliveryFee !== null ? (
                    <Typography fontFamily={`regular${i18n.language}`}>
                      {numberTranslation(deliveryFee, i18n.language)}{" "}
                      {t("currency.EGP")}
                    </Typography>
                  ) : user ? (
                    !selectedAddress ? (
                      <Typography
                        onClick={() => navigate("/address")}
                        color={"#33BDE8"}
                        fontFamily={`regular${i18n.language}`}
                      >
                        {t("Please select address first")}
                      </Typography>
                    ) : (
                      <Typography
                        onClick={() => navigate("/address")}
                        color={"#33BDE8"}
                        fontFamily={`regular${i18n.language}`}
                      >
                        {t("Add address")}
                      </Typography>
                    )
                  ) : (
                    <Typography
                      onClick={() => navigate("/login")}
                      color={"#33BDE8"}
                      fontFamily={`regular${i18n.language}`}
                    >
                      {t("Login")}
                    </Typography>
                  )}
                </Box>
              )}
            </Grid>
            {/* discount */}
            {/* {discountedValue && (
              <Grid item sm={12} xs={12}>
                <Box display="flex" justifyContent="space-between">
                  <Typography fontFamily={`regular${i18n.language}`}>
                    {t("you saved")}
                  </Typography>
                  <Typography
                    fontFamily={`regular${i18n.language}`}
                    color="#33bde8"
                  >
                    <>
                      {numberTranslation(
                        Number(
                          Number(totalPrice) - Number(discountedValue)
                        ).toPrecision(2),
                        i18n.language
                      )}{" "}
                      {t("currency.EGP")}
                    </>
                  </Typography>
                </Box>
              </Grid>
            )} */}
            {/* total */}
            <Grid item sm={12} xs={12} sx={{ marginTop: "1rem" }}>
              <Box display="flex" justifyContent="space-between">
                <Typography fontFamily={`regular${i18n.language}`}>
                  {t("Total")}
                </Typography>
                <Typography fontFamily={`regular${i18n.language}`}>
                  {discountedValue ? (
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                      }}
                    >
                      <span
                        style={{
                          textDecoration: "line-through",
                          marginLeft: "0.5rem",
                        }}
                      >
                        {numberTranslation(totalPrice, i18n.language)}{" "}
                        {t("currency.EGP")}
                      </span>
                      <span
                        style={{
                          color: "#33bde8",
                        }}
                      >
                        {numberTranslation(
                          Number(totalPrice - discountedValue).toFixed(2),
                          i18n.language
                        )}{" "}
                        {t("currency.EGP")}
                      </span>
                    </Box>
                  ) : discount ? (
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                      }}
                    >
                      <span
                        style={{
                          textDecoration: "line-through",
                          marginLeft: "0.5rem",
                        }}
                      >
                        {numberTranslation(
                          Number(totalPrice + discount).toFixed(2),
                          i18n.language
                        )}{" "}
                        {t("currency.EGP")}
                      </span>
                      <span
                        style={{
                          color: "#33bde8",
                        }}
                      >
                        {numberTranslation(
                          Number(totalPrice).toFixed(2),
                          i18n.language
                        )}{" "}
                        {t("currency.EGP")}
                      </span>
                    </Box>
                  ) : (
                    <>
                      {numberTranslation(totalPrice, i18n.language)}{" "}
                      {t("currency.EGP")}
                    </>
                  )}
                </Typography>
              </Box>
            </Grid>

            {/* button */}
            <Grid item sm={12} xs={12}>
              <Button
                onClick={handleOrderPlacement}
                disabled={disableBtns || loading || !canOrder}
                sx={{
                  font: "0.4rem",
                  marginTop: "0.5rem",
                  padding: "1rem",
                  width: "100%",
                  fontFamily: `regular${i18n.language}`,
                  backgroundColor:
                    themeMode === "light" ? "#F07EAA" : "#33bde8",
                  "&:hover": {
                    backgroundColor: "#33bde8",
                  },
                }}
              >
                {t("PlaceMyOrder")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
export default Cart;
