import {
  Box,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-loading-skeleton/dist/skeleton.css";
import Categortycontainer from "../../components/categortycontainer";
import Foodcard from "../../components/foodcard";
import Searchinput from "../../components/searchinput";
import Sidebar from "../../components/sidebar/Sidebar";
import Voucherheader from "../../components/voucherheader";
import { Category, MenuItem as MenuItemType } from "../../models/Category";

//redux
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../components/Nav/Navbar";
import SplashScreen from "../../components/splashScreen/SplashScreen";
import { capitalization } from "../../constants/helperFunction";
import { getCart } from "../../services/cartServices";
import {
  getCartItemsQuantity,
  getLocalCart,
  mergeLocalAndOnlineCart,
  updateCartState,
  updateOfflineCart,
  updateOnlineCart,
} from "../../services/cartUtils";
import { fetchData } from "../../services/categoryPageUtils";
import {
  getAvailableConcepts,
  getNearestConcept,
  isConceptWithinZone,
} from "../../services/conceptUtils";
import {
  setCategories,
  setChannel,
  setHomeScroll,
  setSelectedConcept,
  setSplashScreen,
} from "../../state/index";
import ChangeChannelModal from "./changeChannelModal";
import InitialRedirect from "../../components/helper/InitialRedirect";

interface CategoryProps {
  changeIsChannelModalOpen?: (value: boolean) => void;
}

const labelStyle = (language: string): React.CSSProperties => {
  if (language === "Arabic") {
    return {
      transformOrigin: "right",
      left: "inherit",
      right: "1.75rem",
      overflow: "unset",
    };
  } else {
    return {};
  }
};
const CategoryPage = ({ changeIsChannelModalOpen }: CategoryProps) => {
  const [search, setSearch] = useState("" as string);
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [deliveryFee, setDeliveryFee] = useState<any>(0);
  const [isChangeChannelModalOpen, setIsChangeChannelModalOpen] =
    useState<boolean>(false);
  const selectedAddress = useSelector((state: any) => state.selectedAddress);
  const concept = useSelector((state: any) => state.concept);
  const zonesListing = useSelector((state: any) => state.zonesListing);
  const selectedConcept = useSelector((state: any) => state.selectedConcept);
  const forceSelectConcept = useSelector(
    (state: any) => state.forceSelectConcept
  );
  const fetchingCategoriesID = useSelector(
    (state: any) => state.fetchingCategoriesID
  );
  const categories = useSelector(
    (state: any) => state.categoriesState.listing || []
  );
  const user = useSelector((state: any) => state.user);
  const isFetchingUser = useSelector((state: any) => state.isFetchingUser);
  const [currentCartUser, setCurrentCartUser] = useState<any>(user);
  const splashScreen = useSelector((state: any) => state.splashScreen);
  const homeScroll = useSelector((state: any) => state.homeScroll);
  const channel = useSelector((state: any) => state.channel);
  const [displaySplashScreen, setDisplaySplashScreen] = useState(false);
  const handleSearch = (searchString: string) => {
    setSearch(searchString);
  };

  const filtredItems = useMemo(() => {
    if (!search) return categories;
    let items: MenuItemType[] = [];
    const returnedData: Category[] = [];
    categories.forEach((category: Category) => {
      category.menuItems.forEach((menuItem: MenuItemType) => {
        if (menuItem.name.toLowerCase().includes(search.toLowerCase())) {
          items.push(menuItem);
        }
      });
      if (items.length === 0) return;
      returnedData.push({ ...category, menuItems: items });
      items = [];
    });
    return returnedData;
  }, [search, categories]);

  const handleDisplaySplashScreen = (val: boolean) => {
    setDisplaySplashScreen(val);
  };
  useEffect(() => {
    if (!selectedConcept) return;
    if (isFetchingUser) return;
    if (!isFetchingUser && !user && channel !== "pick-up" && categories?.length)
      return;
    let currentConcept = { ...selectedConcept };
    if (displaySplashScreen) dispatch(setSplashScreen(true));
    const zone = zonesListing.find(
      (zone: any) => zone?.id === selectedAddress?.address?.zoneID
    );
    if (zone && channel !== "pick-up") {
      // Find the nearest concept based on zoneConcepts
      const availableConcepts = getAvailableConcepts(concept.listing);
      currentConcept = getNearestConcept(zone.concepts, availableConcepts);
    }
    if (!currentConcept) return;
    if (selectedConcept && selectedConcept.id !== currentConcept?.id) {
      dispatch(setSplashScreen(true));
      setDisplaySplashScreen(true);
    }

    if (
      selectedConcept &&
      selectedConcept.id !== currentConcept?.id &&
      isConceptWithinZone(zone?.concepts, selectedConcept)
    ) {
      currentConcept = selectedConcept;
    }

    if (categories?.length) {
      const firstMenuItemConceptID = categories?.[0]?.conceptID ?? "";
      if (!splashScreen && firstMenuItemConceptID === currentConcept.id) return;
    }

    fetchData(dispatch, currentConcept.id);
    setDisplaySplashScreen(false);

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      dispatch(setCategories([]));
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [selectedConcept, isFetchingUser]);

  useEffect(() => {
    if (!categories?.length) return;

    const firstMenuItemConceptID = categories?.[0]?.conceptID ?? "";

    if (firstMenuItemConceptID !== fetchingCategoriesID) return;

    dispatch(setSplashScreen(false));
    setDisplaySplashScreen(false);
  }, [categories]);

  useEffect(() => {
    if (!categories?.length) return;

    const firstMenuItemConceptID = categories?.[0]?.conceptID ?? "";

    if (firstMenuItemConceptID === fetchingCategoriesID) return;

    dispatch(setSplashScreen(true));
    setDisplaySplashScreen(true);
  }, [categories]);

  // useEffect(() => {
  //   if (!selectedConcept) return;
  //   if (isFetchingUser) return;
  //   let currentConcept = { ...selectedConcept };
  //   if (displaySplashScreen) dispatch(setSplashScreen(true));
  //   const zone = zonesListing.find(
  //     (zone: any) => zone?.id === selectedAddress?.address?.zoneID
  //   );
  //   if (zone && channel !== "pick-up") {
  //     currentConcept = concept.listing.find(
  //       (concept: any) => concept?.id === zone?.conceptID
  //     );
  //   }
  //   if (!currentConcept) return;
  //   if (selectedConcept && selectedConcept.id !== currentConcept?.id) {
  //     dispatch(setSplashScreen(true));
  //     setDisplaySplashScreen(true);
  //   }
  //   fetchData(dispatch, currentConcept.id);
  //   setDisplaySplashScreen(false);

  //   const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  //     dispatch(setCategories([]));
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, [selectedConcept, isFetchingUser]);

  const fetchCart = async (userID: string, conceptID: string) => {
    try {
      let existingCart: any = {};
      if (userID?.length > 0) {
        const response = await getCart(userID, conceptID);
        const onlineCart = response?.length === 0 ? {} : response[0];
        const localCart = { ...getLocalCart(conceptID) };

        existingCart = mergeLocalAndOnlineCart(
          localCart,
          onlineCart,
          selectedConcept.vatPercentage,
          selectedConcept.serviceChargePercentage,
          selectedConcept.addDeliveryToVat,
          "delivery" // not used
        );

        updateOfflineCart(
          [],
          0,
          conceptID,
          selectedConcept.vatPercentage,
          selectedConcept.serviceChargePercentage,
          selectedConcept.addDeliveryToVat,
          channel
        );

        if (
          onlineCart?.totalPrice !== existingCart?.totalPrice ||
          onlineCart?.totalQuantity !== existingCart?.totalQuantity
        ) {
          //  Cart was changed after the merge, update the online cart
          const updatedOnlineCart = await updateOnlineCart(
            existingCart.orderedItems,
            existingCart.totalPrice,
            userID,
            conceptID,
            selectedConcept.vatPercentage,
            selectedConcept.serviceChargePercentage,
            selectedConcept.addDeliveryToVat,
            channel
          );
        }
      } else {
        existingCart = getLocalCart(conceptID);
      }
      const totalQuantity = getCartItemsQuantity(existingCart);
      updateCartState(
        existingCart?.orderedItems || [],
        totalQuantity,
        existingCart?.subTaxTotal || 0,
        deliveryFee || 0,
        dispatch,
        selectedConcept.vatPercentage,
        selectedConcept.serviceChargePercentage,
        selectedConcept.addDeliveryToVat,
        channel,
        "",
        userID.length > 0
      );
    } catch (e: any) {
      console.log(e);
    }
  };
  const handleChannelChange = (event: any) => {
    dispatch(setChannel(event.target.value));
  };
  const handleOpenChangeChannelModal = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setIsChangeChannelModalOpen(true);
    changeIsChannelModalOpen?.(true);
  };
  const handleCloseChangeChannelModal = () => {
    setIsChangeChannelModalOpen(false);
    changeIsChannelModalOpen?.(false);
  };

  useEffect(() => {
    if (currentCartUser?.id === user?.id && user) return;
    setCurrentCartUser(user);
  }, [user]);

  useEffect(() => {
    const userID = currentCartUser ? currentCartUser.id : "";
    if (!selectedConcept) return;
    fetchCart(userID, selectedConcept.id);
  }, [currentCartUser, selectedConcept]);

  useEffect(() => {
    const handleScroll = () => {
      dispatch(setHomeScroll({ x: window.scrollX, y: window.scrollY }));
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo({
      top: homeScroll.y,
      left: homeScroll.x,
      behavior: "instant" as ScrollBehavior,
    });
  }, []);

  useEffect(() => {
    if (channel === "pick-up") return;
    if (
      !zonesListing.length ||
      !concept.listing?.length ||
      !selectedAddress?.address
    )
      return;
    const zone = zonesListing.find(
      (zone: any) => zone?.id === selectedAddress?.address?.zoneID
    );

    // Find the nearest concept based on zoneConcepts
    const availableConcepts = getAvailableConcepts(concept.listing);

    const conceptSelected = getNearestConcept(zone.concepts, availableConcepts);

    // In case the selected concept isnot the nearest concept but is within this zone
    if (
      selectedConcept &&
      selectedConcept.id !== conceptSelected?.id &&
      isConceptWithinZone(zone?.concepts, selectedConcept) &&
      forceSelectConcept
    ) {
      return;
    }

    if (selectedConcept && selectedConcept.id !== conceptSelected?.id) {
      dispatch(setSplashScreen(true));
      setDisplaySplashScreen(true);
    }
    dispatch(setSelectedConcept(conceptSelected));
  }, [
    selectedAddress?.address,
    zonesListing?.length,
    concept?.listing?.length,
    selectedConcept,
  ]);

  // useEffect(() => {
  //   if (channel === "pick-up") return;
  //   if (
  //     !zonesListing.length ||
  //     !concept.listing?.length ||
  //     !selectedAddress?.address
  //   )
  //     return;
  //   const zone = zonesListing.find(
  //     (zone: any) => zone?.id === selectedAddress?.address?.zoneID
  //   );
  //   const conceptSelected = concept.listing.find(
  //     (concept: any) => concept?.id === zone?.conceptID
  //   );
  //   if (selectedConcept && selectedConcept.id !== conceptSelected?.id) {
  //     dispatch(setSplashScreen(true));
  //     setDisplaySplashScreen(true);
  //   }
  //   dispatch(setSelectedConcept(conceptSelected));
  // }, [
  //   selectedAddress?.address,
  //   zonesListing?.length,
  //   concept?.listing?.length,
  //   selectedConcept,
  // ]);

  function scrollToCategory(categoryId: any) {
    const targetElement = document.getElementById(categoryId);
    if (targetElement) {
      // const targetPosition = targetElement.offsetTop - 180;
      const targetPosition = targetElement.offsetTop - 270;
      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      });
    }
  }

  useEffect(() => {
    if (splashScreen) {
      window.scrollTo({ top: 0, behavior: "instant" as ScrollBehavior });
    }
  }, [splashScreen]);

  return (
    <Box>
      {splashScreen && <SplashScreen />}
      {!splashScreen && <InitialRedirect />}

      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingBottom: "3rem",
          position: "relative",
          visibility: splashScreen ? "hidden" : "visible",
        }}
      >
        <Grid
          item
          sx={{
            minWidth: "100%",
            pt: "1rem",
            position: "sticky",
            top: 0,
            zIndex: 5,
            bgcolor: theme.palette.background.default,
          }}
        >
          <FormControl focused={false} fullWidth>
            <InputLabel
              style={{
                color: theme.palette.primary.main,
                fontSize: 12,
                ...labelStyle(i18n.language),
              }}
              // sx={{
              //   "&."
              // }}
              id="demo-simple-select-label"
            >
              {t("channel")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Channel"
              value={channel}
              onChange={handleChannelChange}
              onClick={handleOpenChangeChannelModal}
              inputProps={{ readOnly: true }} // Use readOnly to prevent dropdown from opening
              sx={{
                direction: i18n.language === "Arabic" ? "rtl" : "ltr",
                maxHeight: "2.7rem",
                width: "100%", // Take full width
                boxShadow: "none", // Remove shadow
                color: theme.palette.primary.main,
                fontSize: 12,
                backgroundColor: "rgba(57, 55, 68, 0.28)",
                // border: `2px solid ${theme.palette.primary.dark}`,
                "& .MuiSelect-icon": {
                  color: theme.palette.primary.main,
                },
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    backgroundColor: theme.palette.primary.dark,
                  },
                },
              }}
            >
              <MenuItem value={"delivery"}>{t("delivery")}</MenuItem>
              <MenuItem value={"pick-up"}>{t("Pick-up")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Sidebar />
        {!splashScreen && selectedConcept?.sliderImages.length > 0 && (
          <Voucherheader />
        )}
        <Searchinput handleSearch={handleSearch} />

        <Stack sx={{ width: "100%", padding: "5px 5px" }} direction="row">
          <Typography
            variant="h1"
            color={theme.palette.primary.main}
            fontSize={"18px"}
            fontWeight={"500"}
          >
            {t("Categories")}
          </Typography>
        </Stack>

        <Stack
          direction="row"
          sx={{
            overflowX: "auto",
            overflowY: "hidden",
            paddingTop: ".5rem",
            paddingBottom: ".5rem",
            backgroundColor: theme.palette.background.default,
            width: "100%",
            maxWidth: "100%",
            position: "sticky",
            top: "8rem",
            zIndex: 4,
          }}
          justifyContent={"start"}
        >
          {categories.map((category: Category) => (
            <Box
              onClick={() => scrollToCategory(category.id)}
              key={category.id}
            >
              <Categortycontainer category={category} />
            </Box>
          ))}
        </Stack>

        {filtredItems && filtredItems.length > 0 ? (
          filtredItems.map((category: any) =>
            category.menuItems && category.menuItems.length > 0 ? (
              <Box id={category.id} key={category.id} sx={{ width: "100%" }}>
                <Typography
                  mb={"5px"}
                  ml={"14px"}
                  fontSize={"20px"}
                  fontWeight={"800px"}
                  fontFamily={`bold${i18n.language}`}
                  color={theme.palette.primary.main}
                  variant="h6"
                >
                  {capitalization(
                    new Map(JSON.parse(category.multiLanguages))?.get(
                      `${i18n.language}-${"name"}`
                    ) ?? category.name
                  )}
                </Typography>
                <Grid container sx={{ marginBottom: "1rem" }}>
                  {category.menuItems.map((menuItem: any) => (
                    <Grid item xs={6} sm={6} md={3} key={menuItem.id}>
                      <Foodcard
                        name={capitalization(
                          new Map(JSON.parse(menuItem.multiLanguages))?.get(
                            `${i18n.language}-${"name"}`
                          ) ?? menuItem.name
                        )}
                        image={menuItem.image}
                        description={capitalization(
                          new Map(JSON.parse(menuItem.multiLanguages))?.get(
                            `${i18n.language}-${"description"}`
                          ) ?? menuItem.description
                        )}
                        newItem={menuItem.newItem}
                        prices={menuItem.prices}
                        item={menuItem}
                        categoryID={category.id}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ) : null
          )
        ) : (
          <Typography
            variant="body1"
            sx={{ fontFamily: `bold${i18n.language}` }}
          >
            {t("NoMatching")}
          </Typography>
        )}
      </Container>

      <Grid container>
        {isChangeChannelModalOpen && !splashScreen && (
          <div className="overlay">
            <ChangeChannelModal
              onClose={handleCloseChangeChannelModal}
              handleChannelStateChange={handleChannelChange}
              handleDisplaySplashScreen={handleDisplaySplashScreen}
            />
          </div>
        )}
      </Grid>
      {!isChangeChannelModalOpen && <Navbar />}
    </Box>
  );
};

export default CategoryPage;
