import React, { ComponentType, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Higher-order component to check for authentication
function withAuth<P extends object>(WrappedComponent: ComponentType<P>) {
  const ComponentWithAuth: React.FC<P> = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { hash, pathname, search } = location;
    // Simulate a function that checks authentication status
    const isAuthenticated = () => {
      // Replace this with your actual authentication check logic
      return !!localStorage.getItem("user");
    };

    useEffect(() => {
      if (isAuthenticated()) {
        navigate("/");
      }
    }, [pathname]);

    // If authenticated, return null as we are redirecting
    if (isAuthenticated()) {
      return null; // You can return a loading spinner if needed
    }

    // If not authenticated, render the wrapped component
    return <WrappedComponent {...(props as P)} />;
  };

  return ComponentWithAuth;
}

export default withAuth;
