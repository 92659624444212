import "./Redeem.css"
import { useState, useRef, useEffect, MouseEvent } from "react"
import CloseIcon from "@mui/icons-material/Close"
import { Grid, Card, useTheme, Typography, Box, Button, IconButton } from "@mui/material"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import RewardOtp from "./RewardOtp"
import { useDispatch, useSelector } from "react-redux"
import { createLoyaltyRedeemOtp } from "../../services/loyaltyServices"
import { capitalization, numberTranslation } from "../../constants/helperFunction"
import { useTranslation } from "react-i18next"
import { cloudinaryURL } from "../../constants/Enums"
import * as cartServices from "../../services/cartServices"
import * as cartUtils from "../../services/cartUtils"
import { setLoyaltyDiscount } from "../../state"
import { Navigate, useNavigate } from "react-router-dom"
interface RewardCardProps {
    onClose: (event?: any) => void
    onShowOtp: () => void
    onCloseOtp: () => void
    handleSetOtp: (otp: string) => void
    name: string
    description: string
    points: number
    id: string
    redemptionPerUser: number
    multiLanguages: any
    image: string
    loyaltyItem: any
}

function RewardCard({
    onClose,
    onShowOtp,
    onCloseOtp,
    handleSetOtp,
    id,
    name,
    description,
    points,
    redemptionPerUser,
    multiLanguages,
    image,
    loyaltyItem,
}: RewardCardProps) {
    const theme = useTheme()
    const { t, i18n } = useTranslation()
    const user = useSelector((state: any) => state.user)
    const concept = useSelector((state: any) => state.selectedConcept)
    const items: any[] = useSelector((state: any) => state.cart.orderedItems || [])
    const navigate = useNavigate()
    const themeMode = useSelector((state: any) => state.mode)
    const [disableRedeem, setDisableRedeem] = useState<boolean>(
        (user?.points ? user.points : 0) >= points ? false : true
    )
    const [redeemError, setRedeemError] = useState<string>("")

    const totalQuantity = useSelector((state: any) => state.cart.quantity || 0)

    const subTaxTotal = useSelector((state: any) => state.cart.subTaxTotal || 0)

    const selectedConcept = useSelector((state: any) => state.selectedConcept)
    const channel = useSelector((state: any) => state.channel)
    // const selectedZone = useSelector((state: any) => state.selectedZone);
    const [deliveryFee, setDeliveryFee] = useState<any>(null)

    const [hasLoyalty, setHasLoyalty] = useState(false)

    useEffect(() => {
        if (items && items?.length > 0) {
            const loyalty = items.filter((item) => item.isLoyalty)
            if (loyalty && loyalty.length > 0) setHasLoyalty(true)
        }
    }, [items])
    const dispatch = useDispatch()

    const addLoyaltyItem = async () => {
        if (user) {
            const loyaltyCartItem = {
                menuItemID: loyaltyItem.id,
                symphonyID: loyaltyItem.symphonyID,
                preparationAreaID: null,
                name: loyaltyItem.name,
                quantity: 1,
                choiceIDs: [],
                choiceNames: [],
                choiceSymphonyID: [],
                extraChoiceIDs: [],
                extraChoiceSymphonyID: [],
                price: loyaltyItem.price ?? 150,
                priceName: null,
                priceID: null,
                priceMultiLanguages: loyaltyItem.multiLanguages,
                isLoyalty: true,
                multiLanguages: null,
                notes: "",
                image: loyaltyItem.image,
            }

            const updatedItems = [...items, loyaltyCartItem]

            // Update Redux immediately
            const newSubTotal = cartUtils.getCartSubTotal(subTaxTotal, selectedConcept.serviceChargePercentage, channel)
            const loyaltyDiscount = cartUtils.getCartLoyaltyDiscount(updatedItems)

            cartUtils.updateCartState(
                updatedItems,
                totalQuantity + 1, // Increase quantity
                newSubTotal,
                deliveryFee,
                dispatch,
                selectedConcept.vatPercentage,
                selectedConcept.serviceChargePercentage,
                selectedConcept.addDeliveryToVat,
                channel
            )

            dispatch(setLoyaltyDiscount(loyaltyDiscount)) // Update discount immediately

            await cartUtils.updateOnlineCart(
                updatedItems,
                deliveryFee,
                user.id,
                selectedConcept.id,
                selectedConcept.vatPercentage,
                selectedConcept.serviceChargePercentage,
                selectedConcept.addDeliveryToVat,
                channel
            )

            navigate("/")
        }
    }

    const handleGenerateOtp = async () => {
        try {
            setDisableRedeem(true)
            setRedeemError("")
            if ((user.points ? user.points : 0) < points) return
            const generatedOtp = await createLoyaltyRedeemOtp(
                user.id,
                id,
                redemptionPerUser,
                new Date().toISOString(),
                concept.id
            )
            if (!generatedOtp?.id) {
                setRedeemError(t("Sorry, you have reached your maximum redemptions for this item"))
                setDisableRedeem(false)
                return
            }
            handleSetOtp(generatedOtp.OTP)
            onShowOtp()
            setDisableRedeem(false)
        } catch (err: any) {
            console.log(err)
            setRedeemError(err)
            setDisableRedeem(false)
        }
    }

    useEffect(() => {
        setDisableRedeem((user?.points ? user.points : 0) >= points ? false : true)
    }, [user, points])

    const handleClickOutside = (event: any) => {
        if (event.target.className === "redeem-overlay") {
            onClose()
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [])

    return (
        <Box>
            <div className="redeem-overlay">
                <Box
                    display="flex"
                    flexDirection="column"
                    className="redeem-container"
                    sx={{ backgroundColor: themeMode === "light" ? "#1f1c2b" : "white" }}
                >
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ marginY: "1rem", marginX: "1rem" }}
                    >
                        <ArrowBackIosNewIcon sx={{ color: "#33BDE8", fontSize: "1rem" }} onClick={onClose} />
                        <Box>
                            <IconButton onClick={onClose}>
                                <CloseIcon sx={{ color: theme.palette.primary.main }} />
                            </IconButton>
                        </Box>
                    </Box>

                    {/* <Grid container sx={{ width: '100%', height: '100%' }}> */}
                    {/* <Box
          sx={{
            // padding: '4px',
            boxSizing: 'border-box',
            width: '100%',
            height: '100%',
          }}
        > */}
                    <Card
                        sx={{
                            backgroundColor: theme.palette.primary.dark,
                            width: "100%",
                            height: "100%",
                            borderRadius: "20px",
                            boxShadow: " 0px 4px 18px 0px rgba(0, 0, 0, 0.12)",
                        }}
                    >
                        <Box display={"flex"} flexDirection={"column"} justifyContent={"center"}>
                            <Box
                                display={"flex"}
                                justifyContent={"center"}
                                sx={{
                                    position: "relative",
                                    backgroundColor: (user?.points ? user.points : 0) >= points ? "white" : "grey",
                                    width: "100%",
                                    borderRadius: "20px",
                                    overflow: "hidden",
                                    border: "0.1rem solid #33BDE8",
                                }}
                            >
                                <img
                                    style={{
                                        // objectFit: 'contain',
                                        objectFit: "cover",
                                        width: "100%",
                                        aspectRatio: "2/1",
                                        opacity: user?.points ? user.points : 0 >= points ? 1 : 0.4,
                                    }}
                                    src={`${cloudinaryURL}${image}`}
                                    alt="item"
                                />

                                {(user?.points ? user.points : 0) >= points ? (
                                    ""
                                ) : (
                                    <Box
                                        style={{
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            color: "white",
                                            fontSize: "48px",
                                        }}
                                    >
                                        <img alt="lock" src="./assets/lock.png" />
                                    </Box>
                                )}
                            </Box>
                            <Box sx={{ padding: "0px 3px", paddingBottom: "5px" }}>
                                <Box
                                    display={"flex"}
                                    flexDirection={"column"}
                                    justifyContent={"space-between"}
                                    sx={{ padding: "10px 5px" }}
                                    padding={"1rem"}
                                >
                                    <Typography
                                        variant="h6"
                                        color={theme.palette.primary.main}
                                        fontSize={"20px"}
                                        fontWeight={"500"}
                                        ml={"1rem"}
                                    >
                                        {t("Enjoy a FREE", {
                                            name: capitalization(
                                                String(
                                                    new Map(JSON.parse(multiLanguages))?.get(`${i18n.language}-name`) ||
                                                        []
                                                ) ?? name
                                            ),
                                        })}
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        color={theme.palette.primary.main}
                                        fontSize={"18px"}
                                        fontWeight={"500"}
                                        ml={"1rem"}
                                    >
                                        {t("Get it now for", {
                                            points: numberTranslation(points, i18n.language),
                                        })}
                                    </Typography>

                                    <Typography
                                        variant="body2"
                                        color={"#9B9B9B"}
                                        fontSize={"15px"}
                                        fontWeight={"400"}
                                        ml={"1rem"}
                                        mt={"1rem"}
                                        mb={"0rem"}
                                    >
                                        {String(
                                            new Map(JSON.parse(multiLanguages))?.get(`${i18n.language}-descriptions`) ||
                                                []
                                        ) ?? description}
                                    </Typography>
                                </Box>
                                <Box display="flex" justifyContent="center" height={"3rem"} mb={"1rem"}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        disabled={disableRedeem || hasLoyalty}
                                        onClick={addLoyaltyItem}
                                        size="small"
                                        sx={{
                                            marginY: "0rem",
                                            fontSize: 22,
                                            width: "70%",
                                            color: "#FFFFFF",
                                            background:
                                                "linear-gradient(90deg, rgba(240,126,165,1) 16%, rgba(51,189,232,1) 70%)",
                                            boxShadow: "none",
                                            transition: "box-shadow 0.3s",
                                            borderRadius: "1rem",
                                            padding: "1rem",
                                            "&:hover": {
                                                boxShadow: "0px 0px 5px 2px rgb(240,126,165,0.7)",
                                                background:
                                                    "linear-gradient(90deg, rgba(240,126,165,1) 16%, rgba(51,189,232,1) 70%)",
                                            },
                                        }}
                                    >
                                        {hasLoyalty ? `${t("Redeemed")}` : `${t("Redeem")} ${t("now")}`}
                                    </Button>
                                </Box>
                                {redeemError?.length > 0 && (
                                    <Box display={"flex"} justifyContent={"center"} paddingLeft={2} paddingRight={2}>
                                        <Typography sx={{ color: "red" }}>{t("redeemError")}</Typography>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Card>
                    {/* </Box> */}
                    {/* </Grid> */}
                </Box>
            </div>
        </Box>
    )
}

export default RewardCard
