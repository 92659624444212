import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  CssBaseline,
  IconButton,
  Snackbar,
  ThemeProvider,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { generateClient } from "aws-amplify/api";
import {
  FetchUserAttributesOutput,
  fetchUserAttributes,
  getCurrentUser,
  signOut,
} from "aws-amplify/auth";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import OTP from "./components/Modals/OTP";
import PhoneNumber from "./components/Modals/PhoneNumber";
import Navbar from "./components/Nav/Navbar";
import RoutesContainer from "./containers/RoutesContainer";
import {
  onUpdateCart,
  onUpdateConcept,
  onUpdateMenuItem,
} from "./graphql/subscriptions";
import { getCartItemsQuantity, updateCartState } from "./services/cartUtils";
import { fetchConcepts } from "./services/categoryPageUtils";
import {
  getAvailableConcepts,
  getNearestConcept,
} from "./services/conceptUtils";
import createUser from "./services/createUser";
import { getCategoryData, getUserByPhoneNumber } from "./services/getOperation";
import listZones from "./services/listZones";
import updateUser from "./services/updateUser";
import {
  setCategories,
  setIsFetchingUser,
  setLogin,
  setSelectedAddress,
  setSelectedConcept,
  setSelectedItem,
  setZonesListing,
} from "./state";
import { themeSettings } from "./theme/theme";
import RouteTracker from "./components/helper/RouteTracker";

const API = generateClient();

interface LoggedInUser extends FetchUserAttributesOutput {
  username: string;
  group: string;
}

const App = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user);
  const selectedConcept = useSelector((state: any) => state.selectedConcept);
  const [isChangeChannelModalOpen, setIsChangeChannelModalOpen] =
    useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const mode = useSelector((state: any) => state.mode);
  const selectedAddress = useSelector((state: any) => state.selectedAddress);
  const splashScreen = useSelector((state: any) => state.splashScreen);
  const theme = useMemo(
    () => createTheme(themeSettings(mode, splashScreen)),
    [mode, splashScreen]
  );
  const location = useLocation();
  const [openPhoneNumber, setOpenPhoneNumber] = useState<boolean>(false);
  const [checkCode, setCheckCode] = useState<any>(null);
  const [openOTP, setOpenOTP] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [validationWarning, setValidationWarning] = useState<boolean>(false);
  const [message, setMessage] = useState<any>("");
  const [success, setSuccess] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [group, setGroup] = useState<string>("");
  const [loginUser, setLoginUser] = useState<any>(null);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const categories =
    useSelector((state: any) => state.categoriesState.listing) || [];
  const selectedItem = useSelector((state: any) => state.selectedItem) || null;
  const concept = useSelector((state: any) => state.concept);
  const channel = useSelector((state: any) => state.channel);
  const items: any[] =
    useSelector((state: any) => state.cart.orderedItems) || [];
  const totalQuantity = useSelector((state: any) => state.cart.quantity || 0);
  const totalPrice = useSelector((state: any) => state.cart.total || 0);
  const subTaxTotal = useSelector((state: any) => state.cart.subTaxTotal || 0);
  const subTotal = useSelector((state: any) => state.cart.subTotal || 0);
  const specialRequest = useSelector((state: any) => state.cart.specialRequest);
  const notes = useSelector((state: any) => state.cart.notes);
  const deliveryFee = useSelector((state: any) => state.cart.deliveryFee);
  const promocode = useSelector((state: any) => state.promocode);
  const cart = useSelector((state: any) => state.cart);
  const Concept = useSelector((state: any) => state.concept);
  const zonesListing = useSelector((state: any) => state.zonesListing);
  const isFetchingUser = useSelector((state: any) => state.isFetchingUser);
  const [userInitialFetch, setUserInitialFetch] = useState(false);

  const showNavbar = ![
    "/login",
    "/register",
    "/orders",
    "/login/",
    "/rewards",
  ].includes(location.pathname);

  const handleOpenPhoneNumber = (isPhoneNumberOpen: boolean): void => {
    setOpenPhoneNumber(isPhoneNumberOpen);
  };

  const handleSuccessfulOTP = async () => {
    try {
      setLoading(true);
      let currentUser: any = await getUserByPhoneNumber(data.phoneNumber);
      currentUser = currentUser.items[0];
      if (currentUser) {
        if (!currentUser.status) {
          dispatch(setLogin({ user: currentUser }));
          let newUser = await updateUser({
            userID: currentUser.id,
            version: currentUser._version,
            email: currentUser.email || loginUser.email,
            name: currentUser.group ? currentUser.name : loginUser.name,
            phone_number: currentUser.phone_number,
            address: currentUser.address,
            status: "registered",
            birthdate: currentUser.birthdate,
            gender: currentUser.gender,
            picture: currentUser.picture,
            pictureCrop: currentUser.pictureCrop,
            phones: currentUser.phones,
            deleted: currentUser.deleted,
            createdAt: currentUser.createdAt,
            email_verified: true,
            phone_number_verified: true,
            group: loginUser.group,
            resource: currentUser,
            cognitoUsername: loginUser.username,
            updated: "true",
          });

          dispatch(setLogin({ user: newUser }));
        } else if (
          currentUser.status &&
          currentUser.cognitoUsername === loginUser.username
        ) {
          currentUser.group = loginUser.group;
          dispatch(setLogin({ user: currentUser }));
          let newUser = await updateUser({
            userID: currentUser.id,
            version: currentUser._version,
            email: currentUser.email,
            name: currentUser.group ? currentUser.name : loginUser.name,
            phone_number: currentUser.phone_number,
            address: currentUser.address,
            status: "registered",
            birthdate: currentUser.birthdate,
            gender: currentUser.gender,
            picture: currentUser.picture,
            pictureCrop: currentUser.pictureCrop,
            phones: currentUser.phones,
            deleted: currentUser.deleted,
            createdAt: currentUser.createdAt,
            email_verified: true,
            phone_number_verified: true,
            group: loginUser.group,
            resource: currentUser,
            cognitoUsername: loginUser.username,
            updated: "true",
          });

          dispatch(setLogin({ user: newUser }));
        } else {
          localStorage.removeItem("user");
          dispatch(setLogin({ user: null }));
          await signOut();
          setSuccess(false);
          setMessage(t("User with this phone number already exist"));
          setValidationWarning(true);
          return;
        }
      } else {
        let newUser = await createUser(
          loginUser,
          loginUser.group,
          "+2" + data.phoneNumber,
          true,
          true,
          {
            cognitoUsername: loginUser.username,
            updated: "true",
          }
        );

        newUser.group = loginUser.group;
        dispatch(setLogin({ user: newUser }));
      }
      // update user phone number - not working - replaced with a triggered lamda
      // try {
      //   await updateUserAttributes({
      //     userAttributes: {
      //       phone_number: "+2" + data.phoneNumber,
      //     },
      //   });
      //   // await updateUserAttribute({
      //   //     userAttribute: {
      //   //         attributeKey:"phone_number",
      //   //         value:"+2"+data.phoneNumber
      //   //     }
      //   // });
      // } catch (error: any) {
      //   console.log("error updating user attributes: ", error.message);
      //   setSuccess(false);
      //   setMessage(error.message);
      //   setValidationWarning(true);
      // }
    } catch (error: any) {
      setLoading(false);
      setSuccess(false);
      setMessage(error.message);
      setValidationWarning(true);
    } finally {
      setLoading(false);
      setOpenOTP(false);
    }
  };

  const fetchUser = async () => {
    if (!user) {
      try {
        dispatch(setIsFetchingUser(true));
        let [loggedInUser, userDetails] = await Promise.all([
          fetchUserAttributes() as Promise<LoggedInUser>,
          getCurrentUser(),
        ]);

        let userGroup: string | null = null;
        if (
          loggedInUser.identities &&
          loggedInUser.identities.toString().includes("Google")
        ) {
          userGroup = "Google";
        } else if (
          loggedInUser.identities &&
          loggedInUser.identities.toString().includes("Apple")
        ) {
          userGroup = "Apple";
        } else {
          userGroup = "Cognito";
        }
        loggedInUser.group = userGroup;
        loggedInUser.username = userDetails.username;
        setLoginUser(loggedInUser);
        if (loggedInUser?.phone_number) {
          let currentUser: any = await getUserByPhoneNumber(
            loggedInUser.phone_number.substring(
              2,
              loggedInUser?.phone_number.length + 1
            )
          );
          setUserInitialFetch(true);
          currentUser = currentUser.items[0];
          localStorage.setItem("user", "true");

          if (
            loggedInUser.phone_number &&
            currentUser &&
            currentUser.phone_number_verified
          ) {
            if (currentUser) {
              currentUser.group = userGroup;
              dispatch(setLogin({ user: currentUser }));
              if (!currentUser.status) {
                let newUser = await updateUser({
                  userID: currentUser.id,
                  version: currentUser._version,
                  email: currentUser.email,
                  name: currentUser.name,
                  phone_number: currentUser.phone_number,
                  address: currentUser.address,
                  status: "registered",
                  birthdate: currentUser.birthdate,
                  gender: currentUser.gender,
                  picture: currentUser.picture,
                  pictureCrop: currentUser.pictureCrop,
                  phones: currentUser.phones,
                  deleted: currentUser.deleted,
                  createdAt: currentUser.createdAt,
                  email_verified: true,
                  phone_number_verified: true,
                  group: userGroup,
                  resource: currentUser,
                });
                newUser.group = userGroup;
                dispatch(setLogin({ user: newUser }));
              }
            } else {
              // currentUser.group=group;
              // console.log(
              //   `loggedInUserBefCreate: ${JSON.stringify(loggedInUser)}`
              // );
              let newUser = await createUser(
                loggedInUser,
                userGroup,
                loggedInUser.phone_number,
                true,
                true,
                {
                  cognitoUsername: loggedInUser.username,
                  updated: "false",
                }
              );
              newUser.group = userGroup;
              dispatch(setLogin({ user: newUser }));
            }
          } else if (currentUser && !currentUser?.phone_number_verified) {
            // console.log("currentUser && !currentUser?.phone_number_verified");
            if (currentUser.phone_number?.length > 0) {
              setPhoneNumber(currentUser.phone_number);
            }
            handleOpenPhoneNumber(true);
          } else if (currentUser && currentUser.phone_number_verified) {
            dispatch(setLogin({ user: currentUser }));
          } else if (loggedInUser.phone_number && !currentUser) {
            setPhoneNumber(loggedInUser.phone_number);
            handleOpenPhoneNumber(true);
          }
        } else if (loggedInUser && !loggedInUser.phone_number) {
          handleOpenPhoneNumber(true);
        }
        dispatch(setIsFetchingUser(false));
      } catch (e: any) {
        console.log(e);

        console.log("not logged in");
        localStorage.removeItem("user");
        dispatch(setLogin({ user: null }));
        dispatch(setIsFetchingUser(false));
        setUserInitialFetch(true);
      }
    } else if (isFetchingUser) {
      dispatch(setIsFetchingUser(false));
    }
  };

  const fetchCategories = async (conceptID: string) => {
    const fetchedCategories = await getCategoryData(conceptID);
    dispatch(setCategories(fetchedCategories));
    return fetchedCategories;
  };

  const getUpdatedMenuItemCompleteData = (
    updatedCategories: any,
    updatedMenuItem: any
  ) => {
    let updatedMenuItemCompleteData: any = null;
    for (const category of updatedCategories) {
      const currentMenuItems = category.menuItems;
      for (const menuItem of currentMenuItems) {
        if (menuItem.id === updatedMenuItem.id) {
          updatedMenuItemCompleteData = menuItem;
          break;
        }
      }
      if (updatedMenuItemCompleteData) {
        break;
      }
    }
    return updatedMenuItemCompleteData;
  };

  // useEffect(() => {
  //   fetchUser();
  //   if (user && user.address && !selectedAddress && channel === "delivery") {
  //     // added channel check here trying to prevent changing concept menu bug

  //     const userAddressesAll = JSON.parse(user.address);

  //     const userAddresses = userAddressesAll.filter((address: any) => {
  //       const zone = zonesListing.find(
  //         (zone: any) => zone?.id === address?.zoneID
  //       );
  //       return Concept?.listing?.some(
  //         (concept: Concept) => concept.id === zone?.conceptID
  //       );
  //     });

  //     dispatch(setSelectedAddress({ address: userAddresses[0] }));
  //   }
  // }, [user]);

  useEffect(() => {
    if (!user && !isFetchingUser && userInitialFetch) return;
    fetchUser();

    if (user && user.address && !selectedAddress && channel === "delivery") {
      // Parse user addresses from the stored JSON string
      const userAddressesAll = JSON.parse(user.address);

      // Filter the user's addresses based on the availability of matching zones and concepts
      const userAddresses = userAddressesAll.filter((address: any) => {
        // Find the corresponding zone for the address
        const zone = zonesListing.find(
          (zone: any) => zone?.id === address?.zoneID
        );

        // If the zone exists and has zoneConcepts, find the available concepts
        if (zone?.concepts?.length) {
          const availableConcepts = getAvailableConcepts(Concept.listing);
          const nearestConcept = getNearestConcept(
            zone.concepts,
            availableConcepts
          );

          // Return true if there is a matching concept in the zone
          return nearestConcept !== undefined;
        }

        return false;
      });
      // Dispatch the first matching address if any
      if (userAddresses.length > 0) {
        dispatch(setSelectedAddress({ address: userAddresses[0] }));
      }
    }
  }, [user, zonesListing]);

  const fetchZones = async () => {
    let zones = await listZones();
    dispatch(setZonesListing(zones));
  };
  useEffect(() => {
    if (zonesListing.length === 0) {
      fetchZones();
    }
  }, []);

  // useEffect(() => {
  //   if (user && user.address && channel === "delivery") {
  //     const userAddressesAll = JSON.parse(user.address);

  //     const userAddresses = userAddressesAll.filter((address: any) => {
  //       const zone = zonesListing.find(
  //         (zone: any) => zone?.id === address?.zoneID
  //       );
  //       return Concept?.listing?.some(
  //         (concept: Concept) => concept.id === zone?.conceptID
  //       );
  //     });

  //     dispatch(setSelectedAddress({ address: userAddresses[0] }));
  //   } else {
  //     dispatch(setSelectedAddress(null));
  //   }
  // }, [channel]);

  useEffect(() => {
    if (user && user.address && channel === "delivery") {
      // Parse user addresses from the stored JSON string
      const userAddressesAll = JSON.parse(user.address);

      // Filter the user's addresses based on the availability of matching zones and concepts
      const userAddresses = userAddressesAll.filter((address: any) => {
        // Find the corresponding zone for the address
        const zone = zonesListing.find(
          (zone: any) => zone?.id === address?.zoneID
        );

        // If the zone exists and has zoneConcepts, find the available concepts
        if (zone?.concepts?.length) {
          const availableConcepts = getAvailableConcepts(Concept.listing);
          const nearestConcept = getNearestConcept(
            zone.concepts,
            availableConcepts
          );
          // Return true if there is a matching concept in the zone
          return nearestConcept !== undefined;
        }
        return false;
      });

      // Dispatch the first matching address if any
      if (userAddresses.length > 0) {
        dispatch(setSelectedAddress({ address: userAddresses[0] }));
      } else {
        dispatch(setSelectedAddress(null));
      }
    } else {
      dispatch(setSelectedAddress(null));
    }
  }, [channel]);

  useEffect(() => {
    if (concept?.listing?.length) return;

    if (isInitialRender) {
      fetchConcepts(dispatch, selectedConcept);
      setIsInitialRender(false);
    }
  }, [concept.listing, isInitialRender]);

  useEffect(() => {
    if (!selectedConcept) return;

    const subscription = API.graphql({ query: onUpdateConcept });

    if ("subscribe" in subscription) {
      const orderSubscription = subscription.subscribe({
        next: (event: any) => {
          const updatedConcept = event.data.onUpdateConcept;

          if (updatedConcept.id === selectedConcept.id) {
            dispatch(setSelectedConcept(updatedConcept));
          }
        },
        error: (error: any) => {
          console.error("Subscription error:", error);
        },
      });

      return () => {
        if ("unsubscribe" in orderSubscription) {
          orderSubscription.unsubscribe();
        }
      };
    }
  }, [selectedConcept]);

  useEffect(() => {
    if (!categories || !selectedConcept) return;
    const subscription = API.graphql({ query: onUpdateMenuItem });
    if ("subscribe" in subscription) {
      const orderSubscription = subscription.subscribe({
        next: async (event: any) => {
          const updatedMenuItem = event.data.onUpdateMenuItem;
          if (updatedMenuItem.conceptID !== selectedConcept.id) return;
          const updatedCategories: any = await fetchCategories(
            selectedConcept.id
          );
          // update the selectedItem to reflect changes on menuItems page
          dispatch(
            setSelectedItem(
              getUpdatedMenuItemCompleteData(updatedCategories, updatedMenuItem)
            )
          );
        },
        error: (error: any) => {
          console.error("Subscription error:", error);
        },
      });
      return () => {
        if ("unsubscribe" in orderSubscription) {
          orderSubscription.unsubscribe();
        }
      };
    }
  }, [categories, selectedConcept]);

  useEffect(() => {
    if (!cart || !user || !selectedConcept) return;
    const subscription = API.graphql({ query: onUpdateCart });
    if ("subscribe" in subscription) {
      const cartSubscription = subscription.subscribe({
        next: async (event: any) => {
          const updatedCart = event.data.onUpdateCart;
          if (
            updatedCart.userID !== user.id ||
            updatedCart.conceptID !== selectedConcept.id
          )
            return;
          updateCartState(
            updatedCart.orderedItems,
            getCartItemsQuantity(updatedCart),
            updatedCart.subTaxTotal,
            deliveryFee,
            dispatch,
            selectedConcept.vatPercentage,
            selectedConcept.serviceChargePercentage,
            selectedConcept.addDeliveryToVat,
            channel,
            promocode
          );
        },
        error: (error: any) => {
          console.error("Subscription error:", error);
        },
      });
      return () => {
        if ("unsubscribe" in cartSubscription) {
          cartSubscription.unsubscribe();
        }
      };
    }
  }, [
    cart,
    items,
    totalPrice,
    totalQuantity,
    subTaxTotal,
    subTotal,
    deliveryFee,
    specialRequest,
    notes,
    deliveryFee,
    promocode,
  ]);

  //   useEffect(() => {
  //   if (!categories) return;

  //   const subscription = API.graphql({ query: onUpdateMenuItem });

  //   if ("subscribe" in subscription) {
  //     const orderSubscription = subscription.subscribe({
  //       next: (event: any) => {
  //         const updatedMenuItem = event.data.onUpdateMenuItem;
  //         const updatedCategories = categories.map((category:any) => {
  //           const updatedMenuItems = category.menuItems.map((item:any) => {
  //             if (item.id === updatedMenuItem.id) {
  //               return updatedMenuItem;
  //             }
  //             return item;
  //           });

  //           if (category.id === updatedMenuItem.categoryID) {
  //             return { ...category, menuItems: updatedMenuItems };
  //           }

  //           return category;
  //         });

  //         dispatch(setCategories(updatedCategories));

  //         // update the selectedItem to reflect changes on menuItems page
  //         dispatch(setSelectedItem(updatedMenuItem));

  //       },
  //       error: (error: any) => {
  //         console.error("Subscription error:", error);
  //       },
  //     });

  //     return () => {
  //       if ("unsubscribe" in orderSubscription) {
  //         orderSubscription.unsubscribe();
  //       }
  //     };
  //   }
  // }, [categories]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={validationWarning}
          autoHideDuration={3000}
          onClose={() => {
            setValidationWarning(false);
          }}
        >
          <Alert
            onClose={() => {
              setValidationWarning(false);
            }}
            severity={success ? "success" : "error"}
            sx={{
              position: "fixed",
              top: "16px",
              right: "16px",
            }}
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => {
                  setValidationWarning(false);
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          >
            {message}
          </Alert>
        </Snackbar>
        <PhoneNumber
          open={openPhoneNumber}
          currentPhoneNumber={phoneNumber}
          group={group}
          loggedInUser={loginUser}
          key={+openPhoneNumber}
          onClose={() => {
            handleOpenPhoneNumber(false);
          }}
          onSubmitOpenOtp={() => setOpenOTP(true)}
          onSubmitSetCheckCode={(checkCode: string) => setCheckCode(checkCode)}
          onDataChange={(data: any) => setData(data)}
        ></PhoneNumber>
        <OTP
          open={openOTP}
          phoneNumber={phoneNumber ? phoneNumber : data?.phoneNumber}
          checkCode={checkCode}
          handleSuccessfulOTP={handleSuccessfulOTP}
          key={checkCode}
          onClose={() => {
            setOpenOTP(false);
          }}
        ></OTP>
        <RouteTracker />
        {/* <Routes>
          <Route
            path="/"
            element={
              <CategoryPage
                changeIsChannelModalOpen={handleOpenChangeChannelModal}
              />
            }
          />
          <Route path="/cart" element={<Cart />} />
          <Route path="/menu/:categoryId/:itemId" element={<Menu />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgetPass" element={<ForgetPassword />} />
          <Route
            path="/loginWithoutPassword"
            element={<LoginWithoutPassword />}
          />
          <Route path="/loginWithOtp" element={<LoginWithOTP />} />
          <Route path="/resetPass" element={<ResetPassword />} />
          <Route path="/profile" element={<UserPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/orders" element={<OrderHistory />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/orderDetails" element={<OrderDetails />} />
          <Route path="/orderTrack" element={<OrderTrack />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/address" element={<AdressPage />} />
          <Route path="/newAddress" element={<NewAddress />} />
          <Route path="/addLocation" element={<AddLocation />} />
          <Route path="/rewards" element={<Rewards />} />
          <Route path="/past-transactions" element={<PastTransactions />} />
          <Route path="/payment-status" element={<PaymentStatus />} />
          <Route path="/menu" element={<MenuPage />} />
        </Routes> */}
        <RoutesContainer />
        {showNavbar && !splashScreen && <Navbar />}
      </>
    </ThemeProvider>
  );
};

export default App;
