import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const PATH_STORAGE_KEY = "lastVisitedPath";

const RouteTracker = () => {
  const location = useLocation();
  const hasTrackedInitialPath = useRef(false);
  const initialPathname = useRef<string | null>(null);

  useEffect(() => {
    const navEntry = performance.getEntriesByType(
      "navigation"
    )[0] as PerformanceNavigationTiming;
    const isReload = navEntry?.type === "reload";

    if (!hasTrackedInitialPath.current) {
      initialPathname.current = location.pathname;

      if (isReload) {
        // ✅ Save path *if* it's NOT /home on reload
        if (location.pathname !== "/" && location.pathname !== "/home") {
          localStorage.setItem(PATH_STORAGE_KEY, location.pathname);
          console.log("Saved path on reload:", location.pathname);
        } else {
          console.log("Skipped saving path on reload at /home");
        }
      }

      hasTrackedInitialPath.current = true;
    } else {
      // ✅ Normal navigation, save always (except login/logout)
      if (location.pathname !== "/login" && location.pathname !== "/logout") {
        localStorage.setItem(PATH_STORAGE_KEY, location.pathname);
        console.log("Saved path on navigation:", location.pathname);
      }
    }
  }, [location.pathname]);

  return null;
};

export default RouteTracker;
